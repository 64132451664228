import { DefaultTheme, StyledComponentProps } from 'styled-components'

import { ButtonCompo } from './styles'
export type ButtonProps = {
  primary?: boolean
  round?: boolean
  link?: boolean
  secondary?: boolean
  block?: boolean
  size?: 'small' | 'default' | 'large'
  type?: 'button' | 'submit' | 'reset'
  loading?: boolean
  height?: number
}

const Button = ({
  children,
  loading = false,
  type = 'button',
  ...props
}: StyledComponentProps<'button', DefaultTheme, ButtonProps, never>) => {
  return (
    <ButtonCompo {...props} type={type} loading={loading}>
      <span>{children}</span>
    </ButtonCompo>
  )
}

export default Button
