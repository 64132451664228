import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { ImageWrapper, PhotoLottieWrapper } from './PhotoModal'
import theme from '../../styles/theme'
import FileInfos from './Storage/FileInfos'
import { Dispatch, SetStateAction } from 'react'
import MoblieButtons from './MoblieButtons'
import useFormatText from '../../hooks/useFormatText'
import { lambdaResizeImage } from '../../utils/libs/lambdaResizeImage'

interface FilPreviewProps {
  type: 'image' | 'video'
  selectedImageUrl: string
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
  selectedAssetInfo: any
  previewLoad: () => React.ReactNode
  previewLoadEnd: () => void
  handleLoadClick?: () => void
}

const FilePreview = ({
  type,
  selectedImageUrl,
  setSelectedImageUrl,
  selectedAssetInfo,
  previewLoad,
  previewLoadEnd,
  handleLoadClick
}: FilPreviewProps) => {
  const intl = useIntl()

  const common = {
    src: lambdaResizeImage(selectedImageUrl, 260),
    onLoad: () => previewLoadEnd(),
    className: 'selected-image',
    alt: 'preview'
  }
  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <Title>
          {intl.formatMessage({
            id:
              type === 'image'
                ? 'PHOTO_MODAL_PREVIEW_TITLE'
                : 'VIDEO_MODAL_PREVIEW_TITLE'
          })}
        </Title>
        <ImageWrapper innerHeight={document.documentElement.clientHeight}>
          {selectedImageUrl && (
            <PhotoLottieWrapper>{previewLoad()}</PhotoLottieWrapper>
          )}
          {selectedImageUrl && (
            <>
              {type === 'image' ? (
                <img {...common} />
              ) : (
                <video
                  {...common}
                  controls
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </>
          )}
          {selectedImageUrl && selectedAssetInfo && (
            <FileInfos selectedAssetInfo={selectedAssetInfo} />
          )}
        </ImageWrapper>
      </div>
      <MoblieButtons
        onClickCancel={() => setSelectedImageUrl('')}
        onClickOk={handleLoadClick}
        cancelText={useFormatText('PREVIOUS')}
        okText={useFormatText('IMAGE_INPUT_SELECT')}
      />
    </Wrapper>
  )
}

export default FilePreview

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &&& {
    img,
    video {
      width: 100%;
      height: 100%;
      max-height: 30vh;
      margin-bottom: 16px;
    }
  }
`

const Title = styled.div`
  color: ${theme.colors.text.secondary};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  white-space: pre-wrap;
  margin-top: 8px;
  margin-bottom: 20px;
`
