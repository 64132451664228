import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/Button'
import theme from '../../../styles/theme'
import { IconArrowUp } from '../../../assets/icons'
import useFormatText from '../../../hooks/useFormatText'
import useOutsideClick from '../../../hooks/useOutsideClick'

interface FilterDropdownProps {
  order: 0 | 1
  setOrder: React.Dispatch<React.SetStateAction<0 | 1>>
}

const items = [
  {
    id: 0,
    label: 'IMAGE_INPUT_FROM_STORAGE_FILTER1'
  },
  {
    id: 1,
    label: 'IMAGE_INPUT_FROM_STORAGE_FILTER2'
  }
] as const

const FilterDropdown = ({ order, setOrder }: FilterDropdownProps) => {
  const ref = useOutsideClick<HTMLDivElement>(() => setOpen(false))
  const [open, setOpen] = useState(false)
  const selectLabel = items.find((item) => item.id === order)?.label || ''

  return (
    <Wrapper ref={ref}>
      <Button onClick={() => setOpen((prev) => !prev)}>
        {useFormatText(selectLabel)}
        <IconArrowUp
          style={{
            transform: `rotate(${open ? 0 : 180}deg)`,
            transition: 'all 0.2s ease-in-out'
          }}
        />
      </Button>
      {open && (
        <Dropdown>
          {items.map((item) => (
            <DropdownItem
              key={item.id}
              onClick={() => {
                setOrder(item.id)
                setOpen(false)
              }}
            >
              {useFormatText(item.label)}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default FilterDropdown

const Wrapper = styled.div`
  position: relative;
  width: fit-content;

  button {
    height: 32px;
    padding: 4px 0 4px 4px;
    border-radius: 4px;
    border: none;

    span {
      display: inline-flex;
      gap: 4px;
      align-items: center;
      color: ${theme.colors.black};
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.28px;
    }
  }
`

const Dropdown = styled.ul`
  width: 96px;
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  padding: 8px 0;
  z-index: 999;

  &&& {
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
  }
`

const DropdownItem = styled.li`
  padding: 9px 12px;
  color: ${theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  list-style: none;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.dropdownHover};
  }
`
