import styled, { css } from 'styled-components'
import { IconNotePencil } from '../../assets/icons'
import { AIInfos } from '../../types/project'
import { tabletMedia } from './constants'
import AiSubtitle from './AiSubtitle'
import useToggle from '../../hooks/useToggle'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, {
  ContentCardProps,
  ContentListType
} from '../../components/ModalContentCard'
import LottieIcon from '../../components/Lottie'
import useMarketingPlan from './hooks/useMarketingPlan'
import { PROJECT_CATEGORY } from '../../types/constants'

const PlaceInfo = ({
  aiInfo,
  progress,
  isMobile,
  isPendingError
}: {
  aiInfo?: AIInfos
  progress?: number
  isPendingError: boolean
  isMobile: boolean
}) => {
  const { isToggled, toggle } = useToggle(true)

  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const { isLoading, isStarting } = useMarketingPlan({
    progress
  })

  const isEmpty = !aiInfo?.feature?.length
  const category = aiInfo?.category
  const isProduct = category === PROJECT_CATEGORY.PLACE.PRODUCT
  const isReview = category === PROJECT_CATEGORY.PLACE.REVIEW

  const currentReviewFeature = aiInfo?.productReviewCopy
    ? aiInfo?.productReviewCopy[0]
    : undefined
  const currentProductFeature = aiInfo?.productFeature
    ? aiInfo?.productFeature[0]
    : undefined

  const currentFeature = aiInfo?.feature ? aiInfo?.feature[0] : undefined

  const ContentList: ContentCardProps[] = [
    { title: 'text_0384', content: aiInfo?.name || '' },
    ...(isReview
      ? [
          {
            title: 'text_0387',
            contentList: currentReviewFeature
              ? currentReviewFeature?.reduce(
                  (accumulator: ContentListType[], currentValue, index) => {
                    accumulator.push({
                      title: aiInfo?.review?.[index]?.nickname || '',
                      content: Object.values(currentValue)
                    })
                    return accumulator
                  },
                  []
                )
              : undefined
          }
        ]
      : []),
    ...(isProduct
      ? [
          {
            title: 'text_0386',
            contentList: currentProductFeature
              ? currentProductFeature?.reduce(
                  (accumulator: ContentListType[], currentValue) => {
                    if (currentValue.feature)
                      accumulator.push({
                        title: currentValue.name,
                        content: [
                          currentValue?.short,
                          currentValue?.medium || ''
                        ]
                      })
                    return accumulator
                  },
                  []
                )
              : undefined
          }
        ]
      : []),
    {
      title: 'text_0385',
      contentList: currentFeature
        ? currentFeature?.reduce(
            (accumulator: ContentListType[], currentValue) => {
              if (currentValue.feature)
                accumulator.push({
                  title: currentValue.feature,
                  content: [
                    currentValue?.short,
                    currentValue?.detailed || currentValue?.medium || ''
                  ]
                })
              return accumulator
            },
            []
          )
        : undefined
    }
  ]

  return (
    <PlaceInfoStyle.Wrapper>
      <AiSubtitle
        isToggled={isToggled}
        toggle={toggle}
        subtitle={useFormatText('text_0383')}
        progressValue={progress || 0}
        progressText='1'
        icon={<IconNotePencil />}
        isShowArrow={isMobile}
      />
      {isToggled && (
        <PlaceInfoStyle.Container isEmpty={isEmpty} isKr={isKr}>
          {(isStarting &&
            ContentList.map((content, idx) => (
              <ContentCard
                key={'ai-modal-product-' + idx}
                title={useFormatText(content.title)}
                content={content.content}
                contentList={content.contentList}
                notice={content.notice}
              />
            ))) || <></>}
          {isLoading && (
            <div className='empty'>
              <LottieIcon width={34} height={34} />
              {useFormatText(
                isPendingError
                  ? 'AI_LOADING_PLANNING_PENDING'
                  : 'AI_LOADING_PLANNING_PROCESSING'
              ) || <></>}
            </div>
          )}
        </PlaceInfoStyle.Container>
      )}
    </PlaceInfoStyle.Wrapper>
  )
}

export default PlaceInfo

const PlaceInfoStyle = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    user-select: none;

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          padding: 0;
        `
      )};
  `,
  Container: styled.div<{ isEmpty: boolean; isKr: boolean }>`
    width: 429px;
    height: calc(100% - 36px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.f5f5};
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 24px 18px;
    margin-top: 12px;

    ${({ isEmpty }) =>
      isEmpty &&
      css`
        color: ${({ theme }) => theme.colors.text['#aaa']};
        justify-content: center;
      `};

    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    ${tabletMedia(css`
      width: 100%;
    `)};

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          width: 100%;
          height: 236px;
          gap: 25px;
          padding: 20px 16px;
        `
      )};
  `
}
