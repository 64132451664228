import { forwardRef, useMemo, useState } from 'react'
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
  css
} from 'styled-components'
import theme from '../../../styles/theme'
import { IconAlertCircle } from '../../../assets/icons'
import useFormatText from '../../../hooks/useFormatText'
import Media from './Media'
import Checkbox from '../../../components/Checkbox'

type WrapperStyle =
  | FlattenSimpleInterpolation
  | FlattenInterpolation<ThemeProps<DefaultTheme>>

interface FileItemProps {
  file:
    | {
        _id: string
        url: string
        name: string
        createdAt: string
        size: number
        type: string
        uploadProgress?: number
        isError?: boolean
        resolution: string
        file?: File
      }
    | null
    | any
  isChecked: boolean
  onClick?: (file: any) => void
  showCheckbox?: boolean
  wrapperStyle?: WrapperStyle
}

const FileItem = forwardRef<HTMLDivElement, FileItemProps>(
  ({
    file,
    isChecked,
    onClick,
    showCheckbox = true,
    wrapperStyle
  }: FileItemProps) => {
    const [isLoadError, setIsLoadError] = useState(false)
    const { type, uploadProgress, isError } = file || {}
    const hasError = isLoadError || isError

    const handleOnError = () => {
      setIsLoadError(true)
    }

    const fileUrl = useMemo(() => {
      return file?.convertedUrl || file?.originalUrl || ''
    }, [file.name])

    return (
      <Wrapper isChecked={isChecked} custom={wrapperStyle}>
        <FileWrapper isError={hasError} isChecked={isChecked} onClick={onClick}>
          {!hasError ? (
            <>
              <Media
                fileUrl={fileUrl}
                type={type}
                handleOnError={handleOnError}
                uploadProgress={uploadProgress}
                duration={file?.duration}
              />
            </>
          ) : (
            <>
              <IconAlertCircle />
              <div className='upload-error-message'>
                {useFormatText('STORAGE_UPLOAD_FAILED')}
              </div>
            </>
          )}

          {showCheckbox && <Checkbox checked={isChecked} onChange={onClick} />}
        </FileWrapper>
      </Wrapper>
    )
  }
)

export default FileItem

const Wrapper = styled.div<{ isChecked: boolean; custom?: WrapperStyle }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 4px 4px 4px -2px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  ${({ isChecked }) =>
    isChecked &&
    css`
      border-color: ${theme.colors.red};
    `};

  ${({ custom }) => custom};
`

const FileWrapper = styled.div<{ isError?: boolean; isChecked: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 126px;
  aspect-ratio: 16 / 9;
  user-select: none;
  background-color: #a4a4a4;
  background-image: linear-gradient(
      45deg,
      #8b8b8d 25%,
      transparent 25%,
      transparent 75%,
      #8b8b8d 75%,
      #8b8b8d
    ),
    linear-gradient(
      45deg,
      #8b8b8d 25%,
      transparent 25%,
      transparent 75%,
      #8b8b8d 75%,
      #8b8b8d
    );
  background-position: 0 0, 12px 12px;
  background-size: 24px 24px;

  img,
  video {
    max-width: 100%;
    max-height: 100%;
  }

  ${({ isError }) =>
    isError &&
    css`
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      background: ${theme.colors.d3d3};
      background-image: none;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;

      .upload-error-message {
        color: ${theme.colors.btnTextColor};
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.24px;
      }

      & > svg {
        width: 24px;
        height: 24px;
      }
    `};

  .checkbox-wrapper {
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 0;
    z-index: 2;
    opacity: 0.99;
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      .checkbox-wrapper {
        opacity: 0.99;
      }

      img,
      video {
        filter: brightness(0.8);
      }
    `};
`
