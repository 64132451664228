import styled, { css } from 'styled-components'
import useOutsideClick from '../../hooks/useOutsideClick'
import theme from '../../styles/theme'
import { acceptFiles } from './constants'
import UploadContainer from './UploadContainer'
import { UploadInfoProps } from './types'
import { IconModalClose } from '../../assets/icons'
import useFormatText from '../../hooks/useFormatText'

const UploadInfo = ({ onClose }: UploadInfoProps) => {
  const ref = useOutsideClick<HTMLDivElement>(onClose)
  const totalImageSize = 20
  const totalVideoSize = 500
  return (
    <Wrapper ref={ref}>
      <div className='title'>
        {useFormatText('STORAGE_UPLOAD_MORE')}
        <IconModalClose
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        />
      </div>
      <UploadContainer
        subtitle={useFormatText('STORAGE_UPLOAD_POSSIBLE_FILE')}
        imageValue={acceptFiles.image.join(', ')}
        videoValue={acceptFiles.video.join(', ')}
      />
      <UploadContainer
        subtitle={useFormatText('STORAGE_UPLOAD_POSSIBLE_FILE_MAX_SIZE')}
        imageValue={`${totalImageSize}MB`}
        videoValue={`${totalVideoSize}MB`}
      />
    </Wrapper>
  )
}

export default UploadInfo

const Wrapper = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  width: 300px;
  background-color: ${theme.colors.white};
  border-radius: 12px;
  padding: 0 16px 16px 24px;
  z-index: 999;

  &&& {
    box-shadow: 0 0 8px 2px rgba(48, 48, 48, 0.06);
  }

  .title {
    position: relative;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.border.primary};
    color: ${theme.colors.text['#222']};
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    padding-top: 16px;
    padding-bottom: 12px;

    svg {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  ${({ theme }) =>
    theme.breakpoints.large(css`
      left: unset;
      right: 0;
    `)};
`
