import { useParams } from 'react-router-dom'
import { ProjectContext, useProject } from '../hooks/useProject'

import Main from './Main'
import AuthHelper from '../components/AuthHelper'
import UserLimitProvider from '../contexts/UserLimitContext'

const Editor = () => {
  const { projectId } = useParams<{ projectId: string }>()
  const { project, setProject, sceneList, refetchProject } =
    useProject(projectId)

  const { needCheck, renderCnt, renderLimit } = project ?? {}
  const isLimit =
    needCheck && renderCnt !== undefined && renderLimit !== undefined
      ? renderCnt >= renderLimit
      : false

  return (
    <UserLimitProvider>
      <ProjectContext.Provider
        value={{
          project,
          setProject,
          sceneList,
          projectId,
          refetchProject,
          isLimit
        }}
      >
        <AuthHelper projectId={projectId}>
          <Main projectId={projectId} status={project?.status} />
        </AuthHelper>
      </ProjectContext.Provider>
    </UserLimitProvider>
  )
}

export default Editor
