import styled, { css } from 'styled-components'

import { Input } from 'antd'
import Button from '../../components/Button'
import Typography from '../../components/Typography'
import theme from '../../styles/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.border.secondary};
`

export const InnerWrapper = styled.div<{ isMobile: boolean }>`
  width: 96vw;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 10px;

  ${({ isMobile }) => isMobile && `height: 50px;`}
`

export const LeftWrapper = styled.div`
  display: flex;

  button {
    color: ${theme.colors.text['#777']};
    font-size: 16px;
    font-weight: 500;
  }
`

export const RightWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  overflow: visible;
  justify-content: space-between;

  ${theme.breakpoints.xLarge(css`
    justify-content: center;
    text-align: center;
  `)}

  .beta-chip {
    position: absolute;
    top: -5px;
    right: -6px;
    user-select: none;
  }
`

export const TitleWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre-wrap;
`

export const BtnEdit = styled(Button)<{ isMobile: boolean }>`
  &:disabled {
    cursor: not-allowed;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 14px;
  `};
`

export const StyledInput = styled(Input)`
  &:focus {
    border-color: ${theme.colors.primary};
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgb(229 73 72 / 20%);
    box-shadow: 0 0 0 2px rgb(229 73 72 / 20%);
  }
`

export const StyledTypography = styled(Typography)<{ isMobile: boolean }>`
  display: inline-block;
  width: 100%;
  max-width: 480px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${theme.colors.text['#222']};
  font-size: 16px;
  font-weight: 600;

  ${({ theme }) =>
    theme.breakpoints.large(`
    max-width: 112px;
  `)}

  ${({ theme }) =>
    theme.breakpoints.small(`
    max-width: 100px;
  `)}

  ${({ isMobile }) =>
    isMobile &&
    `
    width: 78%;
  `};
`

export const EditIcon = styled.button`
  width: 18px;
  height: 18px;
  padding: 0;
  margin-left: 2px;
  background: transparent;
  border: none;
  cursor: pointer;
`

export const RemainingRenderingInnerWrapper = styled.div<{
  isPointer: boolean
}>`
  display: inline-flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  padding: 10px 12px;
  border-radius: 40px;
  background: ${theme.colors.background.active};

  p {
    color: ${theme.colors.text['#222']};
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
  }
`

export const VideoIcon = styled.div<{
  icon: string
}>`
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${({ icon }) => icon}');
`

export const RemainingRenderingWrapper = styled.div`
  position: relative;
  margin-right: 16px;

  ${theme.breakpoints.large(`
    margin-right: 12px;
  `)}
`

export const MoreBtnIcon = styled.div<{
  icon: string
  isActive: boolean
}>`
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${({ icon }) => icon}');
  transition: all 0.1s ease;

  ${({ isActive }) =>
    isActive &&
    `
    transform: rotate(-180deg);
  `};
`

export const RenderingCount = styled.div<{ isMobile?: boolean }>`
  color: ${theme.colors.text.active};
  font-size: 14px;
  font-weight: 600;
  line-height: 100%;
  font-weight: 500;
  user-select: none;

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 14px;
  `}

  strong {
    font-weight: 600;
    margin-right: 3px;

    ${({ isMobile }) => isMobile && `font-weight: 700;`}
  }
`

export const DefaultInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const CenterWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex: 1;
  justify-content: center;
`

export const SmallContentsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  span {
    display: inline-block;
    height: 16px;
    color: ${theme.colors.text['#333']};
    font-size: 13px;
    font-weight: 600;
    user-select: none;
  }

  a {
    background: -webkit-${theme.colors.gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 11px;
    font-weight: 600;
    line-height: 100%;
    text-decoration-line: underline;
    text-align: center;
    width: 100%;
    margin-top: 8px;
  }
`

export const InfiniteIcon = styled.div<{ icon: string }>`
  width: 18px;
  height: 18px;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('${({ icon }) => icon}');
`

export const MobileRemainingRenderingWrapper = styled.div`
  position: fixed;
  top: calc(51px + 12px);
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 343px;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffff;
  border: 1px solid #1a1a1a;
  border-radius: 10rem;
  box-shadow: 0px 4px 6px 0px #00000026;
`

export const MobileRemainingRenderingInnerWrapper = styled(
  RemainingRenderingInnerWrapper
)`
  width: fit-content;
  padding: 0;
  background-color: transparent;
  p {
    font-size: 13px;
    font-weight: 500;
  }
`

export const PlanBadge = styled.div<{ bgColor?: string }>`
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #ffff;
  border-radius: 5rem;
  background-color: ${({ bgColor }) => bgColor};
  margin-right: 8px;
`

export const MobileStatusButton = styled.div<{ isFree: boolean }>`
  margin-left: auto;
  padding: 8px 12px;
  border-radius: 5rem;
  background-color: ${({ isFree }) => (isFree ? '#FFEEEE' : '#F5F5F5')};
  color: ${({ isFree }) => (isFree ? '#E81513' : '#1A1A1A')};
  cursor: ${({ isFree }) => (isFree ? 'pointer' : 'default')};
  font-size: 12px;
  font-weight: 600;
  line-height: 100%;
`
