import React, { useState } from 'react'
import { useMutation } from 'react-query'
import message from 'antd/lib/message'
import { useIntl } from 'react-intl'
import { service } from '../../utils/api'
import { useProjectContext } from '../../hooks/useProject'

export type RemoveBgUrls = {
  removedImageUrl: string
  originalImageUrl: string
}

const removeBg = async (imageUrl: string, userId: string) => {
  try {
    const res = await service().projects.removeImageBg(imageUrl, userId)
    return res
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message)
    }
  }
}

export const useRemoveBgMutation = (
  imageUrl: string,
  setImage: React.Dispatch<React.SetStateAction<string>>
) => {
  const intl = useIntl()
  const { project } = useProjectContext()
  const [isSuccess, setIsSuccess] = useState(false)

  const { data, isLoading, mutate } = useMutation<
    Awaited<Promise<RemoveBgUrls>>,
    Error,
    void
  >(['removeBg', imageUrl], () => removeBg(imageUrl, project?.userId || ''), {
    onSuccess: (urls) => {
      setIsSuccess(true)
      setImage(urls.removedImageUrl)
    },
    onError: (e) => {
      setIsSuccess(false)
      if (e instanceof Error && e.message && typeof e.message === 'string') {
        message.error(intl.formatMessage({ id: e.message }))
      }
    }
  })

  const handleSuccessState = (value: boolean) => {
    setIsSuccess(value)
  }

  return { data, isLoading, isSuccess, mutate, handleSuccessState }
}
