import { useEffect, useRef, useState } from 'react'
import { useAiStore } from '../../../stores/ai'
import { useAiQuery } from '../_queries'
import { useQueryClient } from 'react-query'
import { useProjectContext } from '../../../hooks/useProject'
import { message } from 'antd'
import { useIntl } from 'react-intl'

const useAIInfoSSE = ({
  onClose,
  completeCallback
}: {
  onClose: () => void
  completeCallback?: () => Promise<any> | any
}) => {
  const queryClient = useQueryClient()
  const intl = useIntl()

  const { aiInfo, refetchAiInfo, isError } = useAiQuery()
  const { aiInfos, setAiInfos } = useAiStore()
  const { refetchProject } = useProjectContext()

  const [isPendingError, setIsPendingError] = useState(false)
  const eventSourceRef = useRef<EventSource | null>(null)
  const isRetryFailError = aiInfo?.status === -6
  const isNeedRefetch = aiInfo?.status === 5
  const isPlace = !!aiInfos?.isPlace
  useEffect(() => {
    if (isNeedRefetch) {
      refetchAiInfo()
    }
  }, [isNeedRefetch])

  useEffect(() => {
    if (!aiInfos?._id || eventSourceRef.current) return

    eventSourceRef.current = new EventSource(
      isPlace
        ? `${process.env.REACT_APP_VPLATE_API_URL}/airequest/watch/place/${aiInfos._id}`
        : `${process.env.REACT_APP_VPLATE_API_URL}/airequest/watch/${aiInfos._id}`
    )

    eventSourceRef.current.onopen = () => {
      console.log('Connection was opened.')
    }
    eventSourceRef.current.onerror = () => {
      console.error('Unknown error occurred.')
    }
    eventSourceRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data)

      queryClient.setQueryData(['ai'], () => ({
        ...data.data,
        media: [...data.media],
        progress: { ...data.progress },
        totalMediaLen: data.totalMediaLen
      }))
    }

    return () => {
      if (!eventSourceRef.current) return
      eventSourceRef.current.close()
    }
  }, [aiInfos?._id])

  const showErrorToast = () => {
    const errorMsg = (() => {
      switch (aiInfo?.status) {
        case -1:
          // return 'AI_RETRY_MESSAGE1'
          return 'AI_RETRY_MESSAGE2'
        case -3:
          return 'AI_RETRY_MESSAGE2'
        case -5:
          // return 'AI_RETRY_MESSAGE3'
          return 'AI_RETRY_MESSAGE2'
        default:
          return ''
      }
    })()
    if (!errorMsg) return
    message.warning(
      <div
        style={{
          textAlign: 'center',
          whiteSpace: 'pre-wrap'
        }}
      >
        {intl.formatMessage({ id: errorMsg })}
      </div>
    )
  }

  const cleanUp = async () => {
    if (aiInfo) {
      setAiInfos(aiInfo)
    }

    if (refetchProject) {
      await refetchProject()
    }
    if (isRetryFailError) return
    onClose()
  }

  useEffect(() => {
    if (isError || isRetryFailError || aiInfo?.progress?.isComplete) {
      cleanUp()
      completeCallback?.()
      return
    }
  }, [isError, aiInfo?.progress?.isComplete, isRetryFailError])

  useEffect(() => {
    if (
      aiInfo?.status === -1 ||
      aiInfo?.status === -3 ||
      aiInfo?.status === -5
    ) {
      setIsPendingError(true)
      showErrorToast()
    }
  }, [aiInfo?.status])

  return {
    aiInfo,
    isPendingError,
    isError,
    isRetryFailError
  }
}

export default useAIInfoSSE
