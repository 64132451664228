import styled from 'styled-components'
import { UploadContainerProps } from './types'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'

const UploadContainer = ({
  subtitle,
  imageValue,
  videoValue
}: UploadContainerProps) => {
  return (
    <Wrapper>
      <span className='subtitle'>{subtitle}</span>
      <div className='upload-container'>
        <span className='media'>{useFormatText('COMPONENT_IMAGE')}</span>
        <span className='info'>{imageValue}</span>
      </div>
      <div className='upload-container'>
        <span className='media'>{useFormatText('COMPONENT_VIDEO')}</span>
        <span className='info'>{videoValue}</span>
      </div>
    </Wrapper>
  )
}

export default UploadContainer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .subtitle {
    color: ${theme.colors.text['#222']};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-top: 20px;
  }

  .upload-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .media {
      color: ${theme.colors.text['#555']};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    .info {
      color: ${theme.colors.text['#888']};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
`
