import { MutableRefObject } from 'react'
import ReactPlayer from 'react-player/youtube'
import {
  TimeLineList,
  TimeLineItemLeft,
  TimeLineItemRight,
  TimeLineLi
} from './styles'

export interface TimeLineItem {
  title: string
  timeline: string
  time: number
  nextTime: number
}

export const timeLineList: Array<TimeLineItem> = [
  {
    title: 'TUTORIAL_MODAL_TIMELINE1',
    timeline: '00:00',
    time: 0,
    nextTime: 27
  },
  {
    title: 'TUTORIAL_MODAL_TIMELINE2',
    timeline: '00:27',
    time: 27,
    nextTime: 70
  },
  {
    title: 'TUTORIAL_MODAL_TIMELINE3',
    timeline: '01:10',
    time: 70,
    nextTime: 110
  },
  {
    title: 'TUTORIAL_MODAL_TIMELINE4',
    timeline: '01:50',
    time: 110,
    nextTime: 123
  },
  {
    title: 'TUTORIAL_MODAL_TIMELINE5',
    timeline: '02:03',
    time: 123,
    nextTime: 999999
  }
]

type SeekToTime = (
  videoRef: MutableRefObject<ReactPlayer | null>,
  time: number
) => void

const seekToTime: SeekToTime = (videoRef, time) => {
  if (videoRef && videoRef.current && videoRef.current.seekTo) {
    videoRef.current.seekTo(time, 'seconds')
  }
}

export const renderTimeLines = (
  videoRef: MutableRefObject<ReactPlayer | null>,
  isMobile: boolean,
  formatText: (id: string) => string,
  currentTime: number | null
) => {
  return (
    <TimeLineList isMobile={isMobile}>
      {timeLineList.map(({ time, title, timeline, nextTime }) => {
        const isCurrentTimeInSection =
          currentTime !== null && currentTime >= time && currentTime < nextTime

        return (
          <TimeLineLi
            key={time}
            onClick={() => seekToTime(videoRef, time)}
            isCurrentTimeInSection={isCurrentTimeInSection}
          >
            <TimeLineItemLeft isMobile={isMobile}>
              <strong>{formatText(title)}</strong>
            </TimeLineItemLeft>
            <TimeLineItemRight isMobile={isMobile}>
              {timeline}
            </TimeLineItemRight>
          </TimeLineLi>
        )
      })}
    </TimeLineList>
  )
}
