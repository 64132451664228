import { useEffect, useState } from 'react'
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
  keyframes
} from 'styled-components'
import theme from '../../styles/theme'

type WrapperStyle =
  | FlattenSimpleInterpolation
  | FlattenInterpolation<ThemeProps<DefaultTheme>>
export interface ToastProps {
  icon: JSX.Element
  title?: string
  description?: string
  duration?: number
  wrapperStyle?: WrapperStyle
}

const Toast = ({
  icon,
  title,
  description,
  duration = 3,
  wrapperStyle
}: ToastProps) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isShowToast, setIsShowToast] = useState(true)
  const [isFadingOut, setIsFadingOut] = useState(false)

  const handleMouseEnterAndTouchStart = () => {
    setIsHovered(true)
  }

  const handleMouseLeaveAndTouchEnd = () => {
    setTimeout(() => {
      setIsFadingOut(true)
    }, 1000)
  }

  const handleAnimationEnd = () => {
    if (isFadingOut) {
      setIsShowToast(false)
    }
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined

    if (!isHovered) {
      timeoutId = setTimeout(() => {
        setIsFadingOut(true)
      }, duration * 1000)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [duration, isHovered])

  if (!isShowToast) return null

  return (
    <>
      <Wrapper
        className='toast-wrapper'
        onMouseEnter={handleMouseEnterAndTouchStart}
        onMouseLeave={handleMouseLeaveAndTouchEnd}
        onTouchStart={handleMouseEnterAndTouchStart}
        onTouchEnd={handleMouseLeaveAndTouchEnd}
        custom={wrapperStyle}
        isShowToast={isShowToast}
        isFadingOut={isFadingOut}
        onAnimationEnd={handleAnimationEnd}
      >
        <IconWrapper className='icon-wrapper'>{icon}</IconWrapper>
        <TextWrapper className='text-wrapper'>
          <Title className='title'>{title}</Title>
          {description && (
            <Description className='description'>{description}</Description>
          )}
        </TextWrapper>
      </Wrapper>
    </>
  )
}

export default Toast

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.99;
  }
`

const fadeOutAnimation = keyframes`
  from {
    opacity: 0.99;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

const Wrapper = styled.div<{
  isShowToast: boolean
  isFadingOut: boolean
  custom?: WrapperStyle
}>`
  opacity: ${({ isShowToast }) => (isShowToast ? '0.99' : '0')};
  visibility: ${({ isShowToast }) => (isShowToast ? 'visible' : 'hidden')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 68px;
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: 4px;
  fill: #fff;
  filter: drop-shadow(3px 4px 4px rgba(0, 0, 0, 0.07));
  animation: ${({ isFadingOut }) =>
      isFadingOut ? fadeOutAnimation : fadeInAnimation}
    0.3s ease-in-out;
  z-index: 9999;

  ${({ custom }) => custom};
`

const IconWrapper = styled.div`
  width: 44px;
  height: 44px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
`

const Title = styled.span`
  color: ${theme.colors.black2};
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
`

const Description = styled.span`
  color: ${theme.colors.black2};
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  margin-top: 5px;
`
