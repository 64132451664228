import styled, { css } from 'styled-components'
import Typography from '../../components/Typography'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;

  ${({ theme }) =>
    theme.breakpoints.large(css`
      padding-bottom: 0px;
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    `)};
`

export const CropWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: 45vh;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
`

export const FunctionWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  & > div {
    width: 100%;
    height: auto;
    max-width: 360px;
    margin: 0 auto;
    padding: 16px;
    position: relative;
    display: flex;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      & > div {
        margin-top: 20px;
        padding: 0;
      }
    `)};
`

export const IconFunction = styled.button<{ icon: string; disabled: boolean }>`
  flex: 1;
  width: auto;
  height: auto;
  position: relative;
  display: inline-block;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;

  &::before {
    content: '';
    width: 100%;
    height: 48px;
    position: relative;
    display: block;
    background-image: url('${({ icon }) => icon}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 8px;
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

export const SecondaryTypography = styled(Typography).attrs({
  type: 'body2'
})`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.secondary};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: inline-block;
      width: 100%;
      text-align: center;
    `)}
`

export const CropButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      position: relative;
    `)}
`
