import { useState } from 'react'
import styled, { css } from 'styled-components'
import Checkbox from '../../components/Checkbox'
import { useHideMutation, useLeaveMutation } from './_queries'
import DefaultButton from '../../components/DefaultButton'
import { IconModalClose } from '../../assets/icons'
import theme from '../../styles/theme'
import useOutsideClick from '../../hooks/useOutsideClick'
import useFormatText from '../../hooks/useFormatText'
import { useAiStore } from '../../stores/ai'
import Loader from '../../components/Loader'

interface LeaveModalProps {
  onClose: () => void
}
const LeaveModal = ({ onClose }: LeaveModalProps) => {
  const [checked, setChecked] = useState<boolean>(false)
  const { aiInfos } = useAiStore()
  const ref = useOutsideClick<HTMLDivElement>(onClose)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { leaveMutate } = useLeaveMutation(setIsLoading)
  const { hideMutate } = useHideMutation()

  const handleConfirm = async () => {
    if (!aiInfos?._id) return
    setIsLoading(true)
    if (checked) {
      await hideMutate()
    }
    leaveMutate()
  }

  return (
    <ModalOverlay>
      <ModalContainer ref={ref}>
        <CloseButton onClick={onClose} />
        <ModalTitle>
          {useFormatText('AI_LOADING_MODAL_LEAVE_CONFIRM')}
        </ModalTitle>
        <ModalContent>
          {useFormatText('AI_LOADING_MODAL_LEAVE_INFO')}
        </ModalContent>
        <CheckBoxLabel
          checked={checked}
          onClick={() => setChecked((prev) => !prev)}
        >
          <Checkbox checked={checked} />{' '}
          {useFormatText('AI_LOADING_MODAL_LEAVE_CHECK_SEVEN_DAYS')}
        </CheckBoxLabel>
        <ModalFooter>
          <DefaultButton category='secondaryMonochrome' onClick={onClose}>
            {useFormatText('AI_LOADING_MODAL_LEAVE_CANCEL')}
          </DefaultButton>
          <DefaultButton category='primary' onClick={handleConfirm}>
            {useFormatText('AI_LOADING_MODAL_LEAVE_BUTTON')}
            {isLoading && (
              <div
                style={{
                  position: 'relative',
                  width: 15,
                  height: 30
                }}
              >
                <Loader size={30} />
              </div>
            )}
          </DefaultButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default LeaveModal

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 51, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 401px;
  background-color: #fff;
  border-radius: 8px;
  padding: 36px 20px 20px 36px;
  z-index: 99999;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-left: 16px;
      margin-right: 16px;
      padding: 24px 20px 20px 24px;
    `)}
`

const ModalTitle = styled.h2`
  color: ${theme.colors.black2};
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 16px;
    `)}
`

const ModalContent = styled.p`
  color: ${theme.colors.text['#555']};
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  white-space: pre-wrap;
  margin-top: 12px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 15px;
    `)}
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 11px;
  margin-top: 24px;

  &&& {
    ${({ theme }) =>
      theme.breakpoints.medium(css`
        & > button {
          width: fit-content;
        }
      `)}
  }
`

const CloseButton = styled(IconModalClose)`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

const CheckBoxLabel = styled.label<{ checked: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 16px;
  color: ${theme.colors.text['#888']};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  cursor: pointer;

  .checkbox-wrapper,
  .checkbox {
    width: 16px;
    height: 16px;
  }

  .checkbox {
    border-width: 1px;
    ${({ checked }) =>
      !checked &&
      css`
        svg {
          visibility: visible;

          & * {
            fill: ${theme.colors.b6b6};
          }
        }
      `};
  }
`
