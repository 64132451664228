import { Row } from './styles'
import { TrimMusicProps } from './types'
import axios from 'axios'

import Range from '../Range'
import WaveformGenerator from '../../utils/libs/generateWaveform'
import styled, { useTheme } from 'styled-components'
import { useEffect, useState } from 'react'
import { waveformSettings } from './constants'
import { files } from '../../utils/api'
import Audio from '../Audio'
import EditMusicLayout from './EditMusicLayout'
import useFormatText from '../../hooks/useFormatText'
import { useDebounce } from '../../hooks/useDebounce'

const TrimMusic = (props: TrimMusicProps) => {
  const {
    onClose,
    selectedMusic,
    setStartTime,
    duration,
    startTime,
    handlePlay,
    handlePause,
    hanleChangeMusic
  } = props

  const theme = useTheme()
  const [selectedMusicWaveform, setSelectedMusicWaveform] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const handleComplete = async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    if (selectedMusic && duration) {
      const result = await files().music.upload({
        musicUrl: selectedMusic?.musicUrl,
        trimAudioDuration: duration,
        trimAudioStart: startTime,
        fadeEffect: true
      })
      if (result?.musicUrl) {
        hanleChangeMusic(result?.musicUrl)
      }
    }
    setIsLoading(false)
  }

  const debouncedHandleRangeChange = useDebounce((value: number) => {
    setStartTime(Math.floor(value * 10) / 10)
  }, 1000)

  const handleSeeked = (audio: HTMLAudioElement) => {
    if (!audio || !duration) return
    if (audio.currentTime >= audio.duration - duration) {
      setStartTime(Math.floor(audio.duration - duration))
      return
    }
    setStartTime(audio.currentTime)
  }

  const handleTimeUpdate = (audio: HTMLAudioElement, startTime: number) => {
    if (!audio || !duration) return
    console.log('startTime + duration', startTime + duration)
    if (audio.currentTime >= startTime + duration) {
      audio.pause()
      return
    }
  }

  useEffect(() => {
    const fetchWaveform = async () => {
      setSelectedMusicWaveform('')
      if (selectedMusic?.musicUrl) {
        const res = await axios.get<ArrayBuffer>(
          process.env.REACT_APP_CORS_PROXY_URL + selectedMusic?.musicUrl,
          {
            responseType: 'arraybuffer',
            headers: { 'Access-Control-Allow-Origin': '*' }
          }
        )
        if (res?.data) {
          const audioBuffer = res.data
          const waveformGenerator = new WaveformGenerator(audioBuffer)
          const waveformResult = await waveformGenerator.getWaveform({
            ...waveformSettings
          })
          if (waveformResult) {
            setSelectedMusicWaveform(waveformResult)
          }
        }
      }
    }
    fetchWaveform()
  }, [selectedMusic, theme])

  return (
    <EditMusicLayout
      title={selectedMusic?.musicTitle}
      description={useFormatText('TRIM_MUSIC_DESCRIPTION')}
      onClose={onClose}
      onOk={handleComplete}
      onCloseText='SIDE_NAV_BGM_CONTENT_CLOSE'
      onOkText='SIDE_NAV_BGM_CONTENT_DONE'
    >
      <Container>
        <Row>
          {selectedMusic && (
            <Range
              background={selectedMusicWaveform}
              originalDuration={selectedMusic.musicLength}
              duration={duration}
              value={startTime}
              onChange={(value) => debouncedHandleRangeChange(value)}
            />
          )}
        </Row>
        <Row>
          <div style={{ width: '100%' }}>
            <Audio
              src={selectedMusic?.musicUrl}
              duration={duration}
              startTime={startTime}
              handlePlay={handlePlay}
              handlePause={handlePause}
              handleSeeked={handleSeeked}
              handleTimeUpdate={handleTimeUpdate}
            />
          </div>
        </Row>
      </Container>
    </EditMusicLayout>
  )
}

export default TrimMusic

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
