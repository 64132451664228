import { Dispatch, SetStateAction } from 'react'
import { AIInfos, BgmType, Project } from '../../types/project'

import {
  iconSideBarMarketing,
  iconSideBarMarketingActive,
  iconSideBarMainColor,
  iconSideBarMainColorActive,
  iconSideBarSubColor,
  iconSideBarSubColorActive,
  iconSideBarText,
  iconSideBarTextActive,
  iconSideBarMusic,
  iconSideBarMusicActive,
  iconSideBarStorage,
  iconSideBarStorageActive
} from '../../assets/icons'

import MusicPanel from '../../components/MusicPanel'
import ColorPanel from '../../components/ColorPanel'
import StoragePanel from '../../components/StoragePanel'
import { SetProject } from '../../hooks/useProject'

import { message } from 'antd'
import MarketingPanel from '../../components/MarketingPanel'
import SelfMarketingPanel from '../../components/SelfMarketingPanel'

import { IntlShape } from 'react-intl'
import { Music } from '../../utils/api/files'

type GetMenuParams = {
  intl: IntlShape
  project?: Project
  selectedDrawerIndex: number
  setSelectedDrawerIndex: Dispatch<SetStateAction<number>>
  setProject: SetProject | undefined
  isMutateLoading?: boolean
  setIsModified: Dispatch<SetStateAction<boolean>>
  handleOnclickAdPoint: (persona: string) => void
  aiInfos: AIInfos
  isReset: boolean
  setIsReset: Dispatch<SetStateAction<boolean>>
}

export const getMenus = ({
  intl,
  project,
  selectedDrawerIndex,
  setSelectedDrawerIndex,
  setProject,
  isMutateLoading,
  setIsModified,
  handleOnclickAdPoint,
  aiInfos,
  isReset,
  setIsReset
}: GetMenuParams) => {
  const isPlugin = !!aiInfos?.marketingPlan
  const isPlace = !!aiInfos?.isPlace
  const handleDrawerClose = () => {
    setSelectedDrawerIndex(-1)
  }

  const changeColor = (index: number, newColor: string | undefined) => {
    if (newColor && project && Array.isArray(project?.changedColor)) {
      setIsModified(true)
      project.changedColor.splice(index, 1, newColor)

      setProject &&
        setProject({
          ...project,
          changedColor: project?.changedColor
        })
      message.success(intl.formatMessage({ id: 'SIDE_NAV_CHANGED' }))
    }
  }

  const handleChangeBgm = async (
    newBgmSrc: string | null,
    newBgm: Music | null
  ) => {
    setIsModified(true)
    const copiedCur = { ...project } as Project
    const copiedCurBgm = { ...project?.bgm } as BgmType

    setProject &&
      copiedCur &&
      (await setProject({
        ...copiedCur,
        bgmUrl: newBgmSrc || '',
        bgm: {
          ...copiedCurBgm,
          musicTitle: newBgm?.musicTitle || null,
          _id: newBgm?._id || null
        }
      }))
  }
  const menuClick = (idx: number) => () =>
    setSelectedDrawerIndex((current: number) => (current === idx ? -1 : idx))

  const isActive = (idx: number) => selectedDrawerIndex === idx
  const contents = [
    {
      isActive: isActive(2),
      icons: {
        default: iconSideBarMainColor,
        active: iconSideBarMainColorActive
      },
      title: intl.formatMessage({ id: 'SIDE_NAV_MAIN_COLOR' }),
      key: 'mainColor',
      onClick: menuClick(2),
      drawer: {
        visible: isActive(2),
        children: (
          <ColorPanel
            selectedDrawerIndex={selectedDrawerIndex}
            title='SIDE_NAV_MAIN_COLOR'
            isMutateLoading={isMutateLoading}
            isReset={isReset && isActive(2)}
            setIsReset={setIsReset}
            defaultColor={project?.design.temColor[0]}
            currentColor={project?.changedColor[0]}
            onColorChange={(newColor) => changeColor(0, newColor)}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    },
    {
      isActive: isActive(3),
      icons: {
        default: iconSideBarSubColor,
        active: iconSideBarSubColorActive
      },
      title: intl.formatMessage({ id: 'SIDE_NAV_SUB_COLOR' }),
      key: 'subColor',
      onClick: menuClick(3),
      drawer: {
        visible: isActive(3),
        children: (
          <ColorPanel
            selectedDrawerIndex={selectedDrawerIndex}
            title='SIDE_NAV_SUB_COLOR'
            isMutateLoading={isMutateLoading}
            isReset={isReset && isActive(3)}
            setIsReset={setIsReset}
            defaultColor={project?.design.temColor[1]}
            currentColor={
              project?.changedColor[1] || project?.design.temColor[1]
            }
            onColorChange={(newColor) => changeColor(1, newColor)}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    },
    {
      isActive: isActive(4),
      icons: {
        default: iconSideBarText,
        active: iconSideBarTextActive
      },
      title: intl.formatMessage({ id: 'SIDE_NAV_FONT_COLOR' }),
      key: 'fontColor',
      onClick: menuClick(4),
      drawer: {
        visible: isActive(4),
        children: (
          <ColorPanel
            selectedDrawerIndex={selectedDrawerIndex}
            title='SIDE_NAV_FONT_COLOR'
            isMutateLoading={isMutateLoading}
            isReset={isReset && isActive(4)}
            setIsReset={setIsReset}
            defaultColor={project?.design.temColor[2]}
            currentColor={
              project?.changedColor[2] || project?.design.temColor[2]
            }
            onColorChange={(newColor) => changeColor(2, newColor)}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    },
    {
      icons: {
        default: iconSideBarMusic,
        active: iconSideBarMusicActive
      },
      isActive: isActive(5),
      title: intl.formatMessage({ id: 'SIDE_NAV_BGM' }),
      key: 'bgm',
      onClick: menuClick(5),
      drawer: {
        visible: isActive(5),
        children: (
          <MusicPanel
            isMutateLoading={isMutateLoading}
            isReset={isReset && isActive(5)}
            setIsReset={setIsReset}
            duration={project?.design?.videoDuration}
            changedBgmTitle={project?.bgm?.musicTitle}
            defaultMusicUrl={project?.design?.temPreviewVideo}
            currentMusicUrl={project?.bgmUrl}
            onChange={handleChangeBgm}
            visible={!isActive(5)}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    },
    {
      icons: {
        default: iconSideBarStorage,
        active: iconSideBarStorageActive
      },
      isActive: isActive(6),
      title: intl.formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
      key: 'storage',
      onClick: menuClick(6),
      drawer: {
        visible: isActive(6),
        children: (
          <StoragePanel
            isActive={isActive(6)}
            handleDrawerClose={handleDrawerClose}
          />
        )
      }
    }
  ]

  if (isPlugin) {
    const pluginMenu = [
      {
        isActive: isActive(0),
        icons: {
          default: iconSideBarMarketing,
          active: iconSideBarMarketingActive
        },
        title: intl.formatMessage({ id: 'SIDE_NAV_MARKETING' }),
        key: 'auto',
        onClick: menuClick(0),
        drawer: {
          visible: isActive(0),
          children: (
            <MarketingPanel
              handleDrawerClose={handleDrawerClose}
              isPlace={!!isPlace}
            />
          )
        }
      },
      ...(!isPlace
        ? [
            {
              isActive: isActive(1),
              icons: {
                default: iconSideBarMarketing,
                active: iconSideBarMarketingActive
              },
              title: intl.formatMessage({ id: 'SIDE_NAV_SELF_MARKETING' }),
              key: 'auto',
              onClick: menuClick(1),
              drawer: {
                visible: isActive(1),
                children: (
                  <SelfMarketingPanel
                    handleClick={handleOnclickAdPoint}
                    handleDrawerClose={handleDrawerClose}
                  />
                )
              }
            }
          ]
        : [])
    ]
    contents.unshift(...pluginMenu)
  }

  return contents
}

export default getMenus
