import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
// import { assets } from '../../../utils/api'
import { message } from 'antd'
import React, { useState } from 'react'
import { assets } from '../../../utils/api'
import { useIntl } from 'react-intl'

type UseStorageInfiniteQueryParams = {
  limit: number
  type?: 'image' | 'video'
  order?: 0 | 1
  files: any[]
  setFiles: React.Dispatch<React.SetStateAction<any[]>>
}

type GetAsstsParams = Omit<
  UseStorageInfiniteQueryParams,
  'files' | 'setFiles'
> & { pageParam: number }

const getAssets = async ({
  pageParam,
  limit,
  type,
  order = 0
}: GetAsstsParams) => {
  const res = await assets().assets.getAssets({
    page: pageParam,
    limit,
    order,
    type
  })

  return res
}

const checkDuplicatedFiles = (
  files: any[],
  uploadedFiles: any[],
  setFiles: any
) => {
  const filteredFiles = files.filter((file) => {
    return !uploadedFiles.some((uploadedFile) => {
      return uploadedFile._id === file._id
    })
  })

  if (files.length !== filteredFiles.length) {
    setFiles(filteredFiles)
  }
}

export const useStorageInfiniteQuery = ({
  limit = 20,
  type,
  order = 0,
  files,
  setFiles
}: UseStorageInfiniteQueryParams) => {
  const intl = useIntl()
  const [info, setInfo] = useState<any>(null)
  const { data, fetchNextPage, isFetching, isFetched } = useInfiniteQuery(
    ['assets', type, order],
    async ({ pageParam = 1 }) => {
      const res = await getAssets({
        pageParam,
        limit,
        type,
        order
      })
      setInfo(res)
      return res?.result
    },
    {
      onSuccess: (result) => {
        if (result) {
          checkDuplicatedFiles(
            files,
            result?.pages?.flatMap((page) => page) ?? ([] as any[]),
            setFiles
          )
          return result
        }
        throw new Error('result is undefined')
      },
      onError: (error) => {
        console.log(error)
        message.error(intl.formatMessage({ id: 'STORAGE_LIST_LOAD_ERROR' }))
      },
      getNextPageParam: () => {
        if (info?.hasNext) {
          return info?.next
        }
      }
    }
  )

  const isLoading = !isFetched && isFetching

  const storageData = data?.pages?.flatMap((page) => page) ?? []

  return {
    storageData,
    fetchNextPage,
    isFetching,
    isLoading,
    resultTotalCount: info?.resultTotalCount,
    hasNext: info?.hasNext
  }
}

const deleteAssets = async (list: string[]) => {
  const res = await assets().assets.deleteAssets(list)
  return res
}

export const useDeleteAssetsMutation = (
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>
) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const { mutateAsync: deleteAssetsMutate } = useMutation(
    (list: any[]) => deleteAssets(list),
    {
      onSuccess: (result) => {
        if (!result) {
          throw new Error('result is undefined')
        }

        queryClient.invalidateQueries('assets')
        queryClient.invalidateQueries('storageInfo')
        setCheckedList([])
      },
      onError: (error) => {
        console.log(error)
        message.error(intl.formatMessage({ id: 'STORAGE_FILE_DELETE_ERROR' }))
      }
    }
  )

  return { deleteAssetsMutate }
}

const getStorageInfo = async () => {
  const res = await assets().assets.getStorageInfo()
  return res
}

export const useStorageInfoQuery = () => {
  const intl = useIntl()
  const { data: storageData } = useQuery('storageInfo', getStorageInfo, {
    staleTime: 60000,
    onSuccess: (result) => {
      if (result) {
        return result
      }
      throw new Error('result is undefined')
    },
    onError: (error) => {
      console.log(error)
      message.error(intl.formatMessage({ id: 'STORAGE_INFO_LOAD_ERROR' }))
    }
  })
  return { storageData }
}
