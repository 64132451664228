import React, { useEffect, useState } from 'react'

import qs from 'query-string'

import useLocalStorage from '../../hooks/useLocalStorage'
import { Platform } from '../../utils/libs/platform'
import { service } from '../../utils/api'
import { useCountryCodeQuery } from './_queries'
import { datadogRum } from '@datadog/browser-rum'
import { useAiStore } from '../../stores/ai'

export type TokenType = 'accessToken' | 'userId'
export type Auth = {
  token?: string
  type: TokenType
  platform: Platform
  isFirst: boolean | string
}

export const AuthInitialValue: Auth = {
  token: '',
  type: 'accessToken',
  platform: Platform.VplateDev,
  isFirst: false
}
export type Locale = 'ko' | 'en'
export const LOCALE = {
  KO: 'ko',
  EN: 'en'
} as const

export type QueryStringType = Auth & {
  user?: string
  locale?: Locale
  countryCode?: string
  from?: string
}

interface AuthHelperProps {
  projectId: string
  children: React.ReactNode
}

type UserType = {
  _id: string
  userName: string
  userPhoneNumber: string
  userEmail: string
}

const AuthHelper = ({ projectId, children }: AuthHelperProps) => {
  const [auth, setAuth] = useLocalStorage<Auth>(
    'vplateVideoEditorAuth',
    AuthInitialValue
  )
  const [next, setNext] = useState(false)
  const { setAiInfos } = useAiStore()
  const [, setCountryCode] = useLocalStorage('countryCode', '')

  const queryString = qs.parse(
    location.search.replace('?', '')
  ) as unknown as QueryStringType
  const [lastAccessTokenIssue] = useLocalStorage<Date | null>(
    'lastAccessTokenIssue',
    null
  )

  const { fetchCountry } = useCountryCodeQuery(setCountryCode)

  const setUserDatadog = (user: string) => {
    if (process.env.NODE_ENV !== 'production') return

    try {
      const userData = JSON.parse(user) as UserType
      const { _id, userName, userPhoneNumber, userEmail } = userData || {}
      datadogRum.setUser({
        id: _id,
        name: userName,
        userPhoneNumber,
        email: userEmail
      })
    } catch (error) {
      console.log('setUserDatadog error', error)
      return
    }
  }

  const checkAuth = async () => {
    const result = await service().users.getReqId(projectId)
    if (queryString?.user) {
      setUserDatadog(queryString.user)
    }

    if (queryString?.token) {
      const token = queryString.token || AuthInitialValue.token
      const type: TokenType = queryString?.type || AuthInitialValue.type
      const platform = queryString?.platform || AuthInitialValue.platform
      const isFirst =
        queryString?.isFirst === 'true' || AuthInitialValue.isFirst

      setAuth({ token, type, platform, isFirst })
    } else {
      if (lastAccessTokenIssue) {
        const diffSec =
          (new Date().getTime() - new Date(lastAccessTokenIssue).getTime()) /
          1000

        if (auth && diffSec > 10800) {
          setAuth({ ...AuthInitialValue })
        }
      }
    }

    if (result?._id) {
      setAiInfos({ ...result })
    }

    const countryCode =
      queryString?.countryCode ||
      localStorage.getItem('countryCode')?.replace(/"/g, '')

    if (countryCode) {
      setCountryCode(countryCode)
    } else {
      await fetchCountry()
    }
    setNext(true)
  }

  useEffect(() => {
    checkAuth()
  }, [])

  return <>{next && children}</>
}

export default AuthHelper
