import { ArrowRight, ToggleBackground } from './_stylesSidebar'

interface SidebarToggleProps {
  open: boolean
  handleToggleClick: () => void
}

const SidebarToggle = ({ open, handleToggleClick }: SidebarToggleProps) => {
  return (
    <div onClick={handleToggleClick}>
      <ToggleBackground />
      <ArrowRight open={open} />
    </div>
  )
}

export default SidebarToggle
