import styled, { css, keyframes } from 'styled-components'
import { tabletMedia } from './constants'
import theme from '../../styles/theme'

export const slideUp = keyframes`
  from {
    transform: translateY(300%);
  }
  to {
    height: 387px;
    transform: translateY(0);
  }
`
export const Wrapper = styled.div<{ isMobile: boolean; isKr: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 387px;
  user-select: none;
  margin-top: 12px;
  padding: 8px 16px;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      animation: ${slideUp} 0.5s ease-in;
    `};

  ${tabletMedia(
    css`
      padding: 30px 0 0;
      margin-top: 0;
    `
  )}

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        padding-top: 26px;
        max-height: none;
        padding: 0;
        margin-top: 0;
      `
    )};

  .media-title {
    width: auto;

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          width: 100%;
        `
      )};
  }
`

export const HeaderContainter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const MediaContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  background-color: ${theme.colors.f5f5};
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 20px 26px 19px 20px;
  margin-top: 12px;
  overflow-y: auto;

  ${tabletMedia(css`
    max-width: 100%;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 236px;
      min-height: 236px;
      gap: 9px;
    `)}
`

export const Info = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${theme.colors.text['#888']};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-top: 8px;

  svg {
    min-width: 18px;
  }
`

export const LottieWrapper = styled.div`
  width: calc(25% - 12px);
  max-height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        width: calc(50% - 4.5px);
      `
    )};
`

export const mediaItemStyle = css`
  width: calc(100% / 4 - 9px);

  ${({ theme }) =>
    theme.breakpoints.medium(
      css`
        width: calc(50% - 4.5px);
      `
    )};
`
