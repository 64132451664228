import { create } from 'zustand'
import { QueryStringType } from '../components/AuthHelper'

type QsStore = {
  queryStrings: Partial<QueryStringType>
  setQueryStrings: (data: Partial<QueryStringType>) => void
}

export const useQsStore = create<QsStore>((set) => ({
  queryStrings: {},
  setQueryStrings: (data: Partial<QueryStringType>) => {
    set({ queryStrings: data })
  }
}))
