import { useState } from 'react'
import { Options } from 'react-lottie'
import LottieIcon from '../components/Lottie'

const useLoading = (
  wrapperClass?: string,
  options?: Options,
  width?: number,
  height?: number
) => {
  const [isLoading, setIsLoading] = useState(false)

  const startLoading = () => {
    setIsLoading(true)
  }

  const endLoading = () => {
    setIsLoading(false)
  }

  const renderLoading = () =>
    isLoading && (
      <LottieIcon
        wrapperClass={wrapperClass}
        options={options}
        width={width}
        height={height}
      />
    )

  return { renderLoading, startLoading, endLoading, isLoading }
}
export default useLoading
