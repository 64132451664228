import styled, { css } from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding: 8px;
  height: auto;
  position: relative;
  max-width: 960px;
  padding: 0 10px;
  margin: 0 auto;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      padding: 0;
    `)}
`

export default Container
