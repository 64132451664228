import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import styled, { css } from 'styled-components'

import { ReactComponent as IconAlert } from '../../assets/icons/icon_alert_denger_circle_outline_normal_redff4545.svg'
import LoadingIndicator from '../../components/LoadingIndicator'
import { useIntl } from 'react-intl'

interface FileLoadingProps {
  isError: boolean
}

const FileLoading = ({ isError }: FileLoadingProps) => {
  const screens = useBreakpoint()
  const intl = useIntl()
  return (
    <>
      {!isError ? (
        <Container>
          <TextWrapper xs={screens.xs}>
            {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_DESCRIPTION1' })}
            <br />
            {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_DESCRIPTION2' })}
          </TextWrapper>
          <LoadingIndicator style={{ marginTop: 10 }} />
        </Container>
      ) : (
        <ErrorContainer>
          <BoldWrapper>
            <CIconAlert />
            {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_ERROR1' })}
          </BoldWrapper>
          <ErrorTextWrapper>
            <div>
              {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_ERROR2' })}
            </div>
            <div>
              {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_ERROR3' })}
            </div>
            <div>
              {intl.formatMessage({ id: 'VIDEO_INPUT_ENCODING_ERROR4' })}
            </div>
          </ErrorTextWrapper>
        </ErrorContainer>
      )}
    </>
  )
}

export default FileLoading

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  z-index: 100;
  color: ${({ theme }) => theme.colors.black}B3;
  font-size: 14px;
  font-weight: bold;
  word-break: keep-all;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
  padding: 40px 60px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  margin: 0 auto;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      padding: 25px 20px 25px 50px;
      margin: auto;
    `)};
`

const BoldWrapper = styled.div`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: bold;
`

const CIconAlert = styled(IconAlert)`
  width: 20px;
  position: absolute;
  top: 1px;
  left: -25px;
`

const TextWrapper = styled.div<{ xs?: boolean }>`
  text-align: center;
  ${({ xs }) => xs && `white-space: pre-wrap;`}
`

const ErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
