import { IconInfoLine, IconMediaImageList } from '../../assets/icons'
import { useEffect, useRef, useState } from 'react'
import useToggle from '../../hooks/useToggle'
import useFormatText from '../../hooks/useFormatText'
import LottieIcon from '../../components/Lottie'
import MediaItem from './MediaItem'
import { Media } from '../../types/project'
import {
  MediaContainer,
  HeaderContainter,
  Info,
  LottieWrapper,
  Wrapper,
  mediaItemStyle
} from './_stylesAIMedia'
import AiSubtitle from './AiSubtitle'

interface AIMediaProps {
  medias?: Media[]
  isMobile: boolean
  progress?: number
  isComplete?: boolean
  mediaTotalLength?: number
  isReset: boolean
}

const AiMedia = ({
  medias,
  isMobile,
  progress,
  mediaTotalLength,
  isReset
}: AIMediaProps) => {
  const { isToggled, setIsToggled, toggle } = useToggle()
  const [copyMedias, setCopyMedias] = useState<Media[]>([])
  const timerRef = useRef<NodeJS.Timeout>()
  const mediaListRef = useRef<HTMLDivElement | null>(null)
  const lastMediaItemRef = useRef<HTMLDivElement | null>(null)
  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  useEffect(() => {
    if (mediaTotalLength) {
      setIsToggled(true)
    }
  }, [mediaTotalLength])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isReset) {
      setCopyMedias([])
    }
  }, [isReset])

  useEffect(() => {
    if (medias && medias?.length !== copyMedias?.length) {
      setCopyMedias(medias)
    }
  }, [medias, copyMedias])

  useEffect(() => {
    if (lastMediaItemRef.current && mediaListRef.current) {
      const parentElement = mediaListRef.current
      const elementToScroll = lastMediaItemRef.current

      const parentRect = parentElement.getBoundingClientRect()
      const elementRect = elementToScroll.getBoundingClientRect()

      if (elementRect.bottom > parentRect.bottom) {
        parentElement.scrollTop += elementRect.bottom - parentRect.bottom
      }
    }
  }, [copyMedias])

  return (
    <Wrapper isMobile={isMobile} isKr={isKr}>
      <HeaderContainter>
        <AiSubtitle
          isToggled={isToggled}
          toggle={toggle}
          icon={<IconMediaImageList />}
          subtitle={useFormatText('AI_LOADING_MODAL_STEP3')}
          progressValue={progress || 0}
          progressText='3'
          isShowArrow={isMobile}
          className='media-title'
        />
        {!isMobile && isKr && (
          <Info>
            <IconInfoLine />
            {useFormatText('AI_LOADING_MODAL_STEP3_INFO1')}
          </Info>
        )}
      </HeaderContainter>
      {isToggled && (
        <MediaContainer ref={mediaListRef}>
          {Array.isArray(copyMedias) &&
            copyMedias.map((media) => {
              return (
                <MediaItem
                  key={media.url}
                  media={media}
                  ref={lastMediaItemRef}
                  style={mediaItemStyle}
                />
              )
            })}
          {mediaTotalLength &&
            medias &&
            mediaTotalLength > copyMedias?.length && (
              <LottieWrapper>
                <LottieIcon width={60} height={60} />
              </LottieWrapper>
            )}
        </MediaContainer>
      )}
      {isToggled && (isMobile || !isKr) && (
        <Info style={{ marginTop: 8 }}>
          <IconInfoLine />
          {useFormatText('AI_LOADING_MODAL_STEP3_INFO1')}
        </Info>
      )}
    </Wrapper>
  )
}

export default AiMedia
