import styled, { css } from 'styled-components'

export const ObserveElement = styled.div`
  height: 10px;
  margin: 30px 0px;
`

export const BlankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  svg {
    width: 250px;

    ${({ theme }) =>
      theme.breakpoints.small(css`
        width: 200px;
      `)}
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      flex-direction: column;
    `)}
`

export const Content = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      position: relative;
      flex: 1;

      & > div {
        position: absolute;
        inset: 0;
        overflow-y: auto;
      }
    `)}
`

export const AutoContent = styled(Content)`
  height: 55vh;
  padding-top: 10px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(55vh - 166px);
    `)}
`

export const PreviewPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      max-width: unset;
      margin-bottom: 10px;
    `)}
`

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 16px;
`

export const PreviewTitle = styled.div`
  width: 100%;
  min-height: 52px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: none;
    `)}
`

export const SearchForm = styled.form<{ innerHeight?: number }>`
  width: 100%;
  height: auto;
  transform: translateY(-1px);
  position: relative;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      background-color: transparent;
      user-select: none;
      width: 100%;
    `)}
`

export const LottieWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const GAP = 12
export const ImageList = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  gap: ${GAP}px;
  flex-wrap: wrap;
  justify-content: flex-start;
`

type ImageListItemProps = {
  src?: string
  selected?: boolean
  isPreviewLoading?: boolean
}
export const ImageListItem = styled.button.attrs<ImageListItemProps>(
  ({ src }) => ({
    style: {
      backgroundImage: `url('${src}')`
    }
  })
)<ImageListItemProps>`
  width: calc(50% - ${GAP / 2}px);
  height: auto;
  position: relative;
  display: inline-block;
  border: 0.125rem solid transparent;
  cursor: ${({ isPreviewLoading }) =>
    isPreviewLoading ? 'not-allowed' : 'pointer'};
  padding: 0;
  margin: 0;
  background-color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: border 0.2s ease-in-out;
  ${({ selected }) =>
    selected &&
    css`
      border: 0.125rem solid ${({ theme }) => theme.colors.primary};
    `}
`
ImageListItem.defaultProps = { type: 'button' }

export const FileInput = styled.input`
  width: 0px;
  height: 0px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
`
