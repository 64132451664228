import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Editor from './Editor'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { IntlProvider } from 'react-intl'
import ko from './locale/ko.json'
import en from './locale/en.json'
import { LOCALE, Locale, QueryStringType } from './components/AuthHelper'
import { ToggleProvider } from './contexts/ToggleContext'
import qs from 'query-string'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import koLocale from 'dayjs/locale/ko'
import enLocale from 'dayjs/locale/en'
import ReactGA from 'react-ga4'
import { useQsStore } from './stores/qs'

dayjs.extend(localizedFormat)

const messages = { ko, en }

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const App: React.FC = () => {
  const queryString = qs.parse(
    location.search.replace('?', '')
  ) as unknown as QueryStringType
  const initLocale = navigator.language.includes('ko') ? LOCALE.KO : LOCALE.EN
  const locale = queryString?.locale || initLocale

  const setQueryStrings = useQsStore((state) => state.setQueryStrings)

  dayjs.locale(locale === LOCALE.KO ? koLocale : enLocale)

  useEffect(() => {
    if (locale) {
      let _locale = locale as 'kr' | Locale
      if (_locale === 'kr') _locale = 'ko'
      localStorage.setItem('locale', _locale)
    }
  }, [locale])

  useEffect(() => {
    ReactGA.initialize('G-FD5EV8PZDZ')
  }, [])

  useEffect(() => {
    setQueryStrings(queryString)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale={locale} messages={messages[locale as Locale]}>
        <ToggleProvider>
          <ThemeProvider theme={theme}>
            <div className='App' style={{ height: '100%' }}>
              <Switch>
                <Route path='/:projectId'>
                  <Editor />
                </Route>
              </Switch>
            </div>
            <ReactQueryDevtools />
          </ThemeProvider>
        </ToggleProvider>
      </IntlProvider>
    </QueryClientProvider>
  )
}

export default App
