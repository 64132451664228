/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components'
import { useAiStore } from '../../stores/ai'
import RestScenario from './RestScenario'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, { ContentCardProps } from '../ModalContentCard'
import InputElement from '../Input'
import DefaultButton from '../DefaultButton'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useEffect, useMemo, useState } from 'react'

const MarketingDataEditor = ({
  handleClick
}: {
  handleClick: (persona: string) => void
}) => {
  const { isMobile } = useBreakpoint()

  const MarketingPlanLimit = 5

  const { aiInfos } = useAiStore()
  const [persona, setPersona] = useState('')
  const [selfPlanList, setSelfPlanList] = useState<ContentCardProps[][]>([])

  const restMarketingPlan = useMemo(() => {
    return aiInfos?.persona
      ? MarketingPlanLimit - (aiInfos?.persona?.length - 1)
      : MarketingPlanLimit
  }, [aiInfos?.persona])

  const currentCategory = aiInfos?.category

  const isInformation = !!currentCategory && currentCategory === 102
  const isPromotion = !!currentCategory && currentCategory === 101
  useEffect(() => {
    setSelfPlanList([])
    if (aiInfos?.persona && aiInfos?.persona?.length > 1) {
      for (let i = aiInfos?.persona?.length - 1; i > 0; i--) {
        const currentPersona: ContentCardProps | null = aiInfos?.persona[i]
          ? {
              title: 'SIDE_NAV_MARKETING_CONTENT1',
              contentList: [{ title: aiInfos?.persona[i]?.character }]
            }
          : null

        const currentHooking: ContentCardProps | null =
          aiInfos?.hooking && aiInfos?.hooking[i]
            ? {
                title: 'SIDE_NAV_MARKETING_CONTENT5',
                contentList: [
                  {
                    content: [
                      aiInfos?.hooking[i]?.short || '',
                      aiInfos?.hooking[i]?.detailed || ''
                    ]
                  }
                ]
              }
            : null

        const currentMoving: ContentCardProps | null =
          aiInfos?.moving && aiInfos?.moving[i]
            ? {
                title: 'SIDE_NAV_MARKETING_CONTENT6',
                contentList: [
                  {
                    content: [
                      aiInfos?.moving[i]?.short || '',
                      aiInfos?.moving[i]?.detailed || ''
                    ]
                  }
                ]
              }
            : null

        const currentPromotionTitle: ContentCardProps | null =
          aiInfos?.promotionTitle && aiInfos?.promotionTitle[i]
            ? {
                title: 'SIDE_NAV_MARKETING_CONTENT7',
                contentList: [
                  {
                    content: [aiInfos?.promotionTitle[i] || '']
                  }
                ]
              }
            : null

        const currentPromotionDetail: ContentCardProps | null =
          aiInfos?.promotionDetail && aiInfos?.promotionDetail[i]
            ? {
                title: 'SIDE_NAV_MARKETING_CONTENT8',
                contentList: [
                  {
                    content: [aiInfos?.promotionDetail[i]]
                  }
                ]
              }
            : null

        const ContentList: ContentCardProps[] = []

        if (currentPersona) {
          ContentList.push(currentPersona)
        }
        if (isInformation && currentHooking) {
          ContentList.push(currentHooking)
        }
        if (isInformation && currentMoving) {
          ContentList.push(currentMoving)
        }
        if (isPromotion && currentPromotionTitle) {
          ContentList.push(currentPromotionTitle)
        }
        if (isPromotion && currentPromotionDetail) {
          ContentList.push(currentPromotionDetail)
        }
        setSelfPlanList((state) => [...state, ContentList])
      }
    }
  }, [aiInfos?.persona])

  const handleClickWrapper = () => {
    if (restMarketingPlan <= 0) return
    handleClick(persona)
  }

  return (
    <Wrapper>
      <RestScenario
        scenarioLimit={MarketingPlanLimit}
        restScenarioCount={restMarketingPlan}
        // isExcededLimit={true}
      />
      {/* {isPersonaEditor || (
        <EditorBtn onClick={() => setIsPersonaEditor(true)}>
          <IconEditor />
        </EditorBtn>
      )} */}
      <div className='persona-input-wrap'>
        <InputElement
          className='persona-input'
          name='query'
          placeholder={useFormatText('SIDE_NAV_MARKETING_CONTENT_PLACEHOLDER')}
          block
          type='text'
          autoComplete='off'
          value={persona}
          onChange={(e) => {
            setPersona(e.target.value)
          }}
        />
        <DefaultButton
          fullWidth={isMobile}
          category='secondaryMulticolored'
          disabled={restMarketingPlan <= 0 || !persona}
          onClick={handleClickWrapper}
        >
          {useFormatText('SIDE_NAV_MARKETING_CONTENT_SUBMIT')}
        </DefaultButton>
      </div>

      {selfPlanList.length > 0 &&
        selfPlanList.map((selfPlan, index) => (
          <div key={'self-marketing-' + index} className='self-marketing-list'>
            {selfPlan.map((content, idx) => (
              <ContentCard
                key={'marketing-data-list-' + idx}
                title={useFormatText(content.title)}
                content={content.content}
                contentList={content.contentList}
                notice={content.notice}
              />
            ))}
          </div>
        ))}
    </Wrapper>
  )
}

export default MarketingDataEditor

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  line-height: 1.5;
  padding-bottom: 60px;

  .persona-input-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .persona-input {
    height: 40px;
    border-radius: 4px;
    input {
      width: 100%;
      height: 36px;
      font-size: 14px;
      line-height: 140%;
    }
  }
  .self-marketing-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .content-card-title {
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 16px;
    line-height: 100%;
  }
  .content-card-container {
    font-size: 18px !important;
    font-weight: 700;
    background-color: #f9f9f9;
  }

  .content-card-container-list {
    padding: 0;
    gap: 20px;
    .content-card-container-title {
      font-size: 18px;
    }
    ol {
      background-color: #f9f9f9;
      li {
        font-size: 16px;
      }
    }
  }
`
