export const calculateDiscountRate = (
  originalPrice: number | string,
  discountPrice: number | string
): number | null => {
  // 숫자 또는 숫자 형식의 문자열로 변환
  const originalPriceNumber =
    typeof originalPrice === 'string'
      ? parseFloat(originalPrice)
      : originalPrice
  const discountPriceNumber =
    typeof discountPrice === 'string'
      ? parseFloat(discountPrice)
      : discountPrice

  // 유효한 숫자인지 확인
  if (isNaN(originalPriceNumber) || isNaN(discountPriceNumber)) {
    return 0
  }

  // 원래 가격이 0보다 큰지 확인
  if (originalPriceNumber <= 0) {
    return 0
  }

  // 할인된 가격이 0 이상이고 원래 가격 이하인지 확인
  if (discountPriceNumber < 0 || discountPriceNumber > originalPriceNumber) {
    return 0
  }

  // 할인율 계산
  const discount = originalPriceNumber - discountPriceNumber
  const rate = discount / originalPriceNumber
  const discountRate = isNaN(rate) ? 0 : Math.round(rate * 100) // 소수점 없이 반올림된 정수로 반환

  return discountRate
}
