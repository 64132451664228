import React, { useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'

import { iconPhotoModalPreviewDefault } from '../../assets/icons'
import CropImage from '../CropFile/CropImage'
import useLoading from '../../hooks/useLoading'
import FileLoading from '../CropFile/FileLoading'
import useFetchPexels, { MEDIA_TYPE } from '../../hooks/useFetchPexels'
import { PreviewPanel, PreviewTitle, Wrapper } from './_stylesModal'
import PhotoModalTabs from './PhotoModalTabs'
import { useIntl } from 'react-intl'
import FileInfos from './Storage/FileInfos'
import useBreakpoint from '../../hooks/useBreakpoint'
import FilePreview from './FilePreview'
import MediaModalButtons from './MediaModalButtons'
import { lambdaResizeImage } from '../../utils/libs/lambdaResizeImage'

//TYPE //////////////////////////////////////////////

type SourceSizeType = {
  sourceWidth: number
  sourceHeight: number
}

type PhotoModalProps = ModalProps & {
  value?: string
  onComplete?(dataUrl: string): void
  aspect?: number
  isError?: boolean
  sourceSize: SourceSizeType
  innerHeight?: number
}

//TYPE FIN //////////////////////////////////////////////

const PhotoModal = (props: PhotoModalProps) => {
  const {
    onClose,
    visible,
    onComplete,
    aspect = 1,
    isError = false,
    sourceSize
  } = props

  const intl = useIntl()
  const theme = useTheme()
  const { isBreakpoint } = useBreakpoint()

  const [currentStep, setCurrentStep] = useState(0)
  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [query, setQuery] = useState('')
  const [showDropbox, setShowDropBox] = useState(false)
  const [orientation, setOrientation] = useState<string>('')
  const [selectedAssetInfo, setSelectedAssetInfo] = useState<any>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  // 크롭 로딩 중일 땐 모달 닫기 불가
  const closable = !(currentStep === 2 && !isError)

  const {
    isLoading: previewLoading,
    renderLoading: previewLoad,
    startLoading: previewLoadStart,
    endLoading: previewLoadEnd
  } = useLoading()

  const {
    renderLoading: listLoad,
    startLoading: listLoadStart,
    endLoading: listLoadEnd
  } = useLoading()

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
        setSelectedTabIndex(0)
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  //NOTE: PEXEL

  const {
    media: imageList,
    fetchNextPage,
    refetchMedia: refetchImage,
    isFetching
  } = useFetchPexels({
    type: MEDIA_TYPE.PHOTO,
    words: query,
    orientation: orientation,
    preventFetch: !visible
  })

  const handleSearch: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    setQuery(formData.get('query') as string)
  }

  useEffect(() => {
    isFetching ? listLoadStart() : listLoadEnd()
  }, [isFetching, listLoadEnd, listLoadStart])

  // NOTE: Refetches if orientation changes

  useEffect(() => {
    setShowDropBox(query !== '')
  }, [query])

  useEffect(() => {
    visible && refetchImage()
  }, [orientation, refetchImage])

  //NOTE: PEXEL FIN

  return (
    <Modal
      title={intl.formatMessage({
        id: currentStep === 0 ? 'IMAGE_INPUT_TITLE' : 'IMAGE_INPUT_EDIT'
      })}
      visible={visible}
      onClose={onClose}
      flowHidden
      closable={closable}
      closeBtnType='round'
      style={css`
        padding: 24px;
      `}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        {currentStep === 0 && !isBreakpoint('medium') && (
          <>
            <Wrapper>
              <PreviewPanel>
                <PreviewTitle>
                  <Typography bold block center type='body2'>
                    {intl.formatMessage({ id: 'IMAGE_INPUT_PREVIEW' })}
                  </Typography>
                </PreviewTitle>
                <ImageWrapper
                  innerHeight={document.documentElement.clientHeight}
                >
                  {!selectedImageUrl && (
                    <>
                      <img
                        src={iconPhotoModalPreviewDefault}
                        alt='preview'
                        style={{ width: 56, marginBottom: 16 }}
                      />
                      <Typography
                        block
                        center
                        type='body2'
                        style={{
                          color: theme.colors.text.secondary,
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {intl.formatMessage({
                          id: 'IMAGE_INPUT_PREVIEW_DEFAULT'
                        })}
                      </Typography>
                    </>
                  )}
                  {selectedImageUrl && (
                    <PhotoLottieWrapper>{previewLoad()}</PhotoLottieWrapper>
                  )}
                  {selectedImageUrl && (
                    <img
                      onLoad={() => previewLoadEnd()}
                      className='selected-image'
                      src={lambdaResizeImage(selectedImageUrl, 260)}
                      alt='preview'
                    />
                  )}
                  {selectedImageUrl && selectedAssetInfo && (
                    <FileInfos selectedAssetInfo={selectedAssetInfo} />
                  )}
                </ImageWrapper>

                <MediaModalButtons
                  okButtonDisabled={!selectedImageUrl}
                  cancelButtonDisabled={!selectedImageUrl}
                  onClickLoad={() => setCurrentStep(1)}
                  onClickCancel={() => setSelectedImageUrl('')}
                  loading={previewLoading}
                />
              </PreviewPanel>
              <div
                style={{
                  width: 1,
                  height: 'calc(100% - 64px)',
                  background: '#E1E1E1',
                  alignSelf: 'flex-end',
                  margin: '0 16px'
                }}
              />
              <PhotoModalTabs
                handleSearch={handleSearch}
                showDropbox={showDropbox}
                setOrientation={setOrientation}
                imageList={imageList}
                isFetching={isFetching}
                listLoad={listLoad}
                fetchNextPage={fetchNextPage}
                selectedImageUrl={selectedImageUrl}
                setSelectedImageUrl={setSelectedImageUrl}
                previewLoadStart={previewLoadStart}
                previewLoading={previewLoading}
                setSelectedAssetInfo={setSelectedAssetInfo}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
              />
            </Wrapper>
          </>
        )}
        {currentStep === 0 && isBreakpoint('medium') && (
          <>
            {!selectedImageUrl && (
              <PhotoModalTabs
                handleSearch={handleSearch}
                showDropbox={showDropbox}
                setOrientation={setOrientation}
                imageList={imageList}
                isFetching={isFetching}
                listLoad={listLoad}
                fetchNextPage={fetchNextPage}
                selectedImageUrl={selectedImageUrl}
                setSelectedImageUrl={setSelectedImageUrl}
                previewLoadStart={previewLoadStart}
                previewLoading={previewLoading}
                setSelectedAssetInfo={setSelectedAssetInfo}
                selectedTabIndex={selectedTabIndex}
                setSelectedTabIndex={setSelectedTabIndex}
              />
            )}
            {selectedImageUrl && (
              <FilePreview
                type='image'
                selectedImageUrl={selectedImageUrl}
                setSelectedImageUrl={setSelectedImageUrl}
                selectedAssetInfo={selectedAssetInfo}
                previewLoad={previewLoad}
                previewLoadEnd={previewLoadEnd}
                handleLoadClick={() => setCurrentStep(1)}
              />
            )}
          </>
        )}
        {currentStep === 1 && (
          <CropImage
            image={selectedImageUrl}
            aspect={aspect}
            restrictPosition
            onCrop={async (dataUrl) => {
              setCurrentStep(2)
              onComplete && onComplete(dataUrl)
            }}
            sourceSize={sourceSize}
            onClose={onClose}
          />
        )}
        {currentStep === 2 && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FileLoading isError={isError} />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PhotoModal

// STYLE ////////////////////////////////////////

export const ImageWrapper = styled.div<{
  innerHeight?: number
}>`
  position: relative;
  width: 100%; // NOTE: mobile issue
  height: calc(100% - 51px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-y: auto;

  .selected-image {
    width: 100%;
    height: 100%;
    max-width: 376px;
    max-height: 260px;
    object-fit: contain;
    object-position: center center;

    ${({ theme, innerHeight }) =>
      theme.breakpoints.medium(css`
        max-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}

    ${({ theme, innerHeight }) =>
      theme.breakpoints.small(css`
        min-height: ${innerHeight && innerHeight > 667 ? '25vh' : '20vh'};
      `)}
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: auto;
      justify-content: center;
      padding: 0;
    `)}
`

export const PhotoLottieWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
`
