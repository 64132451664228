/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components'
import { useAiStore } from '../../stores/ai'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, {
  ContentCardProps,
  ContentListType
} from '../ModalContentCard'
import { PROJECT_CATEGORY } from '../../types/constants'

const MarketingDataPlace = () => {
  const { aiInfos } = useAiStore()

  const category = aiInfos?.category
  const isProduct = category === PROJECT_CATEGORY.PLACE.PRODUCT
  const isReview = category === PROJECT_CATEGORY.PLACE.REVIEW

  const currentReviewFeature = aiInfos?.productReviewCopy
    ? aiInfos?.productReviewCopy[0]
    : undefined
  const currentProductFeature = aiInfos?.productFeature
    ? aiInfos?.productFeature[0]
    : undefined

  const currentFeature = aiInfos?.feature ? aiInfos?.feature[0] : undefined

  const ContentList: ContentCardProps[] = [
    { title: 'text_0384', content: aiInfos?.name || '' },
    ...(isReview
      ? [
          {
            title: 'text_0387',
            contentList: currentReviewFeature
              ? currentReviewFeature?.reduce(
                  (accumulator: ContentListType[], currentValue, index) => {
                    accumulator.push({
                      title: aiInfos?.review?.[index]?.nickname || '',
                      content: Object.values(currentValue)
                    })
                    return accumulator
                  },
                  []
                )
              : undefined
          }
        ]
      : []),
    ...(isProduct
      ? [
          {
            title: 'text_0386',
            contentList: currentProductFeature
              ? currentProductFeature?.reduce(
                  (accumulator: ContentListType[], currentValue) => {
                    accumulator.push({
                      title: currentValue.name,
                      content: [currentValue?.short, currentValue?.medium || '']
                    })
                    return accumulator
                  },
                  []
                )
              : undefined
          }
        ]
      : []),
    {
      title: 'text_0385',
      contentList: currentFeature
        ? currentFeature?.reduce(
            (accumulator: ContentListType[], currentValue) => {
              if (currentValue.feature)
                accumulator.push({
                  title: currentValue.feature,
                  content: [
                    currentValue?.short,
                    currentValue?.detailed || currentValue?.medium || ''
                  ]
                })
              return accumulator
            },
            []
          )
        : undefined
    }
  ]

  return (
    <Wrapper>
      {ContentList.map((content, idx) => (
        <ContentCard
          key={'marketing-data-list-' + idx}
          title={useFormatText(content.title)}
          content={content.content}
          contentList={content.contentList}
          notice={content.notice}
        />
      ))}
    </Wrapper>
  )
}

export default MarketingDataPlace

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  line-height: 1.5;
  padding-bottom: 60px;

  .content-card-title {
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
  }
  .content-card-container {
    font-size: 16px !important;

    background-color: #f9f9f9;
  }

  .content-card-container-list {
    padding: 0;
    gap: 20px;
    .content-card-container-title {
      font-size: 18px;
    }
    ol {
      background-color: #f9f9f9;
      li {
        font-size: 16px;
      }
    }
  }

  .content-card-notice {
    padding: 0;
    color: #ff4c4c;
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
  }

  .persona-input {
    height: 40px;
    border-radius: 4px;
    input {
      width: 100%;
      height: 36px;
      font-size: 14px;
      line-height: 140%;
    }
  }
`
