import { useEffect, useRef } from 'react'

function useAutoScroll<T extends HTMLElement>() {
  const scrollRef = useRef<T | null>(null)

  useEffect(() => {
    const scrollContainer = scrollRef.current

    if (!scrollContainer) return

    const observer = new MutationObserver(() => {
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight
      }
    })

    const config = { childList: true, subtree: true, attributes: true }
    observer.observe(scrollContainer, config)

    return () => {
      observer.disconnect()
    }
  }, [])

  return scrollRef
}

export default useAutoScroll
