import styled, { css } from 'styled-components'
import Modal from '../../components/Modal'
import theme from '../../styles/theme'

export const StyledModal = styled(Modal)``

export const Title = styled.div`
  color: ${theme.colors.text['#222']};
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  align-items: flex-start;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
`

export const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1348px;
  aspect-ratio: 16/9;
  flex: 1;
`

export const TimeLineWrapper = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 428px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;
  overflow: auto;
  background-color: #f4f4f4;

  d & > div {
    background: ${theme.colors.white};
  }

  ${({ isMobile }) =>
    isMobile &&
    ` max-width: 100%;
      height: calc(100% - 336px);
  `}
`

export const CurriculumTitle = styled.div<{ isMobile: boolean }>`
  padding: 19px 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  border-bottom: 1px solid ${theme.colors.border.secondary};
  background: ${theme.colors.white};

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 16px;    
    padding: 16px 20px;
  `}
`

export const wrapperStyle = css`
  ${({ theme }) =>
    theme.breakpoints.xxxLarge(css`
      max-width: 100%;
      width: 95%;
      max-height: 95vh;
      height: 95vh;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `)}
`

export const titleBarStyle = css`
  padding: 25px 30px;
  background: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.border.primary};
  height: 80px;
`

export const containerStyle = css`
  display: flex;
  height: calc(100% - 80px);
  padding: 0px;
  padding: 0px;
`

export const TimeLineList = styled.ul<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  background: ${theme.colors.white};

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 20px;
    border-bottom: 1px solid ${theme.colors.border.primary};
  }

  li:first-child {
    border-top: 1px solid ${theme.colors.border.primary};
  }

  ${({ isMobile }) =>
    isMobile &&
    ` 
    li {
      padding: 0 16px;
    }
`}
`

export const TimeLineLi = styled.li<{ isCurrentTimeInSection: boolean }>`
  color: ${theme.colors.text['#333']};
  cursor: pointer;

  ${({ isCurrentTimeInSection }) =>
    isCurrentTimeInSection
      ? css`
          background-color: ${theme.colors.tutorial.active};
          color: ${theme.colors.white};
          font-weight: 500;

          & > div {
            color: ${theme.colors.white};
            font-weight: 500;
          }
        `
      : css`
          &:hover {
            background-color: ${theme.colors.tutorial.hover};
          }
        `};
`

export const TimeLineItemLeft = styled.div<{ isMobile: boolean }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;

  ${({ isMobile }) =>
    isMobile &&
    ` 
    font-size: 14px;
`}
`
export const TimeLineItemRight = styled.div<{ isMobile: boolean }>`
  color: ${theme.colors.text['#888']};
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;

  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 13px;
  `}
`

export const Notice = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 18px;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      color: ${theme.colors.text['#888']};
      font-size: 14px;
      font-weight: 500;
      line-height: 120%;
    }
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    p {
      font-size: 12px;
    }
  `}
`

export const NoticeIcon = styled.div<{ icon: string }>`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  padding: 0;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
`

export const MobileModal = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  height: 100svh;
  background: #f4f4f4;
`

export const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  width: 100%;
  height: 44px;
  background: #e9e9e9;

  color: ${theme.colors.text['222']};
  font-size: 16px;
  font-weight: 600;
  line-height: 120%;
`

export const MobileVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 292px;
`

export const LineCloseBtn = styled.div<{ icon: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  cursor: pointer;
`

export const LottieWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
