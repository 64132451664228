import styled, { css } from 'styled-components'
import Container from '../../components/Container'

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundEditor};
`

export const Layout = styled.div`
  width: 100%;
  height: calc(100vh - 91px);
  height: calc(100svh - 91px);
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100vh - 130px);
      height: calc(100svh - 130px);
      margin-top: 56px;
    `)};
`

const DRWER_WIDTH = 480
export const Content = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  align-items: top;
  justify-content: center;
  transition: all 0.4s ease-in-out;

  ${({ theme }) =>
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
      }
    `)};

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      margin-left: ${DRWER_WIDTH / 2}px;
    `}

  ${({ theme }) =>
    theme.breakpoints.xLarge(
      css`
        margin-left: 0;
      `
    )}
`

export const MainContainer = styled(Container)<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      width: calc(100% - ${DRWER_WIDTH}px);
    `};

  ${({ theme }) =>
    theme.breakpoints.xLarge(
      css`
        width: 100%;
      `
    )}
`

export const CenterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ScreenWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 250px);

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      height: calc(100% - 300px);
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - 270px);
    `)}
`

export const Info = styled.div`
  width: 100%;
  height: 46px;
  position: relative;
  padding: 13px 10px;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 4px;
  }
`
