import styled, { css } from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import { getPlanColor, getPlanTitle } from './constants'
import useBreakpoint from '../../hooks/useBreakpoint'
import theme from '../../styles/theme'
import { PlanType } from '../../types/constants'

const PlanTitleBadge = ({
  planType,
  style
}: {
  planType?: PlanType
  style?: React.CSSProperties
}) => {
  const { isMobile } = useBreakpoint()
  return (
    <Badge
      backgroundColor={getPlanColor(planType)}
      isMobile={isMobile}
      style={{ ...style }}
    >
      {useFormatText(getPlanTitle(planType))}
    </Badge>
  )
}

export default PlanTitleBadge

const Badge = styled.div<{
  backgroundColor: string
  isMobile: boolean
}>`
  display: inline-flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 24px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${theme.colors.white};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: auto;
      margin-bottom: 0px;
    `};
`
