import { useQuery } from 'react-query'
import { service } from '../../utils/api'

const fetchCountryCode = async () => {
  return await service().users.getUserLocation()
}

export const useCountryCodeQuery = (
  setCountryCode: (value: string | ((val: string) => string)) => void
) => {
  const { refetch: fetchCountry } = useQuery(
    'getCountryCode',
    fetchCountryCode,
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: false,
      onSuccess: (countryCode) => {
        setCountryCode(countryCode.replace(/"/g, ''))
      },
      onError: (e) => {
        setCountryCode('US')
        console.log(e)
      }
    }
  )

  return { fetchCountry }
}
