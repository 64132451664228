import Audio from '../Audio'
import { PreviewMusicProps } from './types'
import EditMusicLayout from './EditMusicLayout'

const PreviewMusic = ({
  onClose,
  selectedMusic,
  setSelectedMusic,
  setDrawerPage,
  handlePlay,
  handlePause
}: PreviewMusicProps) => {
  const handleClose = () => {
    setSelectedMusic(null)
    onClose()
  }

  return (
    <EditMusicLayout
      title={selectedMusic?.musicTitle}
      description={selectedMusic?.musicDescription}
      onClose={handleClose}
      onOk={() => setDrawerPage('trim')}
      onCloseText='SIDE_NAV_BGM_CONTENT_CLOSE'
      onOkText='SIDE_NAV_BGM_CONTENT_CLIP'
    >
      <Audio
        src={selectedMusic?.musicUrl}
        handlePlay={handlePlay}
        handlePause={handlePause}
      />
    </EditMusicLayout>
  )
}

export default PreviewMusic
