import axiosConfig from './axiosConfig'

function api() {
  const axios = axiosConfig()

  const assets = {
    async getAssets({
      page,
      limit,
      order,
      type
    }: {
      page: number
      limit: number
      order: 0 | 1
      type?: 'image' | 'video'
    }) {
      try {
        const result = await axios.get('/asset', {
          params: {
            page,
            limit,
            type,
            order
          },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })

        if (result?.data?.code === 'SUCCESS') {
          return result?.data?.data
        }

        throw new Error(result?.data?.errMsg)
      } catch (error) {
        console.log(error)
      }
    },
    async deleteAssets(list: string[]) {
      try {
        const result = await axios.delete('/asset', {
          data: { deleteList: list },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          return result?.data
        }
        throw new Error(result?.data?.errMsg)
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }
      }
    },
    async uploadAssets(
      {
        userId,
        size,
        resolution,
        duration,
        type,
        file
      }: {
        userId: string
        size: number
        resolution: string
        duration?: string
        type: 'image' | 'video'
        file: File
      },
      handleUploadProgress: (progressEvent: any) => void
    ) {
      const formData = new FormData()
      formData.append('userId', userId)
      formData.append('size', size.toString())
      formData.append('resolution', resolution)
      duration && formData.append('duration', duration)
      formData.append('type', type)
      formData.append('file', file)
      formData.append('originalName', file.name)

      try {
        const result = await axios({
          method: 'post',
          url: `/asset`,
          baseURL: process.env.REACT_APP_API_URL,
          data: formData,
          onUploadProgress: handleUploadProgress,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        if (result?.data?.code === 'SUCCESS') {
          return result?.data?.data
        }
        throw new Error(result?.data?.errMsg)
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = (() => {
            switch (error.message) {
              case 'INVALID FILE TYPE':
                return 'STORAGE_UPLOAD_ERROR1'
              case 'EXCEED REMAINING SPACE':
                return 'STORAGE_UPLOAD_ERROR2'
              case 'INTERNAL SERVER ERROR':
                return 'STORAGE_UPLOAD_ERROR3'
              default:
                return 'STORAGE_UPLOAD_ERROR4'
            }
          })()
          if (errorMessage) {
            throw errorMessage
          }
        }
      }
    },
    async getStorageInfo() {
      try {
        const result = await axios({
          method: 'get',
          url: `/asset/info`,
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        })
        if (result?.data?.code === 'SUCCESS') {
          return result?.data?.data
        }
        throw new Error(result?.data?.errMsg)
      } catch (error) {
        console.log(error)
        if (error instanceof Error) {
          throw new Error(error.message)
        }
      }
    }
  }

  return {
    assets
  }
}

export default api
