export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const loadImage = (src: string) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', () => {
        if (src === url) {
          const proxyUrl = process.env.REACT_APP_CORS_PROXY_URL + url
          loadImage(proxyUrl)
        } else {
          reject(new Error('Failed to load the image.'))
        }
      })
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = src
    }

    loadImage(url)
  })

export async function rotateImage(
  imageSrc: string,
  rotation = 0
): Promise<string> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!

  const orientationChanged =
    rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270
  if (orientationChanged) {
    canvas.width = image.height
    canvas.height = image.width
  } else {
    canvas.width = image.width
    canvas.height = image.height
  }

  ctx.translate(canvas.width / 2, canvas.height / 2)
  ctx.rotate((rotation * Math.PI) / 180)
  ctx.drawImage(image, -image.width / 2, -image.height / 2)

  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file))
      } else {
        reject(new Error('parameter "file" is null'))
      }
    }, 'image/png')
  })
}
