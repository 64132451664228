import React, { useState, useEffect, useRef, useCallback } from 'react'
import styled from 'styled-components'
import Cropper from 'react-easy-crop'
import Button from '../../components/Button'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import Trimmer from './Trimmer'
import useLoading from '../../hooks/useLoading'
import ZoomButtons from './ZoomButtons'
import { Container } from './_stylesCropImage'
import Typography from '../../components/Typography'
import { CropData } from '../EditModals/VideoModal'
import { useIntl } from 'react-intl'
import useBreakpoint from '../../hooks/useBreakpoint'
import MoblieButtons from '../EditModals/MoblieButtons'

type CropVideoProps = {
  video?: string
  thumbnailSrc?: string
  duration: number
  videoSize: { width: number; height: number }
  onComplete(data: CropData): void
  onClose?: () => void
}

const CropVideo: React.FC<CropVideoProps> = ({
  video: videoUrl,
  thumbnailSrc,
  duration,
  videoSize,
  onComplete: handleComplete,
  onClose
}) => {
  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const cropperRef = useRef<any>(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [opacity, setOpacity] = useState(0)
  const { startLoading, endLoading, renderLoading, isLoading } = useLoading()
  const [computed, setComputed] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  })
  const [startTime, setStartTime] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [originalDuration, setOriginalDuration] = useState(10)
  const [aspect, setAspect] = useState(1)

  useEffect(() => {
    if (!videoUrl) return
    const { width, height } = videoSize
    setAspect(width / height)
  }, [videoUrl, videoSize])

  const handleVideoTimeUpdate = useCallback(() => {
    try {
      const video = cropperRef?.current?.videoRef
      if (video) {
        if (
          video.currentTime > startTime + duration ||
          video.currentTime < startTime
        ) {
          video.currentTime = startTime
        }
        setCurrentTime(video.currentTime)
      }
    } catch (error) {
      console.log(error)
    }
  }, [startTime, duration])

  const handleCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setComputed(croppedAreaPixels)
  }, [])

  const handleTrimmerChange = useCallback((value) => {
    const video = cropperRef?.current?.videoRef

    if (!video) return

    setStartTime(value)
    setCurrentTime(value)
    video.currentTime = value
  }, [])

  useEffect(() => {
    if (!videoUrl) return
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    if (!video) return
    const handleLoadedMetaData = () => setOriginalDuration(video.duration)
    video.addEventListener('timeupdate', handleVideoTimeUpdate)
    video.addEventListener('loadedmetadata', handleLoadedMetaData)

    return () => {
      video.removeEventListener('timeupdate', handleVideoTimeUpdate)
      video.removeEventListener('loadedmetadata', handleLoadedMetaData)
    }
  }, [videoUrl, startTime, duration, handleVideoTimeUpdate])

  const firstPlay = () => {
    endLoading()
    setOpacity(1)
  }

  useEffect(() => {
    if (!videoUrl) return
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    if (!video) return
    video.addEventListener('play', firstPlay)
    return () => {
      video.removeEventListener('play', firstPlay)
    }
  }, [videoUrl])

  useEffect(() => {
    startLoading()
    const video: HTMLVideoElement = cropperRef?.current?.videoRef
    if (video) (video as any).playsInline = true
  }, [])

  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%'
        }}
      >
        <>
          <div>
            <CropInfoTypography>
              {intl.formatMessage({ id: 'VIDEO_INPUT_EDIT_INFO' })} <br />
            </CropInfoTypography>
            <CropperWrapper>
              <AspectRatioSizer aspect={16 / 9} />
              <Container>
                {isLoading && (
                  <LoadingWrapper>{renderLoading()}</LoadingWrapper>
                )}
                <Cropper
                  {...{ crop, zoom, aspect }}
                  ref={cropperRef}
                  style={{ containerStyle: { opacity } }}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  video={videoUrl}
                  onCropComplete={handleCropComplete}
                />
                <ZoomButtons
                  onClickZoomIn={() =>
                    setZoom((prev) => Math.min(prev + 0.2, 3))
                  }
                  onClickZoomOut={() =>
                    setZoom((prev) => Math.max(prev - 0.2, 1))
                  }
                />
              </Container>
            </CropperWrapper>
            <TrimmerTypography>
              {intl.formatMessage({ id: 'VIDEO_INPUT_EDIT_CLIP' })}
            </TrimmerTypography>
            <Trimmer
              duration={duration}
              onChange={handleTrimmerChange}
              currentTime={currentTime}
              originalDuration={originalDuration}
              value={startTime}
              style={{ marginBottom: 16 }}
              thumbnailSrc={thumbnailSrc}
            />
          </div>
          {!isBreakpoint('medium') && (
            <div style={{ textAlign: 'right' }}>
              <Button
                primary
                disabled={isLoading}
                onClick={() =>
                  handleComplete && handleComplete({ ...computed, startTime })
                }
              >
                {intl.formatMessage({ id: 'VIDEO_INPUT_EDIT_DONE' })}
              </Button>
            </div>
          )}
        </>
      </div>
      {isBreakpoint('medium') && (
        <MoblieButtons
          onClickOk={() => {
            startLoading()
            handleComplete && handleComplete({ ...computed, startTime })
          }}
          onClickCancel={onClose}
          okButtonDisabled={isLoading}
          cancelText='취소'
          okText='업로드'
        />
      )}
    </Wrapper>
  )
}

export default CropVideo

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const CropperWrapper = styled.div<{ aspect?: number }>`
  width: 100%;
  height: 100%;
  max-height: 40vh;
  position: relative;
  margin-bottom: 16px;
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`

const CropInfoTypography = styled(Typography).attrs({
  type: 'body2',
  block: true
})`
  font-size: 14px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.secondary};
  white-space: pre-wrap;
`

const TrimmerTypography = styled(Typography)`
  display: block;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
`
