/* eslint-disable react/no-array-index-key */
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

export interface ContentListType {
  title?: string
  content?: string[]
}
export interface ContentCardProps {
  title: string
  content?: string | null
  contentList?: ContentListType[]
  notice?: string
}
const ContentCard = ({
  title,
  content,
  notice,
  contentList,
  children
}: PropsWithChildren<ContentCardProps>) => {
  return (
    <ContentCardStyle.Wrap className='content-card'>
      {
        <ContentCardStyle.Title className='content-card-title'>
          {title}
        </ContentCardStyle.Title>
      }
      {content && (
        <ContentCardStyle.Container className='content-card-container'>
          {content}
        </ContentCardStyle.Container>
      )}
      {contentList && (
        <ContentCardStyle.Container className='content-card-container-list'>
          {contentList.map((content, idx) => (
            <ContentCardStyle.ContentListWrap key={'content-card-list-' + idx}>
              {content.title && (
                <ContentCardStyle.ContentTitle className='content-card-container-title'>
                  {`"${content.title}"`}
                </ContentCardStyle.ContentTitle>
              )}
              {content.content && (
                <ContentCardStyle.ContentList isBg={!!content.title}>
                  {content.content.map((list, idx) => (
                    <ContentCardStyle.Content
                      key={'content-card-content-' + idx}
                    >
                      {list}
                    </ContentCardStyle.Content>
                  ))}
                </ContentCardStyle.ContentList>
              )}
            </ContentCardStyle.ContentListWrap>
          ))}
        </ContentCardStyle.Container>
      )}
      {children && children}
      {notice && (
        <ContentCardStyle.Notice className='content-card-notice'>
          {notice}
        </ContentCardStyle.Notice>
      )}
    </ContentCardStyle.Wrap>
  )
}

export default ContentCard

const ContentCardStyle = {
  Wrap: styled.article`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  Title: styled.h2`
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
  `,
  Container: styled.div`
    padding: 12px;
    border-radius: 8px;
    background: #fff;

    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    color: ${({ theme }) => theme.colors.gray[800]};

    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  ContentListWrap: styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  ContentTitle: styled.p`
    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 14px;
    font-weight: 700;
    line-height: 140%;
  `,
  ContentList: styled.ol<{ isBg: boolean }>`
    padding: 14px;

    display: flex;
    flex-direction: column;
    gap: 8px;

    border-radius: 8px;
    background: ${({ isBg }) => (isBg ? '#f9f9f9' : '')};
  `,
  Content: styled.li`
    margin-left: 14px;

    color: ${({ theme }) => theme.colors.gray[800]};
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;

    list-style: inside;
    list-style-position: outside;
    list-style-type: disc;
  `,
  Notice: styled.p`
    padding: 0 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #888;
  `
}
