import styled from 'styled-components'
import theme from '../../../styles/theme'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import useFormatText from '../../../hooks/useFormatText'

interface FileInfosProps {
  selectedAssetInfo: any
}

const FileInfos = ({ selectedAssetInfo }: FileInfosProps) => {
  if (!selectedAssetInfo) return null
  const { name, createdAt, size, resolution, _id, extention } =
    selectedAssetInfo
  const locale = useIntl().locale

  const items = [
    {
      label: useFormatText('STORAGE_FILE_INFO_CREATEDAT'),
      value: dayjs(createdAt).format(
        locale === 'kr' || locale === 'ko'
          ? 'YYYY년 M월 D일 dddd A h:mm'
          : 'MMMM D, YYYY dddd h:mm A'
      )
    },
    {
      label: useFormatText('STORAGE_FILE_INFO_SIZE'),
      value: `${size}MB`
    },
    {
      label: useFormatText('STORAGE_FILE_INFO_RESOLUTION'),
      value: resolution
    }
  ]

  return (
    <Wrapper>
      <AssetName>
        <div className='name'>{name}</div>.
        <div className='extention'>{extention}</div>
      </AssetName>
      <div>
        {items.map((item, index) => {
          return (
            <AssetInfo key={`asset-${index}-${_id}`} isFirst={index === 0}>
              <label>{item.label}</label>
              <span>{item.value}</span>
            </AssetInfo>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default FileInfos

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const AssetName = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.colors.black};
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  padding: 12px 0 12px 7px;

  .name {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .extention {
    max-width: 50px;
    overflow: hidden;
  }
`

const AssetInfo = styled.div<{ isFirst: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  padding: 6px 8px;

  label {
    color: ${theme.colors.text.secondary};
  }

  ${({ isFirst }) =>
    !isFirst && `border-top: 1px solid ${theme.colors.border.secondary};`};
`
