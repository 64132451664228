/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import styled, { css } from 'styled-components'
import { IconNotePencil } from '../../assets/icons'
import { AIInfos } from '../../types/project'
import { tabletMedia } from './constants'
import AiSubtitle from './AiSubtitle'
import useToggle from '../../hooks/useToggle'
import useAutoScroll from '../../hooks/useAutoScroll'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, {
  ContentCardProps,
  ContentListType
} from '../../components/ModalContentCard'
import LottieIcon from '../../components/Lottie'
import useMarketingPlan from './hooks/useMarketingPlan'

const ProductInfo = ({
  aiInfo,
  progress,
  isMobile,
  isPendingError
}: // isReset
{
  aiInfo?: AIInfos
  progress?: number
  isPendingError: boolean
  isMobile: boolean
  // isReset: boolean
}) => {
  const { isToggled, toggle } = useToggle(true)
  const scrollRef = useAutoScroll<HTMLDivElement>()

  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const { isLoading, isStarting, currentContent, priceNotice, currencyLabel } =
    useMarketingPlan({
      priceInfo: aiInfo?.priceInfo,
      isNewScenario: aiInfo?.isNewScenario,
      progress
    })

  const isEmpty = !aiInfo?.feature?.length

  const currentFeature = currentContent(aiInfo?.feature)

  const ProductContents: ContentCardProps[] = [
    { title: 'AI_LOADING_MODAL_SUBTITLE1', content: aiInfo?.name || '' },
    {
      title: 'AI_LOADING_MODAL_SUBTITLE2',
      content: `${(aiInfo?.priceInfo?.originalPrice || '')?.toLocaleString()} ${
        currencyLabel && useFormatText(currencyLabel)
      }`,
      notice: priceNotice
    },
    {
      title: 'AI_LOADING_MODAL_SUBTITLE3',
      contentList: currentFeature
        ? currentFeature?.reduce(
            (accumulator: ContentListType[], currentValue) => {
              if (currentValue.feature)
                accumulator.push({
                  title: currentValue.feature,
                  content: [currentValue.short, currentValue?.detailed || '']
                })
              return accumulator
            },
            []
          )
        : undefined
    }
  ]
  return (
    <ProductInfoStyle.Wrapper>
      <AiSubtitle
        isToggled={isToggled}
        toggle={toggle}
        subtitle={useFormatText('AI_LOADING_MODAL_STEP1')}
        progressValue={progress || 0}
        progressText='1'
        icon={<IconNotePencil />}
        isShowArrow={isMobile}
      />
      {isToggled && (
        <ProductInfoStyle.Container
          ref={scrollRef}
          isEmpty={isEmpty}
          isKr={isKr}
        >
          {(isStarting &&
            ProductContents.map((content, idx) => (
              <ContentCard
                key={'ai-modal-product-' + idx}
                title={useFormatText(content.title)}
                content={content.content}
                contentList={content.contentList}
                notice={content.notice}
              />
            ))) || <></>}
          {isLoading && (
            <div className='empty'>
              <LottieIcon width={34} height={34} />
              {useFormatText(
                isPendingError
                  ? 'AI_LOADING_PLANNING_PENDING'
                  : 'AI_LOADING_PLANNING_PROCESSING'
              ) || <></>}
            </div>
          )}
        </ProductInfoStyle.Container>
      )}
    </ProductInfoStyle.Wrapper>
  )
}

export default ProductInfo

const ProductInfoStyle = {
  Wrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    user-select: none;

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          padding: 0;
        `
      )};
  `,
  Container: styled.div<{ isEmpty: boolean; isKr: boolean }>`
    width: 429px;
    height: calc(100% - 36px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.f5f5};
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 24px 18px;
    margin-top: 12px;

    ${({ isEmpty }) =>
      isEmpty &&
      css`
        color: ${({ theme }) => theme.colors.text['#aaa']};
        justify-content: center;
      `};

    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    ${tabletMedia(css`
      width: 100%;
    `)};

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          width: 100%;
          height: 236px;
          gap: 25px;
          padding: 20px 16px;
        `
      )};
  `
}
