import styled, { css } from 'styled-components'
import useBreakpoint from '../../hooks/useBreakpoint'

import DefaultTooltip from '../../components/DefaultTootip'

import useToggle from '../../hooks/useToggle'
import LeaveModal from './LeaveModal'
import { getTooltipStyle, tabletMedia } from './constants'
import { useIntl } from 'react-intl'
import useTablet from './hooks/useTablet'
import Button from '../../components/Button'
import { useIsHideQuery, useLeaveMutation } from './_queries'
import { useProjectContext } from '../../hooks/useProject'
import { useState } from 'react'

const LeaveButton = ({ isAutoRender }: { isAutoRender?: boolean }) => {
  const intl = useIntl()
  const { isLimit } = useProjectContext()
  const { isToggled, toggle } = useToggle()
  const { isMobile } = useBreakpoint()
  const { isTablet } = useTablet()
  const message = intl.formatMessage({
    id: 'AI_LOADING_MODAL_LEAVE_INFO'
  })
  const limitMessage = intl.formatMessage({
    id: 'AI_LOADING_MODAL_LEAVE_BUTTON_LIMIT'
  })
  const buttonText = intl.formatMessage({
    id: 'AI_LOADING_MODAL_LEAVE_BUTTON'
  })
  const { isHide, isFetching } = useIsHideQuery()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { leaveMutate } = useLeaveMutation(setIsLoading)

  const handleLeaveClick = () => {
    if (isLimit) return
    if (isHide) {
      setIsLoading(true)
      leaveMutate()
      return
    }
    toggle()
  }

  return (
    <>
      <ButtonWrapper>
        {isMobile ? (
          <Button
            primary
            onClick={handleLeaveClick}
            loading={isFetching || isLoading}
            disabled={isAutoRender || isLimit}
          >
            {buttonText}
          </Button>
        ) : (
          <DefaultTooltip
            contents={isLimit ? limitMessage : message}
            {...getTooltipStyle(isTablet, isLimit)}
          >
            <Button
              primary
              onClick={handleLeaveClick}
              loading={isFetching || isLoading}
              disabled={isAutoRender || isLimit}
            >
              {buttonText}
            </Button>
          </DefaultTooltip>
        )}
        {isToggled && <LeaveModal onClose={toggle} />}
      </ButtonWrapper>
    </>
  )
}

export default LeaveButton

const ButtonWrapper = styled.div`
  position: relative;
  width: fit-content;

  & > button {
    font-weight: 500;
  }

  ${tabletMedia(css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: ${theme.colors.white};
      padding: 10px 8px 8px;

      button {
        display: flex;
        width: 100%;
        height: 44px;
        padding: 12px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex-shrink: 0;
      }
    `)}
`
