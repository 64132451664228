import styled, { useTheme } from 'styled-components'
import CountUp from 'react-countup'
import { IconWarning } from '../../assets/icons'
import Typography from '../../components/Typography'
import { useProjectContext } from '../../hooks/useProject'
import { useIntl } from 'react-intl'

const ProgressBar = () => {
  const intl = useIntl()
  const theme = useTheme()
  const { project } = useProjectContext()
  const percent = project?.workProgress || 0
  const PROGRESS_MESSAGE =
    percent === 100 ? 'PROGRESS_DONE_MESSAGE' : 'PROGRESS_MESSAGE'

  return (
    <Wrapper>
      <div style={{ marginBottom: 8, display: 'flex', alignItems: 'center' }}>
        <FlexBetween>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconWarning />
            <Typography
              type='body2'
              style={{ color: theme.colors.text.secondary }}
            >
              &nbsp;
              {intl.formatMessage({
                id: PROGRESS_MESSAGE
              })}
            </Typography>
          </div>
        </FlexBetween>
      </div>
      <div>
        <Progress>
          <div style={{ width: `${percent}%` }} />
        </Progress>
      </div>
      <div>
        <Typography bold>
          <CountUp end={percent} duration={1} />
        </Typography>
        <Typography bold>
          &nbsp;{intl.formatMessage({ id: 'PROGRESS_UNIT' })}
        </Typography>
      </div>
    </Wrapper>
  )
}

export default ProgressBar

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 14px 14px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 16px 0 3px;
`

const Progress = styled.div`
  width: 100%;
  height: 5px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border.primary};
  border-radius: 4px;
  margin-bottom: 5px;
  & > div {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    transition: width 0.2s ease-in-out;
  }
`

const FlexBetween = styled.div`
  width: 100%;
  min-width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
