import styled from 'styled-components'
import { useAiStore } from '../../stores/ai'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, { ContentCardProps } from '../ModalContentCard'

const MarketingDataList = () => {
  const { aiInfos } = useAiStore()

  const currentCategory = aiInfos?.category
  const currentPersona = aiInfos?.persona ? aiInfos?.persona[0] : undefined
  const currentHooking = aiInfos?.hooking ? aiInfos?.hooking[0] : undefined
  const currentMoving = aiInfos?.moving ? aiInfos?.moving[0] : undefined

  const currentPromotionTitle = aiInfos?.promotionTitle
    ? aiInfos?.promotionTitle[0]
    : undefined

  const currentPromotionDetail = aiInfos?.promotionDetail
    ? aiInfos?.promotionDetail[0]
    : undefined
  const currentReviewCopy = aiInfos?.productReview
    ? aiInfos?.productReview[0]
    : undefined

  const isPersona =
    currentPersona?.lifestyle ||
    currentPersona?.interest ||
    currentPersona?.feature

  const isInformation =
    !!currentCategory &&
    (currentCategory === 102 ||
      currentCategory === 301 ||
      currentCategory === 302)
  const isPromotion = !!currentCategory && currentCategory === 101
  const isReview = !!currentCategory && currentCategory === 303

  const ContentList: ContentCardProps[] = !isReview
    ? [
        {
          title: 'SIDE_NAV_MARKETING_CONTENT1',
          contentList: currentPersona
            ? [
                {
                  title: currentPersona?.character,
                  content: isPersona
                    ? [
                        currentPersona?.lifestyle || '',
                        currentPersona?.interest || '',
                        currentPersona?.feature || ''
                      ]
                    : undefined
                }
              ]
            : undefined
        },
        ...(isInformation
          ? [
              {
                title: 'SIDE_NAV_MARKETING_CONTENT5',
                contentList: currentHooking
                  ? [
                      {
                        content: [
                          currentHooking?.short || '',
                          currentHooking?.detailed || ''
                        ]
                      }
                    ]
                  : undefined
              },
              {
                title: 'SIDE_NAV_MARKETING_CONTENT6',
                contentList: currentMoving
                  ? [
                      {
                        content: [
                          currentMoving?.short || '',
                          currentMoving?.detailed || ''
                        ]
                      }
                    ]
                  : undefined
              }
            ]
          : []),
        ...(isPromotion
          ? [
              {
                title: 'SIDE_NAV_MARKETING_CONTENT7',
                contentList: currentPromotionTitle
                  ? [
                      {
                        content: [currentPromotionTitle || '']
                      }
                    ]
                  : undefined
              },
              {
                title: 'SIDE_NAV_MARKETING_CONTENT8',
                contentList: currentPromotionDetail
                  ? [
                      {
                        content: [currentPromotionDetail || '']
                      }
                    ]
                  : undefined
              }
            ]
          : [])
      ]
    : [
        {
          title: 'text_0388',
          contentList: currentReviewCopy
            ? [
                {
                  content: Object.values(currentReviewCopy)
                }
              ]
            : undefined
        }
      ]

  return (
    <Wrapper>
      {ContentList.map((content, idx) => (
        <ContentCard
          key={'marketing-data-list-' + idx}
          title={useFormatText(content.title)}
          content={content.content}
          contentList={content.contentList}
          notice={content.notice}
        />
      ))}
    </Wrapper>
  )
}

export default MarketingDataList

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 36px;
  line-height: 1.5;
  padding-bottom: 60px;

  .content-card-title {
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
  }
  .content-card-container {
    font-size: 18px !important;
    background-color: #f9f9f9;
  }

  .content-card-container-list {
    padding: 0;
    gap: 20px;
    .content-card-container-title {
      font-size: 18px;
    }
    ol {
      background-color: #f9f9f9;
      li {
        font-size: 16px;
      }
    }
  }

  .content-card-notice {
    padding: 0;
    color: #ff4c4c;
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
  }
`
