import styled, { css } from 'styled-components'

import { Checkbox } from 'antd'
import DefaultButton from '../DefaultButton'

export const ColorPickerWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 12px 0px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 8px 0px 10px;
    `)};

  .react-colorful {
    width: 100%;
    height: 100%;

    // NOTE: 위 아래 박스 pointer 스타일링
    .react-colorful__pointer {
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    }

    .react-colorful__saturation {
      position: relative;
      flex-grow: 1;
      height: 140px;
      border-color: transparent;
      border-bottom: 12px solid ${({ theme }) => theme.colors.black};
      border-radius: 0;

      ${({ theme }) =>
        theme.breakpoints.medium(css`
          border-radius: 4px;
        `)};
    }
    .react-colorful__last-control {
      margin-top: 8px;
      border-radius: 0;
      height: 12px;

      ${({ theme }) =>
        theme.breakpoints.medium(css`
          border-radius: 8px;
        `)};
    }
  }
`

export const HexColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 38px;
  padding: 7px 8px;
  color: ${({ theme }) => theme.colors.text.link};
  border: solid 1px ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;

  input {
    width: 62px;
    height: 14px;
    color: ${({ theme }) => theme.colors.text['#222']};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    outline: none;
    border: none;
    padding: 0;
  }
`
export const AddFavoriteButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text.link};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 4px;

  svg {
    margin-right: 4px;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 6px 12px;
      font-size: 13px;
      line-height: 100%;
    `)};
`

export const EditFavoriteButton = styled(AddFavoriteButton)`
  gap: 4px;
  padding: 6px 12px;
  border-radius: 4px;

  svg {
    width: 18px;
    height: 18px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 44px);
  height: calc(100svh - 44px);
  height: 100%;
  position: relative;
  overflow: auto;

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      padding-bottom: 70px;
    `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 30px 14px 24px;
    `)};
`

export const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding-bottom: 16px;
  overflow: auto;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding-bottom: 20px;
    `)};
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px 21px 34px;
  flex-direction: row-reverse;
  border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 20px 21px 60px;
    `)};
`

export const Preview = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
  padding: 0px 45px 30px;

  & > div {
    width: auto;
    height: auto;
    position: relative;
    text-align: center;
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 0px 54px 23px;
    `)};
`

export const SecondaryTypography = styled.span`
  color: ${({ theme }) => theme.colors.text['#666']};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 12px;
      font-weight: 400;
    `)};
`

export const SecondaryEmptyTypography = styled.span`
  color: ${({ theme }) => theme.colors.text['#666']};
  font-size: 13px;
  font-weight: 700;
  line-height: 140%;
  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 12px;
      font-weight: 400;
    `)};
`

export const ColorTypography = styled.span`
  color: ${({ theme }) => theme.colors.text['#222']};
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 12px;
      font-weight: 500;
    `)};
`

export const EmyptyTypography = styled.div`
  width: 70px;
  color: ${({ theme }) => theme.colors.text['#666']};
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 12px;
      line-height: 140%;
    `)};
`

export const ChipsRow = styled(Row)`
  margin-top: 38px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 30px;
    `)};
`

export const ColorPickRow = styled(Row)`
  margin-top: 31px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-top: 24px;
    `)};
`

export const BoldTypography = styled(ColorTypography)`
  color: ${({ theme }) => theme.colors.black2};
  font-size: 14px;
  font-weight: 600;
`

export const ColorColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`

export const ChipsWrapper = styled(ColorColumn)`
  align-items: start;
  gap: 11px;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      gap: 8px;
    `)};
`

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  top: 3px;
  left: 3px;

  * {
    width: 10px;
    height: 10px;
  }

  &::after {
    height: 10px;
  }

  .ant-checkbox-inner:after {
    width: 4px;
    height: 8px;
    left: 10%;
  }
`
