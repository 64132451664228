import { useQueryUserInfo } from '../Editor/Main/_queries'

const useGetIsTrackingEventVerified = () => {
  const { userInfo } = useQueryUserInfo()
  const userEmail = userInfo?.userEmail

  const getIsUserVerified = () => {
    // TODO: 추후 IP도 추가
    return userEmail ? !userEmail.includes('vplate.io') : true
  }

  return getIsUserVerified(userEmail) && process.env.NODE_ENV === 'production'
}

export default useGetIsTrackingEventVerified
