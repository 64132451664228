import { Col, Row } from 'antd'
import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import { useState } from 'react'
import { fetchImages } from '../../hooks/useFetchPexels'
import { useQuery } from 'react-query'
import { PhotoData } from '../../utils/api/images'
import { useIntl } from 'react-intl'
import { AI_IMAGE_TAB, AiImageTab } from './constants'
import EmptyText from '../EmptyText'
import useBreakpoint from '../../hooks/useBreakpoint'
import LottieIcon from '../Lottie'
import { Scenario } from '../../types/project'

interface AIImageListProps {
  aiImages?: string[]
  gutter?: [number, number]
  colSpan?: number
  onClickImage?: (img: PhotoData | string) => void
  scenario: Scenario | null
  usedPoint?: 1 | 2 | 3
  rowStyle?: React.CSSProperties
  emptyTextStyle?: React.CSSProperties
}

const AIImageList = ({
  aiImages,
  gutter: gutterProps,
  colSpan,
  onClickImage,
  scenario,
  usedPoint,
  rowStyle,
  emptyTextStyle
}: AIImageListProps) => {
  const intl = useIntl()
  const hasAIPick = Array.isArray(aiImages) && aiImages.length > 0
  const [activedTab, setActivedTab] = useState<AiImageTab>(
    hasAIPick ? AI_IMAGE_TAB.AI : AI_IMAGE_TAB.RELATIVE
  )

  const { isBreakpoint } = useBreakpoint()
  const keyword =
    (
      scenario && scenario[`appealPoint${usedPoint || 1}` as keyof Scenario]
    )?.image.join(' ') || ''

  const gutter: [number, number] =
    gutterProps || isBreakpoint('medium') ? [11, 8] : [12, 12]

  const { isLoading, data: imageList } = useQuery(
    ['images', keyword],
    () => fetchImages(1, keyword, '', 60),
    {
      enabled: !!keyword
    }
  )

  const isAiImagesNotEmpty = Array.isArray(aiImages) && aiImages?.length > 0
  const relatedImages =
    imageList?.data.map((image: PhotoData) => {
      return `${image?.image}?w=600`
    }) || []
  const isRelatedImagesNotEmpty =
    Array.isArray(relatedImages) && relatedImages?.length > 0

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { id } = e.currentTarget
    setActivedTab(id as AiImageTab)
  }

  return (
    <>
      <Wrapper>
        <ButtonWrapper>
          <Button
            type='button'
            id={AI_IMAGE_TAB.AI}
            isActive={activedTab === AI_IMAGE_TAB.AI}
            onClick={handleClick}
            value={intl.formatMessage({
              id: 'SIDE_NAV_AI_CONTENT_TAP2_BUTTON1'
            })}
          ></Button>
          <Button
            type='button'
            id={AI_IMAGE_TAB.RELATIVE}
            isActive={activedTab === AI_IMAGE_TAB.RELATIVE}
            onClick={handleClick}
            value={intl.formatMessage({
              id: 'SIDE_NAV_AI_CONTENT_TAP2_BUTTON2'
            })}
          ></Button>
        </ButtonWrapper>
        {isLoading ? (
          <LoadingWapper>
            <LottieIcon />
          </LoadingWapper>
        ) : (
          <>
            {activedTab === AI_IMAGE_TAB.AI &&
              (isAiImagesNotEmpty ? (
                <StyledRow gutter={gutter} style={rowStyle}>
                  {(aiImages || []).map((img: any) => {
                    const imageUrl = img?.url || img
                    return (
                      <Col
                        span={colSpan || 12}
                        key={`${img}-col`}
                        style={{ position: 'relative' }}
                      >
                        <Image
                          src={imageUrl}
                          key={imageUrl}
                          clickAble={onClickImage ? true : false}
                          onClick={() => onClickImage && onClickImage(imageUrl)}
                        />
                      </Col>
                    )
                  })}
                </StyledRow>
              ) : (
                <EmptyText
                  text={intl.formatMessage({
                    id: 'SIDE_NAV_AI_CONTENT_NO_IMAGE'
                  })}
                  style={emptyTextStyle}
                />
              ))}
            {activedTab === AI_IMAGE_TAB.RELATIVE &&
              (isRelatedImagesNotEmpty ? (
                <StyledRow gutter={gutter} style={rowStyle}>
                  {relatedImages.map((img: any) => {
                    const imageUrl = img?.url || img
                    return (
                      <Col
                        span={colSpan || 12}
                        key={`${img}-col`}
                        style={{ position: 'relative' }}
                      >
                        <Image
                          src={imageUrl}
                          key={imageUrl}
                          clickAble={onClickImage ? true : false}
                          onClick={() => onClickImage && onClickImage(imageUrl)}
                        />
                      </Col>
                    )
                  })}
                </StyledRow>
              ) : (
                <EmptyText
                  text={intl.formatMessage({
                    id: 'SIDE_NAV_AI_CONTENT_NO_IMAGE'
                  })}
                  style={emptyTextStyle}
                />
              ))}
          </>
        )}
      </Wrapper>
    </>
  )
}

export default AIImageList

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
  max-height: 75vh;
  padding-bottom: 40px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Button = styled.input<{ isActive: boolean }>`
  padding: 8px 12px;
  border-radius: 24px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          color: ${theme.colors.primary};
          background: ${theme.colors.background.active};
          border: 1px solid ${theme.colors.primary};
        `
      : css`
          color: ${theme.colors.text['#888']};
          background: ${theme.colors.background.secondary};
          border: 1px solid ${theme.colors.background.secondary};
        `};
`

const StyledRow = styled(Row)`
  position: relative;
  margin-top: 12px;
  margin-left: -6px !important;
  margin-right: -6px !important;
  height: auto !important;
  padding-bottom: 40px;

  & > div {
    padding-left: 6px !important;
    padding-right: 6px !important;

    img {
      border-radius: 4px;
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      display: inline-block;
      vertical-align: bottom;
      transition: transform 0.2s;
    }
  }
`

const Image = styled.img<{ clickAble: boolean }>`
  cursor: ${({ clickAble }) => (clickAble ? 'pointer' : 'default')};
`

const LoadingWapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`
