import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps
} from 'styled-components'

export const Background = styled.div<{
  visible?: boolean
  backgroundColor?: string
  blur?: boolean
  closable?: boolean
}>`
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  background: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  ${({ visible }) =>
    visible
      ? css`
          display: block;
        `
      : css`
          display: none;
        `};
  transform: unset;

  ${({ blur }) =>
    blur &&
    css`
      background-color: ${({ theme }) => theme.colors.black}30;
      backdrop-filter: blur(5px);
    `};
`

export const Wrapper = styled.div<{
  fitContent?: boolean
  noFullHeight?: boolean
  flowHidden?: boolean
  custom?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}>`
  display: inline-block;
  width: ${({ fitContent }) => (fitContent ? 'auto' : '90%')};
  height: 100%;
  overflow-x: hidden;
  overflow-y: ${({ flowHidden }) => (flowHidden ? 'hidden' : 'auto')};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 600;

  ${({ theme, noFullHeight }) =>
    theme.breakpoints.xxxLarge(css`
      max-width: ${noFullHeight ? '620px' : '956px'};
      width: 100%;
      max-height: 80vh;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `)}

  ${({ theme }) =>
    theme.breakpoints.large(css`
      width: 100%;
      max-width: 100%;
      max-height: 100vh;
      max-height: 100svh;
      position: fixed;
      top: unset;
      bottom: 0;
      left: 0;
      transform: unset;
    `)}

  ${({ theme, noFullHeight }) =>
    !noFullHeight &&
    theme.breakpoints.small(css`
      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
      }
      position: fixed;
      left: 0;
      top: 0;
    `)}

  ${({ custom }) => custom && custom};
`

export const Content = styled.div<{
  fitContent: boolean
  custom?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
}>`
  width: 100%;
  height: calc(100% - 52px);
  min-height: ${({ fitContent }) => (fitContent ? 'fit-content' : '')};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ custom }) => custom && custom};
`
