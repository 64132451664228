import { useEffect } from 'react'
import { MUSIC_GENRES, MUSIC_MOODS } from './constants'
import {
  Filter,
  FilterCol,
  FilterTitle,
  FilterWrapper,
  RoundButton
} from './styles'
import { MusicFilterContentProps } from './types'
import { useIntl } from 'react-intl'

const MusicFilterContent = ({
  selectedGenres,
  setSelectedGenres,
  selectedMoods,
  setSelectedMoods,
  filterElement,
  onClickOutside
}: MusicFilterContentProps) => {
  const intl = useIntl()

  const handleClickButton = (
    index: number,
    setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>
  ) => {
    if (index === 0) {
      setSelectedItems([0])
      return
    }
    setSelectedItems((current) => {
      let items = current
      // 전체 선택이 되어 있고 다른 버튼을 눌렀을 때
      if (current.length >= 1 && current.includes(0)) {
        items = current.filter((value) => value !== 0)
      }
      // 이미 선택된 버튼을 눌렀을 때 선택 해제 => 길이가 0이면 전체 선택
      if (items.includes(index)) {
        const result = items.filter((value) => value !== index)
        return result.length === 0 ? [0] : result
      }
      return [...items, index]
    })
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        onClickOutside &&
        filterElement &&
        !filterElement.contains(event.target as Node)
      ) {
        onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [filterElement])

  return (
    <FilterWrapper>
      <FilterCol>
        <FilterTitle>
          {intl.formatMessage({ id: 'BGM_FILTER_MOOD' })}
        </FilterTitle>
        <Filter>
          {MUSIC_MOODS.map((item, index) => (
            <RoundButton
              key={item}
              primary={selectedMoods.includes(index)}
              onClick={() => handleClickButton(index, setSelectedMoods)}
            >
              {intl.formatMessage({ id: item })}
            </RoundButton>
          ))}
        </Filter>
      </FilterCol>
      <FilterCol>
        <FilterTitle>
          {intl.formatMessage({ id: 'BGM_FILTER_GENRE' })}
        </FilterTitle>
        <Filter>
          {MUSIC_GENRES.map((item, index) => (
            <RoundButton
              key={item}
              primary={selectedGenres.includes(index)}
              onClick={() => handleClickButton(index, setSelectedGenres)}
            >
              {intl.formatMessage({ id: item })}
            </RoundButton>
          ))}
        </Filter>
      </FilterCol>
    </FilterWrapper>
  )
}

export default MusicFilterContent
