import theme from '../../styles/theme'

export const MUSIC_MOODS = [
  'BGM_FILTER_TOTAL',
  'SIDE_NAV_BGM_CONTENT_MOOD_BRIGHT',
  'SIDE_NAV_BGM_CONTENT_MOOD_ANGRY',
  'SIDE_NAV_BGM_CONTENT_MOOD_SERIOUS',
  'SIDE_NAV_BGM_CONTENT_MOOD_DARK',
  'SIDE_NAV_BGM_CONTENT_MOOD_HAPPY',
  'SIDE_NAV_BGM_CONTENT_MOOD_FUNKY',
  'SIDE_NAV_BGM_CONTENT_MOOD_COMFORTABLE',
  'SIDE_NAV_BGM_CONTENT_MOOD_STRANGE',
  'SIDE_NAV_BGM_CONTENT_MOOD_ROMANTIC',
  'SIDE_NAV_BGM_CONTENT_MOOD_DREAMY'
]

export const MUSIC_GENRES = [
  'BGM_FILTER_TOTAL',
  'SIDE_NAV_BGM_CONTENT_GENRE_POP',
  'SIDE_NAV_BGM_CONTENT_GENRE_HIPHOP',
  'SIDE_NAV_BGM_CONTENT_GENRE_JAZZ_BLUES',
  'SIDE_NAV_BGM_CONTENT_GENRE_RNB',
  'SIDE_NAV_BGM_CONTENT_GENRE_CLASSIC',
  'SIDE_NAV_BGM_CONTENT_GENRE_DANCE_ELECTRONIC',
  'SIDE_NAV_BGM_CONTENT_GENRE_COUNTRY_FOLK',
  'SIDE_NAV_BGM_CONTENT_GENRE_CHILDREN',
  'SIDE_NAV_BGM_CONTENT_GENRE_PUNK',
  'SIDE_NAV_BGM_CONTENT_GENRE_CALM'
]

export const waveformSettings = {
  barWidth: 1,
  barGap: 0,
  waveformColor: theme.colors.text.primary,
  barAlign: 'center',
  waveformHeight: 48,
  waveformWidth: 1920,
  drawMode: 'png'
}
