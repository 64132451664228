import { Context, createContext, useContext, useEffect, useMemo } from 'react'
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query'
import { Project } from '../types/project'
import { Source2 } from '../types/template'
import { service } from '../utils/api'
import parseProjectData from '../utils/libs/parseProjectData'
import { message } from 'antd'
import { useIntl } from 'react-intl'
import useFormatText from './useFormatText'

export type SetProject = UseMutateAsyncFunction<
  Project | undefined,
  unknown,
  Project | undefined,
  unknown
>

interface ProjectContextType {
  project?: Project
  setProject?: SetProject
  isMutateLoading?: boolean
  sceneList?: (Source2 & { isCompleted?: boolean })[]
  projectId?: string
  isLimit?: boolean
  refetchProject?: VoidFunction
}

export const ProjectContext: Context<ProjectContextType> = createContext({})

const fetchProject = async (
  projectId: string
): Promise<Project | undefined> => {
  try {
    if (!projectId) return
    const res = await service().projects.findOne(projectId)
    return res && parseProjectData(res)
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message)
    }
  }
  return
}

const patchProject = async (
  projectId: string,
  errMsg: string,
  project?: Project
) => {
  if (!projectId) return
  if (project) {
    const res = await service().projects.patch(projectId, {
      userSource: project?.sources,
      bgmUrl: project?.bgmUrl,
      bgm: project?.bgm?._id,
      changedColor: project?.changedColor,
      lastSceneType: project?.lastSceneType,
      contactNumber: project?.contactNumber,
      title: project?.title || '',
      userId: project?.userId || ''
    })
    if (!res && project?.title) {
      throw new Error(errMsg)
    }
    return res
  }
  return
}

export const useProject = (projectId: string) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const UPDATE_PROJECT_ERROR = useFormatText('UPDATE_PROJECT_ERROR')
  const formatText = (text: string) => {
    return intl.formatMessage({ id: text })
  }

  const { data: project, refetch } = useQuery(
    ['project', projectId],
    () => fetchProject(projectId),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
      onError: (error) => {
        if (
          error instanceof Error &&
          error?.message &&
          typeof error?.message === 'string'
        ) {
          message.error(formatText(error.message))
        }
      }
    }
  )

  const { mutateAsync: setProject } = useMutation(
    (_project?: Project) => {
      return patchProject(projectId, UPDATE_PROJECT_ERROR, _project)
    },
    {
      onSuccess: (updatedProject, _project) => {
        updatedProject?._id &&
          _project &&
          queryClient.setQueryData(['project', projectId], {
            ...updatedProject,
            bgm: {
              ...updatedProject?.bgm,
              musicTitle: _project?.bgm?.musicTitle || null,
              _id: _project?.bgm?._id || null
            }
          })
      }
    }
  )

  const sceneList = useMemo(() => {
    const { design, sources } = project ?? {}
    return (design?.sources || []).map((scene, index) => ({
      ...scene,
      isCompleted: (sources?.[index]?.filter((item) => !item).length || 0) <= 0
    }))
  }, [project])

  useEffect(() => {
    queryClient.invalidateQueries(['project', projectId])
  }, [projectId])

  return {
    project,
    setProject,
    sceneList,
    refetchProject: refetch
  }
}

export const useProjectContext = () => useContext(ProjectContext)
