import { create } from 'zustand'
import { AIInfos } from '../types/project'

type AiInfosStore = {
  aiInfos: AIInfos
  setAiInfos: (aiInfos: AIInfos) => void
}

export const useAiStore = create<AiInfosStore>((set) => ({
  aiInfos: {
    adPlanning: [],
    images: [],
    videos: [],
    usedPoint: 1,
    category: null,
    scenario: null,
    status: null,
    _id: '',
    requestType: null,
    name: '',
    detail: '',
    priceInfo: {
      discountPrice: null,
      originalPrice: null,
      currency: null
    },
    feature: [],
    persona: undefined,
    hooking: undefined,
    moving: undefined,
    promotionTitle: undefined,
    promotionDetail: undefined,
    videoInsertType: undefined
  },
  setAiInfos: (aiInfos: AIInfos) => set({ aiInfos })
}))
