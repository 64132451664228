import styled, { css, useTheme } from 'styled-components'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import Modal, { ModalProps } from '../../components/Modal'
import Typography from '../../components/Typography'

import Video from '../../components/Video'
import { iconVideoModalPreviewDefault } from '../../assets/icons'
import CropVideo from '../CropFile/CropVideo'
import useBreakpoint from '../../hooks/useBreakpoint'
import FileLoading from '../CropFile/FileLoading'
import LoadingIndicator from '../../components/LoadingIndicator'

import { PreviewPanel, PreviewTitle, Wrapper } from './_stylesModal'
import { message } from 'antd'
import { convertURLtoFile } from './constants'
import { useIntl } from 'react-intl'
import FileInfos from './Storage/FileInfos'
import VideoModalTabs from './VideoModalTabs'
import FilePreview from './FilePreview'
import useLoading from '../../hooks/useLoading'
import MediaModalButtons from './MediaModalButtons'

// TYPE //////////////////////////////////////////////////////////////

export type CropData = {
  x: number
  y: number
  width: number
  height: number
  startTime: number
}

type PhotoModalProps = ModalProps & {
  value?: string
  onComplete?(file: File | undefined, cropData: CropData): void
  isError?: boolean
  duration?: number
  minDuration?: number
  videoSize: VideoSizeType
}

interface VideoSizeType {
  width: number
  height: number
}

// TYPE FIN///////////////////////////////////////////////

const VideoModal = (props: PhotoModalProps) => {
  const {
    minDuration,
    onClose,
    visible,
    onComplete,
    duration = 30,
    isError = false,
    videoSize
  } = props
  const intl = useIntl()
  const theme = useTheme()

  const [currentStep, setCurrentStep] = useState(0)

  const [selectedFile, setSelectedFile] = useState<File>()

  const [selectedImageUrl, setSelectedImageUrl] = useState('')
  const [isConversion, setIsConversion] = useState(false)
  const [isPreviewError, setIsPreviewError] = useState(false)
  const [cropData, setCropData] = useState<CropData | null>(null)
  const [selectedAssetInfo, setSelectedAssetInfo] = useState<any>(null)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const { sizeName, isBreakpoint } = useBreakpoint()

  const {
    isLoading: previewLoading,
    renderLoading: previewLoad,
    startLoading: previewLoadStart,
    endLoading: previewLoadEnd
  } = useLoading()

  useEffect(() => {
    let timerId: NodeJS.Timeout
    if (!visible) {
      timerId = setTimeout(() => {
        setCurrentStep(0)
        setSelectedImageUrl('')
        setSelectedFile(undefined)
        setCropData(null)
      }, 500)
    }
    return () => {
      if (timerId) {
        clearInterval(timerId)
      }
    }
  }, [visible])

  const handleLoadedData = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      const videoElement = event.target as HTMLVideoElement
      previewLoadEnd()
      setIsConversion(false)

      if (!minDuration) return
      checkFileDuration(videoElement.duration)
    },
    [minDuration]
  )

  const handlePreviewError = useCallback(() => {
    setIsPreviewError(true)
    previewLoadEnd()
    setIsConversion(false)
  }, [])

  const onClickItem = async (url: string) => {
    if (!url || url === selectedImageUrl) return
    setIsPreviewError(false)
    previewLoadStart()
    setSelectedImageUrl(url)
    setSelectedFile(undefined)

    const file = await convertURLtoFile({
      url,
      type: 'video'
    })

    setSelectedFile(file)
  }

  // 유저가 입력한 소스의 길이가 해당 씬에서 요구하는 길이보다 짧은지 체크하기 위함
  const checkFileDuration = async (fileDuration: number) => {
    if (fileDuration && minDuration && fileDuration <= minDuration) {
      setSelectedImageUrl('')
      setSelectedFile(undefined)
      message.error(
        `${intl.formatMessage({
          id: 'VIDEO_FILE_DURATION_ERROR1'
        })} ${minDuration}${intl.formatMessage({
          id: 'VIDEO_FILE_DURATION_ERROR2'
        })}`
      )
    }
    previewLoadEnd()
  }

  const handleCropComplete = () => {
    if (!cropData || !selectedFile) return
    setCurrentStep(2)
    onComplete && onComplete(selectedFile, cropData)
  }

  const modalTitle = (() => {
    if (currentStep === 0) {
      return 'VIDEO_INPUT_TITLE'
    }
    if (currentStep === 1) {
      return 'VIDEO_INPUT_EDIT'
    }
    return 'VIDEO_INPUT_ENCODING_TITLE'
  })()

  useEffect(() => {
    handleCropComplete()
  }, [selectedFile, cropData])

  return (
    <Modal
      sizeName={sizeName}
      title={intl.formatMessage({ id: modalTitle })}
      visible={visible}
      closable={!(!window.Worker && isConversion)}
      backgroundColor={currentStep === 2 ? 'rgba(0, 0, 0, 0.3)' : 'transparent'}
      onClose={() => {
        onClose && onClose()
      }}
      closeBtnType='round'
      style={css`
        padding: 24px;
      `}
    >
      {currentStep === 0 && !isBreakpoint('medium') && (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}
        >
          <Wrapper>
            <PreviewPanel>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%'
                }}
              >
                <PreviewTitle>
                  <Typography bold block center type='body2'>
                    {intl.formatMessage({ id: 'IMAGE_INPUT_PREVIEW' })}
                  </Typography>
                </PreviewTitle>
                <ImageWrapper>
                  {!selectedImageUrl && !previewLoading && (
                    <EmptyPreview>
                      <img
                        src={iconVideoModalPreviewDefault}
                        alt='preview'
                        style={{ width: 56, marginBottom: 16 }}
                      />
                      <Typography
                        block
                        center
                        type='body2'
                        style={{
                          color: theme.colors.text.secondary,
                          whiteSpace: 'pre-wrap'
                        }}
                      >
                        {intl.formatMessage({
                          id: 'VIDEO_INPUT_PREVIEW_DEFAULT'
                        })}
                      </Typography>
                    </EmptyPreview>
                  )}
                  {previewLoading && selectedImageUrl && (
                    <LoadingContainer>
                      <LoadingIndicator />
                      {isConversion && (
                        <div>
                          <div>
                            {intl.formatMessage({
                              id: 'VIDEO_INPUT_ENCODING_DESCRIPTION1'
                            })}
                          </div>
                          <div style={{ marginTop: 4 }}>
                            {intl.formatMessage({ id: 'PLEASE_WAIT' })}
                          </div>
                        </div>
                      )}
                    </LoadingContainer>
                  )}
                  {selectedImageUrl && (
                    <Video
                      videoClassName='selected-image'
                      src={selectedImageUrl}
                      isPreviewError={isPreviewError}
                      handleLoadedData={handleLoadedData}
                      handlePreviewError={handlePreviewError}
                      muted
                      autoPlay
                      playsInline
                    />
                  )}
                  {!previewLoading &&
                    selectedImageUrl &&
                    selectedAssetInfo &&
                    !isPreviewError && (
                      <FileInfos selectedAssetInfo={selectedAssetInfo} />
                    )}
                </ImageWrapper>
              </div>

              <MediaModalButtons
                okButtonDisabled={isPreviewError || !selectedImageUrl}
                cancelButtonDisabled={!selectedImageUrl}
                onClickLoad={() => setCurrentStep(1)}
                onClickCancel={() => setSelectedImageUrl('')}
                loading={previewLoading}
                buttonStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  minWidth: 84
                }}
              />
            </PreviewPanel>

            <div
              style={{
                width: 1,
                height: 'calc(100% - 64px)',
                background: '#E1E1E1',
                alignSelf: 'flex-end',
                margin: '0 16px'
              }}
            />

            <VideoModalTabs
              minDuration={minDuration}
              visible={visible}
              fileLoading={previewLoading}
              onClickItem={onClickItem}
              selectedImageUrl={selectedImageUrl}
              setSelectedAssetInfo={setSelectedAssetInfo}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          </Wrapper>
        </div>
      )}
      {currentStep === 0 && isBreakpoint('medium') && (
        <>
          {!selectedImageUrl && (
            <VideoModalTabs
              minDuration={minDuration}
              visible={visible}
              fileLoading={previewLoading}
              onClickItem={onClickItem}
              selectedImageUrl={selectedImageUrl}
              setSelectedAssetInfo={setSelectedAssetInfo}
              selectedTabIndex={selectedTabIndex}
              setSelectedTabIndex={setSelectedTabIndex}
            />
          )}
          {selectedImageUrl && (
            <FilePreview
              type='video'
              selectedImageUrl={selectedImageUrl}
              setSelectedImageUrl={setSelectedImageUrl}
              selectedAssetInfo={selectedAssetInfo}
              previewLoad={previewLoad}
              previewLoadEnd={previewLoadEnd}
              handleLoadClick={() => setCurrentStep(1)}
            />
          )}
        </>
      )}
      {currentStep === 1 && (
        <CropVideo
          video={selectedFile ? URL.createObjectURL(selectedFile) : ''}
          videoSize={videoSize}
          duration={duration}
          onComplete={(data) => setCropData(data)}
          onClose={onClose}
        />
      )}
      {currentStep === 2 && <FileLoading isError={isError} />}
    </Modal>
  )
}

export default VideoModal

//STYLE //////////////////////////////////////////////////////////////////////

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 51px);
  padding: 0 1.6rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .selected-image {
    width: 100%;
    height: 30vh;
    object-fit: contain;
    object-position: center center;
  }
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${({ theme }) =>
    theme.breakpoints.small(`
      height: 100%;
  `)};
`

const EmptyPreview = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
