import { memo, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import LottieIcon from '../../../components/Lottie'
import theme from '../../../styles/theme'
import DurationChip from '../../../components/DurationChip'
import useLoadThumbnail from './hooks/useLoadThumbnail'

interface MediaProps {
  fileUrl: string
  type: string
  handleOnError: () => void
  uploadProgress: number
  duration: string
}

const Media = ({
  fileUrl,
  type,
  handleOnError,
  uploadProgress,
  duration
}: MediaProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const {
    loadVideoThumbnail,
    isRetryOver,
    getResizedThumbnailUrl,
    getVideoThumbnailUrl
  } = useLoadThumbnail({
    fileUrl,
    callback: () => setIsLoaded(true)
  })
  const imageRef = useRef<HTMLImageElement | null>(null)

  const imageUrl = (() => {
    if (type.includes('image') && fileUrl.includes('png')) {
      return getResizedThumbnailUrl(fileUrl)
    }
    if (type.includes('image')) {
      return fileUrl
    }
    return getVideoThumbnailUrl()
  })()

  const onError = () => {
    if (
      type.includes('image') &&
      fileUrl.includes('png') &&
      imageUrl !== fileUrl &&
      imageRef.current
    ) {
      imageRef.current.src = fileUrl
      return
    }

    if (
      (handleOnError && type.includes('image')) ||
      (type.includes('video') && isRetryOver)
    ) {
      handleOnError()
    }
  }

  useEffect(() => {
    if (type.includes('video') && !isLoaded) {
      loadVideoThumbnail()
    }
  }, [type, isLoaded])

  return (
    <>
      {imageUrl && (
        <>
          <img
            src={imageUrl}
            onLoad={() => setIsLoaded(true)}
            onError={onError}
            ref={imageRef}
          />
          {type && type.includes('video') && duration && (
            <DurationChip duration={duration} />
          )}
        </>
      )}
      {uploadProgress && <Progress progress={uploadProgress} />}
      {!isLoaded && (
        <LotteWrapper>
          <LottieIcon width={60} height={60} />
        </LotteWrapper>
      )}
    </>
  )
}

export default memo(Media)

const Progress = styled.div<{ progress: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ progress }) => `${progress}%`};
  height: 5px;
  background-color: ${theme.colors.primary};
  transition: width 0.3s ease-in-out;
`

const LotteWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
`
