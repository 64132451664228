import styled, { css } from 'styled-components'
import theme from '../../styles/theme'

interface LoaderProps {
  size?: number
  left?: number
  top?: number
  right?: number
  bottom?: number
  center?: boolean
}

const Loader = ({ size = 56, ...props }: LoaderProps) => {
  return (
    <LoaderContainer {...props}>
      <svg
        version='1.1'
        id='L9'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        viewBox='0 0 100 100'
        x={size / 2}
        y={size / 2}
        width={size}
        height={size}
      >
        <path
          fill={theme.colors.white}
          d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50'
        >
          <animateTransform
            attributeName='transform'
            attributeType='XML'
            type='rotate'
            dur='1s'
            from='0 50 50'
            to='360 50 50'
            repeatCount='indefinite'
          />
        </path>
      </svg>
    </LoaderContainer>
  )
}

export default Loader

const getOffset = (offset?: number) => (offset ? `${offset}px` : 'inherit')

const LoaderContainer = styled.div<LoaderProps>`
  position: absolute;
  left: ${(props) => getOffset(props.left)};
  top: ${(props) => getOffset(props.top)};
  right: ${(props) => getOffset(props.right)};
  bottom: ${(props) => getOffset(props.bottom)};

  ${(props) =>
    props.center &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
`
