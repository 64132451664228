import styled from 'styled-components'
import theme from '../../../styles/theme'
import PlanTitleBadge from '../../../components/PlanTitleBadge'
import useFormatText from '../../../hooks/useFormatText'
import { useProjectContext } from '../../../hooks/useProject'
import { useStorageInfoQuery } from './_queries'

const StorageSize = () => {
  const { project } = useProjectContext()
  const { storageData: storageInfo } = useStorageInfoQuery()
  const { usedSize, limitSize } = storageInfo || {}

  return (
    <Wrapper className='storage-size'>
      <div className='storage-info'>
        <span className='title'>
          {useFormatText('SIDE_NAV_STORAGE_MAX_SIZE')}
        </span>
        <PlanTitleBadge
          planType={project?.planType}
          style={{ marginBottom: 0 }}
        />
      </div>
      <div className='size-info'>
        <span className='current'>{usedSize}GB</span>
        <span className='total'>/ {limitSize}GB</span>
      </div>
    </Wrapper>
  )
}

export default StorageSize

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  padding: 14px 0;
  border-top: 1px solid #e1e1e1;
  z-index: 99999;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  .title {
    color: ${theme.colors.black2};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .storage-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .size-info {
    display: flex;
    align-items: center;
    gap: 2px;
    color: ${theme.colors.black};
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;

    .current {
      color: ${theme.colors.primary};
      margin-right: 4px;
    }
  }
`
