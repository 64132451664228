/* eslint-disable react-hooks/rules-of-hooks */
import { PriceInfo } from '../../../types/project'
import { currencyItems } from '../../../utils/currency'
import { calculateDiscountRate } from '../../../utils/price'
import useFormatText from '../../../hooks/useFormatText'
import { useAiStore } from '../../../stores/ai'

interface useMarketingPlanProps {
  priceInfo?: PriceInfo
  isNewScenario?: boolean
  progress?: number
}

const useMarketingPlan = ({
  priceInfo,
  isNewScenario,
  progress
}: useMarketingPlanProps) => {
  const { aiInfos } = useAiStore()

  const isLoading = !progress || progress === 0
  const isStarting = progress && progress > 0

  const currentContent = function <T = unknown>(data?: T[]) {
    if (isNewScenario === true && aiInfos?.persona?.length === data?.length) {
      return undefined
    }
    return data && (isNewScenario === true ? data[data?.length - 1] : data[0])
  }

  const currencyLabel =
    priceInfo?.currency && currencyItems[priceInfo?.currency]

  const priceNotice =
    priceInfo?.discountPrice || priceInfo?.discountPrice === 0
      ? `${useFormatText(
          'SIDE_NAV_MARKETING_CONTENT4_INFO1'
        )} ${priceInfo?.discountPrice?.toLocaleString()} ${
          currencyLabel && useFormatText(currencyLabel)
        } / ${useFormatText(
          'SIDE_NAV_MARKETING_CONTENT4_INFO2'
        )} ${calculateDiscountRate(
          priceInfo?.originalPrice || 0,
          priceInfo?.discountPrice || 0
        )}%`
      : undefined

  return {
    isLoading,
    isStarting,
    currentContent,
    priceNotice,
    currencyLabel
  }
}

export default useMarketingPlan
