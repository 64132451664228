import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { DRAWER_CLASSNAMES, DRAWER_POSITIONS } from './constants'

export type DrawerProps = {
  style?: React.CSSProperties
  closable?: boolean
  position?: 'right' | 'left' | 'top' | 'bottom'
  visible?: boolean
  isChildDrawerOpen?: boolean
  children?: React.ReactNode
}

const Drawer: React.FC<DrawerProps> = ({
  style,
  children,
  position = DRAWER_POSITIONS.LEFT,
  visible,
  isChildDrawerOpen
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    window.document
      .querySelectorAll(`.${DRAWER_CLASSNAMES.VISIBLE}`)
      .forEach((el) => {
        if (el) {
          if (el.querySelectorAll(`.${DRAWER_CLASSNAMES.VISIBLE}`).length > 0) {
            el.classList.add(DRAWER_CLASSNAMES.HAS_CHILD)
            return
          }
          el.classList.remove(DRAWER_CLASSNAMES.HAS_CHILD)
        }
      })
  }, [visible])

  return (
    <Wrapper
      ref={ref}
      style={style}
      className={`${
        visible ? DRAWER_CLASSNAMES.VISIBLE : DRAWER_CLASSNAMES.INVISIBLE
      }`}
      position={position}
      visible={visible}
      isChildDrawerOpen={isChildDrawerOpen}
    >
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default Drawer

const Wrapper = styled.div<DrawerProps>`
  width: 100%;
  height: 100%;
  position: absolute;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: 100%;
      @supports (-webkit-touch-callout: none) {
        overflow: scroll !important;
      }
    `)}

  ${({ position }) => {
    switch (position) {
      case DRAWER_POSITIONS.TOP:
        return css`
          left: 0;
          top: 0;
          max-height: 364px;
        `
      case DRAWER_POSITIONS.RIGHT:
        return css`
          right: 0;
          top: 0;
          max-width: 420px;
        `
      case DRAWER_POSITIONS.BOTTOM:
        return css`
          left: 0;
          bottom: 0;
          max-height: 364px;
        `
      case DRAWER_POSITIONS.LEFT:
      default:
        return css`
          left: 0;
          top: 0;
          max-width: 420px;
        `
    }
  }}
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;

  /* overflow-y: auto;
  overflow-x: hidden; */

  ${({ isChildDrawerOpen }) =>
    isChildDrawerOpen &&
    css`
      overflow: hidden;
      overflow-x: hidden;
      overflow-y: hidden;
    `}

  &.drawer--has-child {
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      max-height: unset;
      min-height: unset;
    `)}
`

const Content = styled.div`
  width: 420px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  /* flex: 1; */
  /* overflow: hidden; */

  ${({ theme }) =>
    theme.breakpoints.xLarge(css`
      width: 100%;
      max-width: 500px;
    `)}

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      max-width: inherit;
    `)}
`
