import { useState } from 'react'

const useToggle = (
  initialValue = false
): {
  isToggled: boolean
  toggle: () => void
  setIsToggled: React.Dispatch<React.SetStateAction<boolean>>
} => {
  const [isToggled, setIsToggled] = useState<boolean>(initialValue)

  const toggle = () => {
    setIsToggled((prevIsToggled) => !prevIsToggled)
  }

  return { isToggled, toggle, setIsToggled }
}

export default useToggle
