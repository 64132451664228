import { useEffect, useState } from 'react'
import { sizes } from '../../../styles/theme/breakpoints'
import { TABLET_SIZE } from '../constants'

const useTablet = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(
        window.innerWidth > sizes.medium && window.innerWidth <= TABLET_SIZE
      )
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    isTablet
  }
}

export default useTablet
