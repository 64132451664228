import { useEffect, useRef, useState } from 'react'

const useLoadThumbnail = ({
  fileUrl,
  callback
}: {
  fileUrl: string
  callback?: () => void
}) => {
  const [thumbnailSrc, setThumbnailSrc] = useState<string | null>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const retryCount = useRef(0)
  const MAX_RETRY_COUNT = 5
  const isRetryOver = retryCount.current > MAX_RETRY_COUNT
  const s3Url = 'amazonaws.com/'
  const lambdaUrl = process.env.REACT_APP_LAMBDA_URL

  const getResizedThumbnailUrl = (url: string) => {
    return lambdaUrl + url.split(s3Url)[1]
  }

  const getVideoThumbnailUrl = () => {
    return thumbnailSrc
  }

  const loadVideoThumbnail = () => {
    if (retryCount.current > MAX_RETRY_COUNT && timerRef.current) {
      callback && callback()
      clearInterval(timerRef.current)
      return
    }
    const thumbnailUrl = fileUrl.replace('mp4', 'png')
    const img = new Image()

    img.onload = () => {
      callback && callback()
      setThumbnailSrc(thumbnailUrl)
      timerRef.current && clearInterval(timerRef.current)
    }

    img.onerror = () => {
      retryCount.current += 1
    }

    img.src = thumbnailUrl

    timerRef.current = setTimeout(() => {
      loadVideoThumbnail()
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (!timerRef.current) return
      clearInterval(timerRef.current)
    }
  }, [])

  return {
    loadVideoThumbnail,
    isRetryOver,
    getResizedThumbnailUrl,
    getVideoThumbnailUrl
  }
}

export default useLoadThumbnail
