import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import 'antd/dist/antd.min.css'
import ColorChip from './ColorChip'
import ColorChips from './ColorChips'
import { basicColors } from './constants'
import {
  AddFavoriteButton,
  BoldTypography,
  ChipsRow,
  ChipsWrapper,
  ColorColumn,
  ColorPickRow,
  ColorPickerWrapper,
  ColorTypography,
  Container,
  EditFavoriteButton,
  EmyptyTypography,
  Footer,
  HexColorInputWrapper,
  Preview,
  Row,
  SecondaryTypography,
  Wrapper
} from './styles'
import LottieIcon from '../Lottie'
import { useIntl } from 'react-intl'
import {
  IconArrowRight,
  IconHeart,
  IconPenCil,
  IconTrashcan
} from '../../assets/icons'
import PanelTitle from '../PanelTitle'
import EmptyText from '../EmptyText'
import DefaultButton from '../DefaultButton'
import useBreakpoint from '../../hooks/useBreakpoint'
import useFormatText from '../../hooks/useFormatText'
import { useLocalStorage } from 'usehooks-ts'

type ColorPanelProps = {
  selectedDrawerIndex: number
  title: string
  isMutateLoading?: boolean
  isReset: boolean
  setIsReset: Dispatch<SetStateAction<boolean>>
  defaultColor?: string
  currentColor?: string
  onColorChange?(newColor?: string, prevColor?: string): void
  handleDrawerClose: () => void
}

const ColorPanel = (props: ColorPanelProps) => {
  const {
    selectedDrawerIndex,
    title,
    isMutateLoading,
    isReset,
    setIsReset,
    defaultColor: initialDefaultColor,
    currentColor: initialCurrentColor,
    onColorChange = () => {},
    handleDrawerClose
  } = props
  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const theme = useTheme()
  const defaultColor = `#${initialDefaultColor}` || theme.colors.white
  const currentColor =
    initialDefaultColor !== initialCurrentColor && initialCurrentColor
      ? `#${initialCurrentColor}`
      : ''

  const [changedColor, setChangedColor] = useState(currentColor)

  const [favColor, setFavColor] = useLocalStorage<string[]>('favColor', [])
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [checkList, setCheckList] = useState<string[]>([])
  const formatEmptyText = useFormatText('SIDE_NAV_COLOR_CONTENT_AFTER_NONE')

  //NOTE: 색깔 즐겨찾기 로직
  const handleAddColor = () => {
    if (favColor) {
      //NOTE: 색깔 중복 체크
      if (favColor.includes(changedColor)) {
        return
      }
      //NOTE: 35개 넘어가면 앞에꺼 삭제
      if (favColor.length > 34) {
        favColor.pop()
      }

      favColor.unshift(changedColor)
      setFavColor(favColor)
    } else {
      setFavColor([changedColor])
    }
  }

  const handleResetColor = () => {
    if (!changedColor) return
    initialDefaultColor && setChangedColor('#' + initialDefaultColor)
    setIsReset(false)
  }

  const handleCheckList = (color: string) => {
    if (checkList.includes(color)) {
      setCheckList((prev) => prev.filter((item) => item !== color))
    } else {
      setCheckList((prev) => [...prev, color])
    }
  }

  const handleClickEditFavorite = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isEditMode && checkList.length > 0) {
      const newFavColor = favColor.filter(
        (prevColor) => !checkList.includes(prevColor)
      )
      setFavColor(newFavColor)
      setCheckList([])
    }
    setIsEditMode((prev) => !prev)
    setCheckList([])
  }

  useEffect(() => {
    setChangedColor(currentColor)
  }, [currentColor])

  useEffect(() => {
    if (isReset) {
      handleResetColor()
    }
  }, [isReset])

  useEffect(() => {
    if (selectedDrawerIndex === -1) {
      setIsEditMode(false)
    }
  }, [selectedDrawerIndex])

  return (
    <>
      {initialDefaultColor && (
        <>
          <PanelTitle
            title={title}
            handleReset={handleResetColor}
            handleDrawerClose={handleDrawerClose}
          >
            <Wrapper>
              {isMutateLoading ? (
                <LottieIcon />
              ) : (
                initialDefaultColor && (
                  <Container>
                    <div>
                      <Preview>
                        <ColorColumn>
                          <ColorTypography>
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_DAFAULT'
                            })}
                          </ColorTypography>
                          <ColorChip color={defaultColor} />
                          <SecondaryTypography>
                            {defaultColor.toUpperCase()}
                          </SecondaryTypography>
                        </ColorColumn>
                        <div style={{ width: 16, height: 27 }}>
                          <IconArrowRight />
                        </div>
                        <ColorColumn style={{ width: 70 }}>
                          {changedColor ? (
                            <>
                              <ColorTypography>
                                {intl.formatMessage({
                                  id: 'SIDE_NAV_COLOR_CONTENT_AFTER'
                                })}
                              </ColorTypography>
                              <ColorChip
                                color={changedColor}
                                setColor={setChangedColor}
                              />
                              <SecondaryTypography>
                                {changedColor.toUpperCase()}
                              </SecondaryTypography>
                            </>
                          ) : (
                            <EmyptyTypography>
                              {formatEmptyText}
                            </EmyptyTypography>
                          )}
                        </ColorColumn>
                      </Preview>
                      <ColorPickRow>
                        <BoldTypography>
                          {intl.formatMessage({
                            id: 'SIDE_NAV_COLOR_CONTENT_PICK'
                          })}
                        </BoldTypography>
                        <div>
                          <AddFavoriteButton
                            category='compactMonochrome'
                            onClick={handleAddColor}
                          >
                            <IconHeart width={18} height={18} />
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_ADD_FAVORITE'
                            })}
                          </AddFavoriteButton>
                        </div>
                      </ColorPickRow>
                      <Row
                        style={{
                          flexDirection: 'column',
                          paddingTop: 0,
                          paddingBottom: 0
                        }}
                      >
                        <ColorPickerWrapper>
                          <HexColorPicker
                            color={changedColor}
                            onChange={setChangedColor}
                          />
                        </ColorPickerWrapper>
                      </Row>
                      <Row>
                        <HexColorInputWrapper>
                          <ColorChip
                            color={changedColor}
                            chipStyle={{ width: 24, height: 24 }}
                          />
                          <HexColorInput
                            prefixed
                            color={changedColor.toUpperCase()}
                            onChange={setChangedColor}
                            size={7}
                          />
                        </HexColorInputWrapper>
                      </Row>
                      <ChipsWrapper>
                        <ChipsRow>
                          <BoldTypography>
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_BASIC'
                            })}
                          </BoldTypography>
                          <AddFavoriteButton
                            category='compactMonochrome'
                            onClick={handleAddColor}
                          >
                            <IconHeart width={18} height={18} />
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_ADD_FAVORITE'
                            })}
                          </AddFavoriteButton>
                        </ChipsRow>
                        <ColorChips
                          setColor={setChangedColor}
                          colors={basicColors}
                        />
                      </ChipsWrapper>
                      <ChipsWrapper>
                        <ChipsRow>
                          <BoldTypography>
                            {intl.formatMessage({
                              id: 'SIDE_NAV_COLOR_CONTENT_FAVORITE'
                            })}
                          </BoldTypography>
                          {favColor.length > 0 && (
                            <EditFavoriteButton
                              category='compactMonochrome'
                              onClick={handleClickEditFavorite}
                            >
                              {isEditMode ? <IconTrashcan /> : <IconPenCil />}
                              {intl.formatMessage({
                                id: isEditMode ? 'DELETE' : 'EDIT'
                              })}
                            </EditFavoriteButton>
                          )}
                        </ChipsRow>
                        <div style={{ maxHeight: 152, overflowY: 'auto' }}>
                          <ColorChips
                            setColor={setChangedColor}
                            colors={favColor}
                            isEditMode={isEditMode}
                            checkList={checkList}
                            handleCheckList={handleCheckList}
                          />
                        </div>
                      </ChipsWrapper>
                    </div>
                  </Container>
                )
              )}
              <Footer>
                <DefaultButton
                  category='secondaryMulticolored'
                  onClick={() => {
                    onColorChange(changedColor.replace(/#/, ''))
                  }}
                  fullWidth={isBreakpoint('medium')}
                  style={{ padding: 12, fontWeight: 600 }}
                >
                  {intl.formatMessage({
                    id: 'SIDE_NAV_COLOR_CONTENT_BUTTON_DONE'
                  })}
                </DefaultButton>
              </Footer>
            </Wrapper>
          </PanelTitle>
        </>
      )}
      {!initialDefaultColor && (
        <PanelTitle
          title={title}
          handleReset={handleResetColor}
          handleDrawerClose={handleDrawerClose}
          wrapperStyle={{ height: '100%' }}
        >
          <EmptyText
            text={intl.formatMessage({ id: 'SIDE_NAV_COLOR_CONTENT_NONE' })}
          />
        </PanelTitle>
      )}
    </>
  )
}

export default ColorPanel
