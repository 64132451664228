import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'

interface ToggleContextType {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const ToggleContext = createContext<ToggleContextType>({
  isOpen: false,
  setIsOpen: () => {}
})

export const ToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <ToggleContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </ToggleContext.Provider>
  )
}

export const useToggleContext = (): ToggleContextType => {
  const context = useContext(ToggleContext)
  return context
}
