import React, { useEffect, useRef } from 'react'
import {
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps
} from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import TitleBar, { TitleProps } from './TitleBar'
import { Background, Content, Wrapper } from './styles'

export type CloseBtnType = 'round' | 'line'

export type ModalProps = TitleProps & {
  visible?: boolean
  backgroundColor?: string
  style?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>

  titleStyle?: FlattenSimpleInterpolation | undefined
  fitContent?: boolean
  noFullHeight?: boolean
  wrapperStyle?:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
  flowHidden?: boolean
  closable?: boolean
  notitleBar?: boolean
  blur?: boolean
}

const Modal: React.FC<ModalProps> = ({
  children,
  backButton,
  title,
  closable = true,
  visible,
  backgroundColor,
  style,
  titleStyle,
  closeBtnType,
  onGoBack,
  onClose,
  sizeName,
  noFullHeight,
  fitContent = false,
  wrapperStyle,
  flowHidden,
  notitleBar = false,
  blur = false
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      width: 100%;
      height: 100%;
      overflow: hidden;
      `
    return () => {
      document.body.style.cssText = ''
    }
  }, [])

  useEffect(() => {
    if (visible) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === 'Escape' &&
          closable &&
          onClose &&
          document.activeElement &&
          (Array.from(
            wrapperRef?.current?.querySelectorAll('*') || []
          ).includes(document.activeElement) ||
            wrapperRef?.current === document.activeElement)
        ) {
          onClose()
        }
      }

      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
    return () => {}
  }, [closable, onClose, visible])
  return (
    <>
      <Background
        visible={!!visible}
        backgroundColor={backgroundColor}
        onClick={() => closable && onClose && onClose()}
        blur={blur}
      />
      <CSSTransition
        nodeRef={wrapperRef}
        in={visible}
        timeout={200}
        unmountOnExit
        classNames='modal-transition'
      >
        <Wrapper
          ref={wrapperRef}
          custom={wrapperStyle}
          noFullHeight={noFullHeight}
          fitContent={fitContent}
          flowHidden={flowHidden}
        >
          {!notitleBar && (
            <TitleBar
              title={title}
              backButton={backButton}
              closable={closable}
              sizeName={sizeName}
              custom={titleStyle}
              onGoBack={onGoBack}
              onClose={onClose}
              closeBtnType={closeBtnType}
            />
          )}
          <Content custom={style} fitContent={fitContent}>
            {children}
          </Content>
        </Wrapper>
      </CSSTransition>
    </>
  )
}

export default Modal
