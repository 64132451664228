import { useMutation } from 'react-query'
import { service } from '../../utils/api'
import { message } from 'antd'
import { PROJECT_STATUS } from '../../types/constants'
import { useIntl } from 'react-intl'

export const useModifyProjectMutation = () => {
  const intl = useIntl()
  const { mutate: modifyMutate } = useMutation<
    Awaited<Promise<boolean>>,
    Error,
    string
  >(
    (projectId: string) =>
      service().projects.changeStatus(projectId, PROJECT_STATUS.EDIT),
    {
      onError: (error) => {
        if (
          error instanceof Object &&
          'msg' in error &&
          typeof error.msg === 'string'
        ) {
          console.log('error', error)
          message.error(intl.formatMessage({ id: error.msg }))
        }
      }
    }
  )
  return { modifyMutate }
}
