import { Project } from '../../types/project'

const parseProjectData = (data: Project) => {
  const newData = { ...data }
  const lastSceneType = newData.lastSceneType
  if (typeof lastSceneType === 'number') {
    const lastSceneIndex = newData?.design?.sources?.length - 1
    const lastSceneFromOriginal = newData?.design?.sources?.[lastSceneIndex]
    const lastSceneFromDesign =
      newData?.design?.lastsources?.[lastSceneType - 1]
    if (lastSceneFromOriginal) {
      if (lastSceneFromDesign) {
        lastSceneFromOriginal.sceneImage =
          newData?.design?.lastsources[lastSceneType - 1].sceneImage
      }
      let otIndex = 0
      let olIndex = 0
      lastSceneFromOriginal.source = lastSceneFromOriginal.source
        .map((item) => {
          if (item.sourceType === 'OT') {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === 'OT'
              }
            )[otIndex++]
            if (lastSceneSource) {
              return lastSceneSource
            }
          }

          if (item.sourceType === 'OL') {
            const lastSceneSource = lastSceneFromDesign?.source?.filter(
              ({ sourceType }) => {
                return sourceType === 'OL'
              }
            )[olIndex++]
            if (lastSceneSource) {
              return lastSceneSource
            }
          }

          return item
        })
        .map((item) => {
          const newItem = { ...item }
          if (lastSceneType === 3 || lastSceneType === 1) {
            if (newItem.sourceType === 'OT') {
              newItem.sourceType = 'T'
            }
          }

          if (lastSceneType === 3 || lastSceneType === 2) {
            if (newItem.sourceType === 'OL') {
              newItem.sourceType = 'I'
            }
          }
          return newItem
        })
      newData.design.sources[lastSceneIndex] = lastSceneFromOriginal
    }
  }
  return newData
}

export default parseProjectData
