import { IntlShape } from 'react-intl'
import { PROJECT_STATUS } from '../../types/constants'

export const getStatusText = (
  intl: IntlShape,
  status: number,
  renderProgress: number | undefined
) => {
  const { formatMessage } = intl
  if (
    ((status > PROJECT_STATUS.EDIT && status < PROJECT_STATUS.RENDER_DONE) ||
      status === PROJECT_STATUS.WAIT) &&
    renderProgress !== undefined
  ) {
    return `${formatMessage({ id: 'RENDERING' })}(${renderProgress}%)`
  }
  if (status === PROJECT_STATUS.EDIT) {
    return `${formatMessage({ id: 'BUTTON_RENDER' })}`
  }
  if (status >= PROJECT_STATUS.RENDER_DONE) {
    return formatMessage({ id: 'MODIFY_BUTTON' })
  }
  return `${formatMessage({ id: 'BUTTON_RENDER' })}`
}

export type FormatTextOptions = { [key: string]: number }

export const getTooltipMessage = (
  status: number,
  isModified: boolean,
  isLimit: boolean | undefined,
  formatText: (id: string, options?: FormatTextOptions) => string,
  renderLimit?: number
) => {
  if (isLimit) {
    return formatText('BUTTON_RENDER_HOVER1', { n: renderLimit || 3 })
  }
  if (status >= PROJECT_STATUS.RENDER_DONE && !isModified) {
    return formatText('BUTTON_RENDER_HOVER2')
  }
  if (
    status < PROJECT_STATUS.RENDER_DONE ||
    (status >= PROJECT_STATUS.RENDER_DONE && isModified)
  ) {
    return formatText('BUTTON_RENDER_HOVER3')
  }
  return formatText('BUTTON_RENDER_HOVER3')
}

export const linkToPricing = () => {
  window.location.href = `${process.env.REACT_APP_VPLATE_URL}/pricing`
}
