import { useIntl } from 'react-intl'

interface Variables {
  [key: string]: string | number | undefined
}

const useFormatText = (text: string, variables: Variables = {}): string => {
  const intl = useIntl()
  const { formatMessage } = intl

  return formatMessage({ id: text }, variables)
}

export default useFormatText
