import { sendMessageToParent } from '../../Editor/Main/constants'

const redirectToVplateAuth = (authUrl: string) => {
  const isFromIframe = window.parent !== window
  const projectIdUrl = location.pathname
  const redirectPath = `/auth/${authUrl}`

  if (isFromIframe) {
    sendMessageToParent({
      code: 'ROUTE_PUSH',
      data: `${redirectPath}?redirectUrl=/app${projectIdUrl}`
    })
  }

  window.location.href = `${process.env.REACT_APP_VPLATE_URL}${redirectPath}?redirectUrl=/app${projectIdUrl}`
}

export const redirectVplateSignupPage = () => {
  redirectToVplateAuth('signup')
}

export const redirectVplateLoginPage = () => {
  redirectToVplateAuth('signin')
}
