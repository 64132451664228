import styled from 'styled-components'
import theme from '../../styles/theme'

const DurationChip = ({ duration }: { duration: string }) => {
  return <Chip className='media-duration'>{duration}</Chip>
}

export default DurationChip

const Chip = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 4px;
  display: inline-flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${theme.colors.white};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  white-space: nowrap;
`
