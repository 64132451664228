import theme from '../../styles/theme'
import { PLAN_TYPE } from '../../types/constants'

// TODO: fix any type
export const getPlanTitle = (planType?: any): string => {
  if (!planType) return 'PLAN_FREE'
  if (PLAN_TYPE.BASIC.includes(planType)) return 'PLAN_BASIC'
  if (PLAN_TYPE.ENTERPRISE.includes(planType)) return 'PLAN_ENTERPRISE'
  return 'PLAN_FREE'
}

export const getPlanColor = (planType?: any): string => {
  if (!planType) return theme.colors.plan.free
  if (PLAN_TYPE.BASIC.includes(planType)) return theme.colors.plan.basic
  if (PLAN_TYPE.ENTERPRISE.includes(planType))
    return theme.colors.plan.enterprise
  return theme.colors.plan.free
}
