import styled from 'styled-components'

import iconRobot from '../../assets/icons/icon_ai_robot.png'
import useFormatText from '../../hooks/useFormatText'

interface RestScenarioProps {
  scenarioLimit?: number
  restScenarioCount: number
  // isExcededLimit: boolean
}
const RestScenario = ({
  scenarioLimit,
  restScenarioCount
}: // isExcededLimit
RestScenarioProps) => {
  // const linkToPricing = () => {
  //   if (!isExcededLimit) return
  //   window.location.href = `${process.env.REACT_APP_VPLATE_URL}/pricing`
  // }
  return (
    <RestScenarioWrapper
    // isExcededLimit={isExcededLimit}
    // onClick={linkToPricing}
    >
      <img src={iconRobot} className='robot' />
      <p>
        {useFormatText('SIDE_NAV_AI_REST_MARKETING_PLAN_COUNT')}
        <>
          <strong>{restScenarioCount}</strong>
          <span>/ {scenarioLimit}</span>
        </>
      </p>
      {/* {isValidUserLimit && (
        <DefaultTooltip
          position={{ top: 'calc(100% + 13px)', right: '-12px' }}
          tail={{
            direction: 'top',
            position: { top: '-8%', right: '16px' }
          }}
          contents={useFormatText('SIDE_NAV_AI_SCENARIO_REDUCE_INFO_TOOLTIP')}
          wrapperStyle={{ backgroundColor: 'transparent', height: '16px' }}
          textStyle={{ fontSize: '14px' }}
          tailStyle={css`
            transform: translateY(-50%);
          `}
          touch
          always={isMobile}
        >
          <IconInfo className='info' />
        </DefaultTooltip>
      )} */}
    </RestScenarioWrapper>
  )
}

export default RestScenario

const RestScenarioWrapper = styled.div<{ isExcededLimit?: boolean }>`
  margin-bottom: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  background-color: #f9faff;
  gap: 4px;
  /* cursor: ${({ isExcededLimit }) =>
    isExcededLimit ? 'pointer' : 'default'}; */

  .robot {
    width: 20px;
    height: 20px;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: #0a0a0a;

    strong {
      margin: 0 2px 0 4px;
      font-weight: 600;
      color: #444bf6;
    }

    span {
      color: #444bf6;
    }
  }

  .info {
    width: 16px;
    height: 16px;
    path {
      fill: #a3a3a3;
    }
  }

  .infinite {
    path {
      stroke: #444bf6;
    }
  }
`
