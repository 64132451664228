import styled from 'styled-components'
import useFormatText from '../../hooks/useFormatText'
import { SyntheticEvent } from 'react'

interface VideoProps {
  className?: string
  videoClassName?: string
  src?: string
  handleLoadedData: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  handlePreviewError: () => void
  muted?: boolean
  autoPlay?: boolean
  playsInline?: boolean
  onError?: () => void
  onPlay?: () => void
  isPreviewError: boolean
}

const Video = ({
  className,
  videoClassName,
  src,
  isPreviewError,
  onPlay,
  handleLoadedData,
  handlePreviewError,
  ...props
}: VideoProps) => {
  const VIDEO_PREVIEW_ERROR1 = useFormatText('VIDEO_PREVIEW_ERROR1')
  const VIDEO_PREVIEW_ERROR2 = useFormatText('VIDEO_PREVIEW_ERROR2')
  const VIDEO_PREVIEW_ERROR3 = useFormatText('VIDEO_PREVIEW_ERROR3')

  return (
    <div style={{ width: '100%' }} className={className}>
      {!isPreviewError && src && (
        <video
          className={videoClassName}
          src={src}
          onLoadedMetadata={(e) => handleLoadedData(e)}
          onError={handlePreviewError}
          onPlay={onPlay}
          {...props}
        />
      )}
      {isPreviewError && (
        <ErrorWrapper>
          <span>{VIDEO_PREVIEW_ERROR1}</span>
          <br />
          <span>{VIDEO_PREVIEW_ERROR2}</span>
          <span>{VIDEO_PREVIEW_ERROR3}</span>
        </ErrorWrapper>
      )}
    </div>
  )
}

export default Video

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    line-height: 1.5;
  }
`
