import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import Tabs from '../Tabs'
import { useIntl } from 'react-intl'
import PanelTitle from '../PanelTitle'
import useBreakpoint from '../../hooks/useBreakpoint'
import MarketingDataEditor from './MarketingDataEditor'

interface MarketingPanelProps {
  handleClick: (persona: string) => void
  handleDrawerClose: () => void
}

const SelfMarketingPanel = ({
  handleClick,
  handleDrawerClose
}: MarketingPanelProps) => {
  const intl = useIntl()
  const { isMobile } = useBreakpoint()

  const tabList = [
    {
      title: intl.formatMessage({ id: 'SIDE_NAV_SELF_MARKETING' }),
      children: <MarketingDataEditor handleClick={handleClick} />
    }
  ]

  return (
    <Wrapper>
      {isMobile && (
        <PanelTitle
          title='SIDE_NAV_SELF_MARKETING'
          handleDrawerClose={handleDrawerClose}
          wrapperStyle={{ height: '100%' }}
        >
          <TabWrapper>
            <Tabs tabs={tabList} />
          </TabWrapper>
        </PanelTitle>
      )}
      {!isMobile && (
        <TabWrapper>
          <Tabs tabs={tabList} />
        </TabWrapper>
      )}
    </Wrapper>
  )
}

export default SelfMarketingPanel

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .tabs-wrapper {
    display: flex;
    flex-direction: column;
  }
  .tab-content {
    height: auto;
    flex: 1;
  }
`

const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${theme.colors.text.secondary};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - 44px);
    `)};
`
