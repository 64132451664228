import { useQuery } from 'react-query'
import { service } from '../../utils/api'

export const useQueryUserInfo = () => {
  const { data: userInfo } = useQuery<Awaited<Promise<any>>, Error, string>(
    ['userInfo'],
    () => service().users.getUserInfo()
  )
  return { userInfo }
}
