import styled, { css } from 'styled-components'
import { tabletMedia } from './constants'

import { useEffect, useMemo, useRef } from 'react'
import Modal from '../../components/Modal'
import theme from '../../styles/theme'
import AiTitle from './AiTitle'
import AiMedia from './AiMedia'
import useBreakpoint from '../../hooks/useBreakpoint'
import LeaveButton from './LeaveButton'
import useTablet from './hooks/useTablet'
import ErrorModal from './ErrorModal'
import useAIInfoSSE from './hooks/useAIInfoSSE'
import { useUserLimitContext } from '../../contexts/UserLimitContext'
import ProductInfo from './ProductInfo'
import MarketingInfo from './MarketingInfo'
import LottieIcon from '../../components/Lottie'
import useFormatText from '../../hooks/useFormatText'
import PlaceInfo from './PlaceInfo'

export interface AiLoadingModalProps {
  onClose: () => void
  isGenerateScenario?: boolean
  isPlace?: boolean
}

const AiLoadingModal = ({
  onClose,
  isGenerateScenario,
  isPlace
}: AiLoadingModalProps) => {
  const { isMobile } = useBreakpoint()
  const { isTablet } = useTablet()
  const { userLimitRefetch } = useUserLimitContext()

  const { aiInfo, isError, isPendingError, isRetryFailError } = useAIInfoSSE({
    onClose,
    completeCallback: userLimitRefetch
  })

  const showOnlyMarketingInfo = useMemo(
    () => !isPlace && (aiInfo?.isNewScenario === true || !!isGenerateScenario),
    [isPlace, aiInfo?.isNewScenario, isGenerateScenario]
  )

  const progress = aiInfo?.progress

  const isMediaStep = isMobile
    ? !!aiInfo?.totalMediaLen
    : progress?.mediaProgress !== undefined &&
      progress?.marketingProgress >= 100 &&
      !!aiInfo?.totalMediaLen

  const isAutoRender = aiInfo?.autoRender

  const mediaRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!progress) return
    if (progress?.marketingProgress >= 100 && !!aiInfo?.totalMediaLen) {
      mediaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
      return
    }
  }, [aiInfo, progress])

  return (
    <Modal
      visible
      closable={isError || aiInfo?.progress?.isComplete}
      onClose={onClose}
      notitleBar
      backgroundColor={`${theme.colors.black}80`}
      wrapperStyle={modalWrapperStyle(showOnlyMarketingInfo, isMobile)}
      style={contentStyle}
    >
      <Wrapper showOnlyMarketingInfo={showOnlyMarketingInfo}>
        <AiTitle
          isMobile={isMobile}
          isPendingError={isPendingError}
          progress={progress}
          mediaTotalLength={aiInfo?.totalMediaLen}
          isAutoRender={isAutoRender}
          showOnlyMarketingInfo={showOnlyMarketingInfo}
          isPlace={isPlace}
        />
        <Contents>
          <Container
            isMediaStep={isMediaStep}
            showOnlyMarketingInfo={showOnlyMarketingInfo}
          >
            {!showOnlyMarketingInfo &&
              (isPlace ? (
                <PlaceInfo
                  aiInfo={aiInfo}
                  isMobile={isMobile}
                  progress={progress?.placeProgress}
                  isPendingError={isPendingError}
                />
              ) : (
                <ProductInfo
                  aiInfo={aiInfo}
                  isMobile={isMobile}
                  progress={progress?.productProgress}
                  isPendingError={isPendingError}
                />
              ))}
            <MarketingInfo
              aiInfo={aiInfo}
              isMobile={isMobile}
              progress={progress?.marketingProgress}
              isPendingError={isPendingError}
              showOnlyMarketingInfo={showOnlyMarketingInfo}
            />
          </Container>
          {!showOnlyMarketingInfo && isMediaStep && (
            <>
              <AiMedia
                medias={aiInfo?.media}
                isMobile={isMobile}
                progress={progress?.mediaProgress}
                mediaTotalLength={aiInfo?.totalMediaLen}
                isReset={aiInfo?.status === -5}
              />
              <div style={{ width: '100%', height: 'auto' }} ref={mediaRef} />
            </>
          )}
        </Contents>
        {(showOnlyMarketingInfo &&
          progress?.marketingProgress &&
          progress?.marketingProgress >= 100 &&
          !progress?.isComplete && (
            <CopyLoading>
              {useFormatText('AI_LOADING_MODAL_LOADING')}
              <LottieIcon width={34} height={34} />
            </CopyLoading>
          )) || <></>}
        {(isTablet || isMobile) && !showOnlyMarketingInfo && (
          <LeaveButton isAutoRender={isAutoRender} />
        )}
        {isRetryFailError && <ErrorModal onClose={onClose} isPlace={isPlace} />}
      </Wrapper>
    </Modal>
  )
}

export default AiLoadingModal

export const Wrapper = styled.div<{ showOnlyMarketingInfo: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;

  ${({ showOnlyMarketingInfo }) =>
    showOnlyMarketingInfo
      ? css`
          flex-direction: column;
        `
      : tabletMedia(css`
          flex-direction: column;
        `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      display: grid;
      grid-template-rows: auto 1fr auto;
    `)};
`

export const modalWrapperStyle = (
  showOnlyMarketingInfo: boolean,
  isMobile: boolean
) => css`
  &&& {
    width: 100%;
    max-width: ${isMobile
      ? '100%'
      : showOnlyMarketingInfo
      ? '505px'
      : '1276px'};
    height: calc(100% - 40px);
    max-height: ${showOnlyMarketingInfo ? '720px' : '832px'};
    border: none;
    border-radius: 12px;
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.06);
    padding: 36px 24px;

    ${showOnlyMarketingInfo
      ? tabletMedia(css`
          width: fit-content;
          max-width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media (max-width: 900px) {
            width: calc(100% - 80px);
          }
        `)
      : tabletMedia(css`
          width: calc(100% - 204px);
          max-width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media (max-width: 900px) {
            width: calc(100% - 80px);
          }
        `)};

    ${({ theme }) =>
      theme.breakpoints.medium(css`
        padding: 0;
      `)};

    ${({ theme }) =>
      theme.breakpoints.small(css`
        height: 100%;
        max-height: none;
        top: unset;
        bottom: 0;
        border-radius: 0;
      `)};
  }
`

const MOBILE_FOOTER_HEIGHT = 62

export const Contents = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${tabletMedia(css`
    height: 100%;
    overflow-y: auto;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: calc(100% - ${MOBILE_FOOTER_HEIGHT}px);
      gap: 30px;
      padding: 0 16px 14px 16px;
      overflow-y: auto;
    `)}
`

export const Container = styled.div<{
  isMediaStep: boolean
  showOnlyMarketingInfo: boolean
}>`
  width: 100%;
  height: ${({ isMediaStep, showOnlyMarketingInfo }) =>
    !showOnlyMarketingInfo && isMediaStep ? '376px' : '100%'};
  display: flex;
  transition: all 0.5s ease-in-out;

  ${({ isMediaStep, showOnlyMarketingInfo }) =>
    !showOnlyMarketingInfo &&
    tabletMedia(css`
      height: ${isMediaStep ? 'max-content' : '100%'};
      gap: 48px;

      & > div {
        height: ${isMediaStep ? '305px' : '100%'};
        flex: 1 0 calc(50% - 24px);
        padding: 0;
      }

      @media (max-width: 1065px) {
        height: max-content;
        flex-wrap: wrap;

        & > div {
          height: 305px;
        }
      }
    `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      height: max-content;
      flex-direction: column;
      gap: 30px;
    `)};
`

const CopyLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.black2};
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 24px */

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      margin-bottom: 40px;
    `)}
`

export const contentStyle = css`
  width: 100%;
  height: 100%;
  padding: 0;
`
