import React from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'

import Button from '../Button'
import CloseIcon from '../CloseIcon'
import Typography from '../Typography'
import { CloseBtnType } from '.'
import { iconModalCloseLine } from '../../assets/icons'

export type TitleProps = {
  title?: string | React.ReactNode
  backButton?: string | React.ReactNode
  closable?: boolean
  sizeName?: string
  custom?: FlattenSimpleInterpolation | undefined
  closeBtnType?: CloseBtnType
  onGoBack?(): void
  onClose?(): void
}

const TitleBar = ({
  backButton,
  onGoBack,
  title,
  closable = true,
  custom,
  closeBtnType,
  onClose,
  sizeName
}: TitleProps) => (
  <Wrapper custom={custom}>
    <div
      style={{
        left: 0
      }}
    >
      {backButton && (
        <Button link onClick={onGoBack}>
          {backButton}
        </Button>
      )}
    </div>
    <div style={{ width: '100%', zIndex: 0, textAlign: 'center' }}>
      {typeof title === 'string' ? (
        <Typography bold type='body1'>
          {title}
        </Typography>
      ) : (
        title
      )}
    </div>
    <div
      style={{
        right: 0
      }}
    >
      {closable && (
        <Button link onClick={onClose}>
          {closeBtnType === 'round' ? (
            <CloseIcon size={sizeName} />
          ) : (
            <LineCloseBtn icon={iconModalCloseLine} />
          )}
        </Button>
      )}
    </div>
  </Wrapper>
)

export default React.memo(TitleBar, (prevProps, nextProps) => {
  return (
    prevProps.sizeName === nextProps.sizeName &&
    prevProps.onClose === nextProps.onClose &&
    prevProps.closable === nextProps.closable
  )
})

const Wrapper = styled.div<{ custom: FlattenSimpleInterpolation | undefined }>`
  width: 100%;
  height: 52px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.border.primary};
  user-select: none;
  & > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  ${({ custom }) => custom};
`

const LineCloseBtn = styled.div<{ icon: string }>`
  width: 24px;
  height: 24px;
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
`
