const colors = {
  brand: {
    primary: {
      10: '#FFF3F3',
      50: '#FDE8E8',
      100: '#FAD0D0',
      200: '#F6A1A1',
      300: '#F17371',
      400: '#ED4442',
      500: '#E81513',
      600: '#AE100E',
      700: '#740B0A',
      800: '#3A0505',
      900: '#170202'
    },
    secondary: {
      10: '#FBF8FF',
      50: '#ECE6F8',
      100: '#D8CCF1',
      200: '#B099E2',
      300: '#8966D4',
      400: '#6133C5',
      500: '#3A00B7',
      600: '#2C0089',
      700: '#1D005C',
      800: '#0F002E',
      900: '#060012'
    }
  },
  gray: {
    0: '#FFFFFF',
    10: '#F5F5F5',
    50: '#F0F0F0',
    100: '#E0E0E0',
    200: '#C2C2C2',
    300: '#A3A3A3',
    400: '#858585',
    500: '#666666',
    600: '#4D4D4D',
    700: '#333333',
    800: '#1A1A1A',
    900: '#0A0A0A'
  },
  primary: '#e54948',
  secondary: '#9134e2',
  white: '#ffffff',
  black: '#333333',
  black2: '#222222',
  gradient: 'linear-gradient(136deg, #e54948 0%, #9134e2 100%)',
  gradient2: 'linear-gradient(180deg, #E54948 0%, #9134E2 100%)',
  button: {
    primary: {
      hoverBg: '#e02221',
      disabledBg: '#e9e9e9',
      disabledColor: '#c4c4c4'
    },
    secondary: {
      disabledBg: '#e9e9e9',
      disabledBorder: '#c4c4c4',
      disabledColor: '#c4c4c4',
      monochrome: {
        border: '#888888',
        color: '#888888',
        hoverBorder: '#6d6d6d',
        hoverColor: '#6d6d6d'
      },
      multicolored: {
        hoverBorder: '#e02221',
        hoverColor: '#e02221'
      }
    },
    compact: {
      monochrome: {
        border: '#d9d9d9',
        color: '#888888',
        disabledBg: '#f4f4f4',
        disabledColor: '#c4c4c4',
        hoverBorder: '#b5b5b5',
        hoverColor: '#6d6d6d'
      },
      multicolored: {
        disabledBorder: '#b5b5b5',
        disabledColor: '#6d6d6d'
      }
    },
    large: {
      disabledBg: '#d6d6d6'
    }
  },
  text: {
    primary: '#333333',
    secondary: '#666666',
    '#222': '#222222',
    '#333': '#333333',
    '#555': '#555555',
    '#666': '#666666',
    '#777': '#777777',
    '#888': '#888888',
    '#999': '#999999',
    '#aaa': '#aaaaaa',
    disabled: '#cccccc',
    link: '#888888',
    active: '#e54948'
  },
  background: {
    primary: '#F9F9F9',
    secondary: '#F0F0F0',
    tertiary: '#505050',
    active: '#FEEEEE'
  },
  input: {
    disabled: '#F1F1F1'
  },
  pink: '#FFEEEE',
  active: '#E54948',
  activeBg: '#FEEEEE',
  border: {
    primary: '#E1E1E1',
    secondary: '#f2f2f2'
  },
  plan: {
    free: '#323232',
    basic: '#2569ED',
    enterprise: '#4717D1'
  },
  tutorial: {
    hover: '#F7F7F7',
    active: '#404040'
  },
  complementary: '#48cde5',
  analogous: '#ffe8e8',
  danger: '#ed0505',
  red: '#FF0000',
  warning: '#fec324',
  success: '#35c440',
  info: '#44abf7',
  dark: '#131313',
  backgroundEditor: '#fafafa',
  grey: '#00000080',
  lightGrey: '#d9d9d9',
  lightPink: '#F7EBEB',
  chipBorder: '#CFCFCF',
  checkbox: '#116DF5',
  f5f5: '#F5F5F5',
  b6b6: '#b6b6b6',
  eded: '#ededed',
  dropdownHover: '#f4f4f4'
} as const

export type Colors = typeof colors
export default colors
