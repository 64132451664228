import { css } from 'styled-components'
import { sizes } from '../../styles/theme/breakpoints'

export const addMissingText = (newText: string, prevText: string) => {
  const newWords = [...newText].filter((_word, index) => !prevText[index])
  if (newWords.length === 0) return prevText
  return prevText + newWords[0]
}

export const twoDigits = (_num: number) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const num = !!_num ? _num : 0
  return num < 10 ? '0' + num : num
}

export const TABLET_SIZE = 1480
export const tabletMedia = (args: ReturnType<typeof css>) => {
  return css`
    @media only screen and (min-width: ${sizes.medium}px) and (max-width: ${TABLET_SIZE}px) {
      ${args}
    }
  `
}

export const getTitle = (isTablet: boolean, isError: boolean) => {
  if (isError) {
    return 'AI_LOADING_MODAL_DESCRIPTION3'
  }
  if (isTablet) {
    return 'AI_LOADING_MODAL_DESCRIPTION2'
  }
  return 'AI_LOADING_MODAL_DESCRIPTION1'
}

export const initAdPlanning = [
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  },
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  },
  {
    appealPoint: '',
    adMessage1: '',
    adMessage2: '',
    adMessage3: ''
  }
]

export const getTooltipStyle = (isTablet: boolean, isLimit?: boolean): any => {
  const common = {
    contentStyle: {
      width: isLimit ? 180 : 246,
      padding: '6px 8px'
    },
    textStyle: { fontSize: 12, letterSpacing: '-0.24px', whiteSpace: 'initial' }
  }

  if (isTablet) {
    return {
      ...common,
      position: { top: -4, right: 'calc(100% + 12px)' },
      tail: { direction: 'right', position: { top: 18, right: -5 } }
    }
  }
  return {
    ...common,
    position: { top: -4, left: 'calc(100% + 12px)' },
    tail: { direction: 'left', position: { top: 18, left: -5 } }
  }
}
