import { message } from 'antd'
import { useQueryClient } from 'react-query'
import { service } from '../../../../utils/api'
import { assets } from '../../../../utils/api'
import { useIntl } from 'react-intl'

const useUploadFiles = ({
  setFiles,
  queryKey,
  limit
}: {
  setFiles: React.Dispatch<React.SetStateAction<any[]>>
  queryKey: any[]
  limit: number
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const updateFileStatus = (file: File, updates: any) => {
    setFiles((prev) => {
      const currentIndex = prev.findIndex((item) => item?.name === file?.name)
      const newFiles = [...prev]

      const fileObj = {
        file,
        name: file?.name,
        size: file?.size,
        type: file?.type,
        lastModified: file?.lastModified,
        ...updates
      }

      if (file instanceof File && currentIndex === -1) {
        newFiles.unshift({
          ...fileObj,
          ...updates
        })
      }

      if (currentIndex > -1) {
        newFiles[currentIndex] = {
          ...fileObj,
          ...updates
        }
      }

      return newFiles
    })
  }

  const updateProgress = (file: File, progressPercent: number) => {
    updateFileStatus(file, { uploadProgress: progressPercent })
  }

  const handleUploadProgress = (
    progressEvent: ProgressEvent<any>,
    file: File
  ) => {
    const progressPercent = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    )
    updateProgress(file, progressPercent)
  }

  const handleUploadFail = (file: File) => {
    updateFileStatus(file, { isError: true })
  }

  const handleUploadSuccess = (file: File, result: any) => {
    updateFileStatus(file, result)
    if (!result?.originalUrl) return

    setFiles((prev) => prev.filter((item) => item?._id !== result._id))

    queryClient.setQueryData([...queryKey], (prev: any) => {
      prev.pages[0] = [result, ...prev.pages[0]].splice(0, limit)

      return {
        ...prev,
        pages: prev.pages
      }
    })
    queryClient.invalidateQueries('storageInfo')
  }

  const formatDuration = (durationInSeconds: number) => {
    const minutes = Math.floor(durationInSeconds / 60)
    const seconds = Math.floor(durationInSeconds % 60)

    return `${minutes}:${seconds}`
  }

  const filesWithResolution = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const newFile = {
          name: file?.name,
          size: file?.size,
          type: file?.type,
          lastModified: file?.lastModified
        }

        if (!reader.result || typeof reader.result !== 'string') {
          reject()
          return
        }

        if (file?.type.includes('image')) {
          const img = new Image()
          img.src = reader.result

          img.onload = () => {
            resolve({
              ...newFile,
              resolution: `${img.width} x ${img.height}`
            })
          }
        } else {
          const video = document.createElement('video')
          video.src = reader.result

          video.onloadedmetadata = () => {
            resolve({
              ...newFile,
              resolution: `${video.videoWidth}x${video.videoHeight}`,
              duration: formatDuration(video.duration)
            })
          }
        }
      }
    })
  }

  /** 0.01 보다 작으면 0.01로 보내기 */
  const bytesToMegabytes = (bytes: number) => {
    const result = Number((bytes / (1024 * 1024)).toFixed(2))
    return result > 0.01 ? result : 0.01
  }

  const uploadFile = async (file: File) => {
    try {
      const fileWithResolution = (await filesWithResolution(file)) as any
      const userInfo = (await service().users.getUserInfo()) as any
      const result = await assets().assets.uploadAssets(
        {
          userId: (userInfo?._id || '') as string,
          size: bytesToMegabytes(fileWithResolution.size),
          resolution: fileWithResolution.resolution,
          duration: fileWithResolution?.duration,
          file,
          type: file?.type.includes('image') ? 'image' : 'video'
        },
        (progressEvent) => handleUploadProgress(progressEvent, file)
      )

      if (!result || !result?._id) {
        handleUploadFail(file)
        return
      }
      handleUploadSuccess(file, result)
    } catch (error) {
      message.error(intl.formatMessage({ id: 'STORAGE_UPLOAD_FAILED' }))
      console.log(error)
      handleUploadFail(file)
    }
  }

  return {
    uploadFile
  }
}

export default useUploadFiles
