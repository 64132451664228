import styled, { css, keyframes } from 'styled-components'
import { ButtonProps } from '.'
import theme from '../../styles/theme'

export const primaryCss = css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.primary};
  border-color: ${theme.colors.primary};
`

export const secondaryCss = css`
  color: ${theme.colors.text.link};
  background-color: transparent;
  border-color: ${theme.colors.text.link};
`

export const linkCss = css`
  color: ${theme.colors.text.link};
  background-color: transparent;
  border-color: transparent !important;
`

export const disabledCss = css`
  background-color: ${theme.colors.button.primary.disabledBg};
  color: ${theme.colors.button.primary.disabledColor};
  border: 1px solid ${theme.colors.button.disabledColor};
  cursor: unset;
`

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const ButtonCompo = styled.button<ButtonProps>`
  width: ${({ block }) => (block ? '100%' : 'auto')};
  height: auto;
  position: relative;
  cursor: pointer;
  border: 1px solid ${theme.colors.primary};
  color: ${theme.colors.primary};
  padding: ${({ size }) => {
    switch (size) {
      case 'small':
        return '12px'
      case 'large':
        return '16px'
      case 'default':
      default:
        return '12px 20px'
    }
  }};
  border-radius: ${({ round }) => (round ? '24px' : '4px')};
  font-size: ${({ size }) => {
    switch (size) {
      case 'small':
        return '14px'
      case 'large':
        return '16px'
      case 'default':
      default:
        return '14px'
    }
  }};
  font-weight: 600;
  line-height: 1;
  background-color: transparent;
  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  vertical-align: middle;
  text-align: center;
  transition: opacity 0.125s ease-in-out;
  user-select: none;
  white-space: nowrap;
  box-sizing: border-box;
  height: ${({ height }) => height + 'px'};

  ${({ loading, primary }) =>
    loading &&
    css`
      pointer-events: none;

      &:after {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-left: 4px;
        border: 2px solid
          ${primary ? theme.colors.white : theme.colors.btnTextColor};
        border-top-color: transparent;
        border-radius: 50%;
        animation: ${spin} 0.6s linear infinite;
      }
    `}

  ${({ primary }) => primary && primaryCss};
  ${({ secondary }) => secondary && secondaryCss};
  ${({ link }) => link && linkCss};

  & > * {
    vertical-align: middle;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    ${disabledCss};
    transform: unset;
  }
`
