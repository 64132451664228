import ReactPlayer from 'react-player/youtube'
import { iconModalCloseLine, iconTutorialInfo } from '../../assets/icons'

import { useEffect, useRef, useState } from 'react'
import {
  Container,
  CurriculumTitle,
  LineCloseBtn,
  LottieWrapper,
  MobileHeader,
  MobileModal,
  MobileVideoWrapper,
  Notice,
  NoticeIcon,
  StyledModal,
  TimeLineWrapper,
  Title,
  VideoWrapper,
  containerStyle,
  titleBarStyle,
  wrapperStyle
} from './styles'
import { renderTimeLines } from './constants'

import useBreakpoint from '../../hooks/useBreakpoint'
import useFormatText from '../../hooks/useFormatText'
import { useIntl } from 'react-intl'
import LottieIcon from '../../components/Lottie'

interface TutorialModalProps {
  onClose: () => void
}

const TutorialModal = ({ onClose }: TutorialModalProps) => {
  const intl = useIntl()
  const { isBreakpoint } = useBreakpoint()
  const [currentTime, setCurrentTime] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const videoRef = useRef<ReactPlayer | null>(null)
  const isLarge = isBreakpoint('large')
  const videoUrl = 'https://youtu.be/tyv6ncf7BVI'
  const formatText = (id: string) => {
    return intl.formatMessage({ id })
  }

  const TUTORIAL_MODAL_TITLE = useFormatText('TUTORIAL_MODAL_TITLE')
  const TUTORIAL_MODAL_CURRICULUM_TITLE = useFormatText(
    'TUTORIAL_MODAL_CURRICULUM_TITLE'
  )
  const TUTORIAL_MODAL_TIMELINE_INFO = useFormatText(
    'TUTORIAL_MODAL_TIMELINE_INFO'
  )

  const handleVideoReady = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (videoRef.current) {
        setCurrentTime(videoRef.current.getCurrentTime())
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      {isBreakpoint('large') ? (
        <MobileModal>
          <MobileHeader>
            <h1>{TUTORIAL_MODAL_TITLE}</h1>
            <LineCloseBtn icon={iconModalCloseLine} onClick={onClose} />
          </MobileHeader>
          <MobileVideoWrapper>
            {isLoading && (
              <LottieWrapper>
                <LottieIcon />
              </LottieWrapper>
            )}
            <ReactPlayer
              ref={videoRef}
              url={videoUrl}
              muted
              playsInline
              playing
              controls
              width='100%'
              height='100%'
              onReady={handleVideoReady}
            />
          </MobileVideoWrapper>
          <TimeLineWrapper isMobile={isLarge}>
            <CurriculumTitle isMobile={isLarge}>
              {TUTORIAL_MODAL_CURRICULUM_TITLE}
            </CurriculumTitle>
            {renderTimeLines(videoRef, isLarge, formatText, currentTime)}
            <Notice isMobile={isLarge}>
              <div>
                <NoticeIcon icon={iconTutorialInfo} />
                <p>{TUTORIAL_MODAL_TIMELINE_INFO}</p>
              </div>
            </Notice>
          </TimeLineWrapper>
        </MobileModal>
      ) : (
        <StyledModal
          visible
          backgroundColor='rgba(0, 0, 0, 0.5)'
          title={<Title>{TUTORIAL_MODAL_TITLE}</Title>}
          closable
          onClose={onClose}
          wrapperStyle={wrapperStyle}
          titleStyle={titleBarStyle}
          style={containerStyle}
          closeBtnType='line'
        >
          <Container>
            <VideoWrapper>
              {isLoading && (
                <LottieWrapper>
                  <LottieIcon />
                </LottieWrapper>
              )}
              <ReactPlayer
                ref={videoRef}
                url={videoUrl}
                muted
                playsInline
                playing
                controls
                width='100%'
                height='100%'
                onReady={handleVideoReady}
              />
            </VideoWrapper>
          </Container>
          <TimeLineWrapper isMobile={isLarge}>
            <div>
              <CurriculumTitle isMobile={isLarge}>
                {TUTORIAL_MODAL_CURRICULUM_TITLE}
              </CurriculumTitle>
              {renderTimeLines(videoRef, isLarge, formatText, currentTime)}
              <Notice isMobile={isLarge}>
                <div>
                  <NoticeIcon icon={iconTutorialInfo} />
                  <p>{TUTORIAL_MODAL_TIMELINE_INFO}</p>
                </div>
              </Notice>
            </div>
          </TimeLineWrapper>
        </StyledModal>
      )}
    </>
  )
}

export default TutorialModal
