import { useState } from 'react'

import { iconModalClose } from '../../assets/icons'
import {
  CloseBtn,
  ModalBackground,
  ModalButtonContainer,
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalWrapper,
  StyledButton
} from './styles'
import DefaultButton from '../DefaultButton'

interface ModalProps {
  icon: string
  title: string
  content?: string
  cancleBtnText?: string
  confirmBtnText: string
  semiConfirmBtnText?: string
  onClose: () => void
  onConfirm: () => void
  onSemiConfirm?: () => void
  closable?: boolean
}

const DefaultModal = ({
  icon,
  title,
  content,
  cancleBtnText,
  confirmBtnText,
  semiConfirmBtnText,
  onClose,
  onConfirm,
  onSemiConfirm,
  closable = true
}: ModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true)

  const handleCloseBtnClick = () => {
    onClose()
    setIsOpen(false)
  }

  const handleConfirm = () => {
    onConfirm()
  }

  const handleSemiConfirm = () => {
    onSemiConfirm?.()
  }

  return (
    <>
      {isOpen && (
        <ModalBackground onClick={() => closable && handleCloseBtnClick}>
          <ModalWrapper
            hasCancelButton={!!cancleBtnText}
            hasSemiConfirmButton={!!semiConfirmBtnText}
            onClick={(e) => e.stopPropagation()}
          >
            {closable && (
              <CloseBtn onClick={handleCloseBtnClick} icon={iconModalClose} />
            )}
            <ModalIcon icon={icon}>
              <div></div>
            </ModalIcon>
            <ModalTitle>{title}</ModalTitle>
            {content && <ModalContent>{content}</ModalContent>}
            <ModalButtonContainer
              hasCancleButton={!!cancleBtnText || !!semiConfirmBtnText}
            >
              {cancleBtnText && (
                <>
                  <DefaultButton
                    category='secondaryMonochrome'
                    onClick={handleCloseBtnClick}
                  >
                    {cancleBtnText}
                  </DefaultButton>
                  <DefaultButton category='primary' onClick={handleConfirm}>
                    {confirmBtnText}
                  </DefaultButton>
                </>
              )}
              {!semiConfirmBtnText && !cancleBtnText && (
                <StyledButton category='primary' onClick={handleConfirm}>
                  {confirmBtnText}
                </StyledButton>
              )}
              {semiConfirmBtnText && !cancleBtnText && (
                <>
                  <DefaultButton
                    category='primaryOutline'
                    onClick={handleSemiConfirm}
                  >
                    {semiConfirmBtnText}
                  </DefaultButton>
                  <DefaultButton category='primary' onClick={handleConfirm}>
                    {confirmBtnText}
                  </DefaultButton>
                </>
              )}
            </ModalButtonContainer>
          </ModalWrapper>
        </ModalBackground>
      )}
    </>
  )
}

export default DefaultModal
