import FFmpegService from '../libs/ffmpeg'

import axiosConfig from './axiosConfig'

export type Music = {
  musicTitle: string
  musicLength: number
  musicInstrument?: number[] | null
  musicGenre: number
  musicMood: number
  musicDescription: string
  musicUrl: string
  _id: string
  createdAt: Date
  updatedAt: Date
}

function api() {
  const axios = axiosConfig()

  const files = {
    async upload(file: Blob) {
      const formData = new FormData()
      formData.append('file', file)

      const result = await axios.post<{
        id: string
        s3: string
        type: string
        url: string
      }>('/upload/image', formData, {
        baseURL: process.env.REACT_APP_FILES_API_URL
      })
      return result.data
    },

    async cropAndTrimVideo(data: {
      file: File | undefined
      width: number
      height: number
      renderWidth: number
      renderHeight: number
      x: number
      y: number
      startTime: number
      duration: number
    }) {
      const {
        file,
        width,
        height,
        renderWidth,
        renderHeight,
        x,
        y,
        startTime,
        duration
      } = data
      if (!file || file === undefined) return null

      // NOTE: 디자인의 sourceWidth와 sourceHeight가 홀수일 경우 에러 발생
      // NOTE: 기본적으로 .h264는 짝수 치수가 필요합니다.
      let sourceWidth = renderWidth
      let sourceHeight = renderHeight

      if (sourceWidth % 2 !== 0) {
        sourceWidth += 1
      }
      if (sourceHeight % 2 !== 0) {
        sourceHeight += 1
      }
      let ffmpegResult: { videoBlob: Blob; thumbnailBlob: Blob } | undefined

      // 2023-11-01 비디오는 이슈 발생으로 인해 ffmpeg 처리 X
      if (!file.type.includes('video')) {
        try {
          ffmpegResult = await FFmpegService.doTranscode(
            file.name,
            file,
            startTime,
            duration,
            width,
            height,
            sourceWidth,
            sourceHeight,
            x,
            y
          )
        } catch (e) {
          console.log('FFmpeg Transcode Error: ', e)
        }
      }

      if (!ffmpegResult) {
        // FFmpeg wasm 작동하지 않는 경우 기존 file api 서버를 통해 업로드
        const formData = new FormData()
        formData.append('file', file)
        formData.append('cropWidth', `${width}`)
        formData.append('cropHeight', `${height}`)
        formData.append('scaleWidth', `${sourceWidth}`)
        formData.append('scaleHeight', `${sourceHeight}`)
        formData.append('cropX', `${x}`)
        formData.append('cropY', `${y}`)
        formData.append('trimStartTime', `${startTime}`)
        formData.append('trimDuration', `${duration}`)

        try {
          const result = await axios.post<{
            id: string
            s3: string
            type: string
            url: string
          }>('/upload/video/all_format', formData, {
            baseURL: process.env.REACT_APP_FILES_API_URL
          })
          return { videoUrl: result.data.s3 }
        } catch (e) {
          console.log('File API Transcode Error: ', e)
          return null
        }
      } else {
        const { videoBlob, thumbnailBlob } = ffmpegResult
        const formData = new FormData()
        formData.append('video', videoBlob)
        formData.append('thumbnail', thumbnailBlob)
        try {
          const result = await axios.post<{
            videoUrl: string
          }>('/upload/video/completed', formData, {
            baseURL: process.env.REACT_APP_FILES_API_URL
          })
          const { videoUrl } = result.data

          return { videoUrl }
        } catch (e) {
          console.log('FFmpeg Transcode Result Upload Error: ', e)
          return null
        }
      }
    },
    async conversionFile(data: { file: File }) {
      const { file } = data
      if (!file || file === undefined) return null
      let ffmpegResult: { videoUrl: string } | undefined
      try {
        ffmpegResult = await FFmpegService.doConversion(file.name, file)
      } catch (e) {
        console.log('FFmpeg Conversion Error: ', e)
        return null
      }
      if (ffmpegResult) {
        const { videoUrl } = ffmpegResult
        return { videoUrl }
      } else {
        return null
      }
    }
  }

  const music = {
    async list(data: { limit: number; page: number }) {
      const { limit = 10, page = 1 } = data
      const result = await axios.get<{ results: Music[] }>(
        '/download/music/list/info',
        {
          params: { limit, page },
          baseURL: process.env.REACT_APP_VPLATE_API_URL
        }
      )
      return result.data
    },
    async upload(data: {
      musicUrl: string
      fadeEffect?: boolean
      trimAudioStart: number
      trimAudioDuration: number
    }) {
      const {
        musicUrl,
        fadeEffect = false,
        trimAudioStart,
        trimAudioDuration
      } = data
      const result = await axios.post(
        '/upload/music',
        {
          musicUrl,
          fadeEffect,
          trimAudioStart,
          trimAudioDuration
        },
        {
          baseURL: process.env.REACT_APP_FILES_API_URL
        }
      )
      return result.data
    }
  }

  return {
    files,
    music
  }
}

export default api
