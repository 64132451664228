import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { useProjectContext } from '../../hooks/useProject'

import Area from './Area'

type ScreenProps = {
  selectedSceneIndex: number
  isSourceProcessing: (sceneIndex: number, sourceIndex: number) => boolean
  onSourceClick(index: number): void
  isFlickering: boolean
}

const Screen = ({
  selectedSceneIndex,
  isSourceProcessing,
  isFlickering,
  onSourceClick
}: ScreenProps) => {
  const { project, sceneList } = useProjectContext()

  const scene = useMemo(
    () =>
      sceneList && {
        ...sceneList[selectedSceneIndex],
        source:
          sceneList[selectedSceneIndex]?.source?.map(
            (item: any, sourceIndex: number) => ({
              ...item,
              isLoading: isSourceProcessing(selectedSceneIndex, sourceIndex)
            })
          ) || []
      },
    [isSourceProcessing, sceneList, selectedSceneIndex]
  )

  const sources = useMemo(() => {
    return (scene?.source || [])
      .map((item, index) => {
        return { ...item, index }
      })
      .sort(({ sourceZindex: a = -1 }, { sourceZindex: b = -1 }) => {
        return a < b ? -1 : 1
      })
  }, [scene])

  return (
    <>
      <Wrapper>
        <ScreenContainer>
          <svg width='100%' height='100%' viewBox='0 0 1280 720'>
            <image href={scene?.sceneImage} width='100%' height='100%' />
            {sources?.map((source) => {
              const {
                sourceArea,
                sourceType,
                sourceWidth,
                sourceHeight,
                iconArea,
                thumbnailArea,
                sourceTextLength,
                index
              } = source
              return (
                ['I', 'V', 'T'].includes(`${sourceType}`.toUpperCase()) && (
                  <Area
                    isLoading={(source as any).isLoading}
                    key={JSON.stringify(source)}
                    points={sourceArea}
                    type={sourceType}
                    data={project?.sources?.[selectedSceneIndex]?.[index]}
                    size={{
                      width: sourceWidth,
                      height: sourceHeight
                    }}
                    iconPath={{ x: iconArea?.[0], y: iconArea?.[1] }}
                    thumbnailPath={{
                      x: thumbnailArea?.[0],
                      y: thumbnailArea?.[1]
                    }}
                    onClick={() => onSourceClick && onSourceClick(index)}
                    isFlickering={isFlickering}
                    textLength={sourceTextLength?.[0] || 20}
                  />
                )
              )
            })}
          </svg>
        </ScreenContainer>
      </Wrapper>
    </>
  )
}

export default Screen

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.text.primary};
  justify-content: center;
  align-items: center;
`

const ScreenContainer = styled.div`
  height: 100%;
  aspect-ratio: 16/9;
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      height: 100%;
    `)}
`
