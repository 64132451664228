import { forwardRef, useEffect, useRef, useState } from 'react'
import styled, {
  FlattenSimpleInterpolation,
  ThemeProps
} from 'styled-components'
import { Media } from '../../types/project'
import { twoDigits } from './constants'
import DurationChip from '../../components/DurationChip'
import { FlattenInterpolation } from 'styled-components'
import { DefaultTheme } from 'styled-components'
import useLoadThumbnail from '../EditModals/Storage/hooks/useLoadThumbnail'

type CustomStyle =
  | FlattenSimpleInterpolation
  | FlattenInterpolation<ThemeProps<DefaultTheme>>

interface MediaItemProps {
  media: Media
  style?: CustomStyle
}

const MediaItem = forwardRef<HTMLDivElement, MediaItemProps>(
  ({ media, style }: MediaItemProps, ref) => {
    const [isLoaded, setIsLoaded] = useState(false)

    const type = media.type
    const fileUrl = media.url
    const {
      loadVideoThumbnail,
      isRetryOver,
      getResizedThumbnailUrl,
      getVideoThumbnailUrl
    } = useLoadThumbnail({
      fileUrl,
      callback: () => setIsLoaded(true)
    })
    const imageRef = useRef<HTMLImageElement | null>(null)

    const imageUrl = (() => {
      if (type.includes('image') && fileUrl.includes('png')) {
        return getResizedThumbnailUrl(fileUrl)
      }
      if (type.includes('image')) {
        return fileUrl
      }
      return getVideoThumbnailUrl()
    })()

    const onError = () => {
      if (
        type.includes('image') &&
        fileUrl.includes('png') &&
        imageUrl !== fileUrl &&
        imageRef.current
      ) {
        imageRef.current.src = fileUrl
        return
      }

      if (type.includes('image') || (type.includes('video') && isRetryOver)) {
        setIsLoaded(false)
      }
    }

    useEffect(() => {
      if (type.includes('video') && !isLoaded) {
        loadVideoThumbnail()
      }
    }, [type, isLoaded])

    return (
      <Item ref={ref} custom={style}>
        {imageUrl && (
          <img
            src={imageUrl}
            onLoad={() => setIsLoaded(true)}
            onError={onError}
            ref={imageRef}
          />
        )}
        {type === 'video' && media?.duration && (
          <DurationChip
            duration={`${twoDigits(Math.floor(media.duration / 60))}:
              ${twoDigits(Math.floor(media.duration % 60))}`}
          />
        )}
      </Item>
    )
  }
)

export default MediaItem

const Item = styled.div<{ custom?: CustomStyle }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16 / 9;
  border-radius: 2px;
  max-height: 134px;
  user-select: none;
  background-color: #a4a4a4;
  background-image: linear-gradient(
      45deg,
      #8b8b8d 25%,
      transparent 25%,
      transparent 75%,
      #8b8b8d 75%,
      #8b8b8d
    ),
    linear-gradient(
      45deg,
      #8b8b8d 25%,
      transparent 25%,
      transparent 75%,
      #8b8b8d 75%,
      #8b8b8d
    );
  background-position: 0 0, 12px 12px;
  background-size: 24px 24px;

  img,
  video {
    max-width: 100%;
    max-height: 100%;
  }

  ${({ custom }) => custom && custom};
`
