import { useCallback, useState } from 'react'

export const useModal = () => {
  const [visible, setVisible] = useState(false)
  const open = useCallback(() => setVisible(true), [])
  const close = useCallback(() => setVisible(false), [])
  const toggle = useCallback(() => setVisible((current) => !current), [])

  return { visible, open, close, toggle }
}
