import React, { useEffect, ReactNode } from 'react'
import ReactDOM from 'react-dom'

interface PortalProps {
  children: ReactNode
  target?: HTMLDivElement | null
}

const Portal: React.FC<PortalProps> = ({ children, target }) => {
  const portalRoot = document.getElementById('portal-root')
  const [rect, setRect] = React.useState<DOMRect | null>(null)

  if (!portalRoot || !target) {
    return null
  }

  const targetRect = rect || target.getBoundingClientRect()

  const wrapperStyle = {
    position: 'absolute',
    top: targetRect.top + 'px',
    left: targetRect.right + 'px',
    zIndex: 99999,
    pointerEvents: 'none'
  } as React.CSSProperties

  useEffect(() => {
    window.addEventListener('resize', () => {
      setRect(target.getBoundingClientRect())
    })
    return () => {
      window.removeEventListener('resize', () => {
        setRect(target.getBoundingClientRect())
      })
    }
  }, [])

  return ReactDOM.createPortal(
    <div style={{ ...wrapperStyle }}>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>,
    portalRoot
  )
}

export default Portal
