import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import useBreakpoint from '../../hooks/useBreakpoint'
import AiProgress from './AiProgress'
import { IconArrowUp } from '../../assets/icons'
import useTablet from './hooks/useTablet'
import { tabletMedia } from './constants'

interface AiSubtitleProps {
  isToggled?: boolean
  toggle?: () => void
  icon?: React.ReactNode
  subtitle: string
  progressValue: number
  progressText: string
  isShowArrow?: boolean
  isShowProgress?: boolean
  className?: string
}

const AiSubtitle = ({
  isToggled,
  toggle,
  icon,
  subtitle,
  progressValue,
  progressText,
  isShowArrow = true,
  isShowProgress = true,
  className
}: AiSubtitleProps) => {
  const { isMobile } = useBreakpoint()
  const { isTablet } = useTablet()
  return (
    <Title className={className}>
      {!isShowProgress || (!isTablet && !isMobile) ? (
        icon && <>{icon}</>
      ) : (
        <AiProgress value={progressValue} text={progressText} />
      )}
      {subtitle}
      {isShowArrow && isMobile && toggle && (
        <ArrowIcon isOpen={isToggled} onClick={toggle} />
      )}
    </Title>
  )
}

export default AiSubtitle

const Title = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.black2};
  font-size: 20px;
  font-weight: 600;
  line-height: 120%; /* 24px */

  svg {
    min-width: 24px;
    height: 24px;
  }

  ${tabletMedia(css`
    font-size: 16px;
  `)};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      font-size: 16px;
    `)}
`

const ArrowIcon = styled(IconArrowUp)<{ isOpen?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
`
