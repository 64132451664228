import ReactDOM from 'react-dom'
import Toast, { ToastProps } from '../components/Toast'

const useToast = () => {
  const renderToast = (props: ToastProps) => {
    ReactDOM.render(
      ReactDOM.createPortal(<Toast {...props} />, document.body),
      document.createElement('div')
    )
  }
  return { renderToast }
}

export default useToast
