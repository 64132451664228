import { useEffect, useRef, RefObject } from 'react'

function useOutsideClick<T extends HTMLElement = HTMLElement>(
  callback: () => void = () => {}
): RefObject<T> {
  const ref = useRef<T | null>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return ref
}

export default useOutsideClick
