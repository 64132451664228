import Axios from 'axios'
import qs from 'query-string'
import { Auth } from '../../components/AuthHelper'
import { redirectVplateLoginPage } from '../vplate'
const { create } = Axios

export default function () {
  const queryString = qs.parse(location.search.replace('?', ''))
  const { token }: Auth = JSON.parse(
    window.localStorage.getItem('vplateVideoEditorAuth') || '{}'
  )
  let accessToken = queryString?.token || token

  const headers = {
    'Access-Control-Allow-Origin': '*'
  }

  const axios = create({
    baseURL: `${process.env.REACT_APP_API_URL}/plugin`,
    withCredentials: false,
    headers
  })
  axios.defaults.headers = headers

  axios.interceptors.request.use(async (config) => {
    if (!accessToken) {
      const token = JSON.parse(
        localStorage.getItem('vplateVideoEditorAuth') || '{}'
      )?.token
      accessToken = token
    }
    config.headers.Authorization = `Bearer ${accessToken}`
    const countryCode = (localStorage.getItem('countryCode') || 'US')?.replace(
      /"/g,
      ''
    )
    const locale = (localStorage.getItem('locale') || 'en')?.replace(/"/g, '')
    const temp = (config.url || '').includes('?') ? '&' : '?'
    config.url = `${
      config.url
    }${temp}lang=${locale}&country=${countryCode.replace(/"/g, '')}`
    return config
  })

  axios.interceptors.response.use((response) => {
    if (response?.data?.code === 'AUTH_ERROR') {
      const auth = JSON.parse(
        window.localStorage.getItem('vplateVideoEditorAuth') || '{}'
      )
      localStorage.setItem(
        'vplateVideoEditorAuth',
        JSON.stringify({
          ...auth,
          token: ''
        })
      )

      redirectVplateLoginPage()
    }
    return response
  })

  return axios
}
