import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import theme from '../../styles/theme'
import 'react-circular-progressbar/dist/styles.css'
import styled from 'styled-components'
import LottieIcon from '../../components/Lottie'
import { check } from '../../assets/lottie'

interface AiProgressProps {
  text?: string
  value: number
}

const AiProgress = ({ text, value }: AiProgressProps) => {
  return (
    <Wrapper>
      {value !== 100 && (
        <>
          <div style={{ position: 'absolute' }}>
            <CircularProgressbar
              styles={buildStyles({
                pathColor: theme.colors.primary,
                trailColor: theme.colors.b6b6
              })}
              value={value}
              strokeWidth={11}
            />
          </div>
          {text && <div className='text'>{text}</div>}
        </>
      )}
      {value === 100 && (
        <CheckboxWrapper>
          <LottieIcon
            width={60}
            height={60}
            options={{ animationData: check, loop: false }}
          />
        </CheckboxWrapper>
      )}
    </Wrapper>
  )
}

export default AiProgress

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;

  .text {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme.colors.text['#aaa']};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
`
const CheckboxWrapper = styled.div`
  background-color: ${theme.colors.primary};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;

  svg {
    padding-bottom: 14px;
  }
`
