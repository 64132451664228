import { useCallback, useEffect, useRef, useState } from 'react'

import { message } from 'antd'
import useBreakpoint from '../../hooks/useBreakpoint'
import Audio from '../Audio'
import {
  AudioCol,
  BgmTitle,
  BgmTitleCol,
  Container,
  ContentNone,
  ContentWrapper,
  LottieContainer,
  Row,
  Subtitle
} from './styles'
import LottieIcon from '../Lottie'
import MusicList from './MusicList'
import { MusicPanelProps } from './types'
import { useIntl } from 'react-intl'
import PanelTitle from '../PanelTitle'
import theme from '../../styles/theme'

const MusicPanel = (props: MusicPanelProps) => {
  const {
    isMutateLoading,
    isReset,
    setIsReset,
    changedBgmTitle,
    duration,
    defaultMusicUrl,
    currentMusicUrl: initialCurrentMusicUrl,
    onChange = () => {},
    visible,
    handleDrawerClose
  } = props

  const intl = useIntl()
  const [currentMusicUrl, setCurrentMusicUrl] = useState(
    initialCurrentMusicUrl || ''
  )
  const [playingTarget, setPlayingTarget] = useState<HTMLAudioElement | null>(
    null
  )
  const { sizeName } = useBreakpoint()
  const defaultMusicRef = useRef<HTMLAudioElement>(null)
  const currentMusicRef = useRef<HTMLAudioElement>(null)

  const handleClearClick = useCallback(() => {
    if (currentMusicUrl) {
      message.success(intl.formatMessage({ id: 'SIDE_NAV_CHANGED' }))
    }
    onChange(null, null)
    setIsReset(false)
  }, [onChange, currentMusicUrl])

  const handlePlay = useCallback(
    (audio: HTMLAudioElement) => {
      if (playingTarget !== audio) {
        playingTarget && playingTarget.pause()
      }
      audio.play().then(() => setPlayingTarget(audio))
    },
    [playingTarget]
  )

  const handlePause = useCallback(
    (audio: HTMLAudioElement, startTime?: number) => {
      if (playingTarget === audio) {
        audio.pause()
        setPlayingTarget(null)
        audio.currentTime = startTime || 0
      }
    },
    [playingTarget]
  )

  useEffect(() => {
    if (!visible) {
      defaultMusicRef.current?.pause && defaultMusicRef.current.pause()
      currentMusicRef.current?.pause && currentMusicRef.current.pause()
    }
  }, [visible])

  useEffect(() => {
    if (isReset) {
      handleClearClick()
    }
  }, [isReset])

  useEffect(() => {
    setCurrentMusicUrl(initialCurrentMusicUrl || '')
  }, [initialCurrentMusicUrl])

  return (
    <Container>
      <PanelTitle
        title='SIDE_NAV_BGM'
        handleReset={handleClearClick}
        handleDrawerClose={handleDrawerClose}
      >
        <ContentWrapper>
          <AudioCol style={{ marginBottom: 40 }}>
            <Row>
              <Subtitle>
                {intl.formatMessage({ id: 'SIDE_NAV_BGM_CONTENT_DEFAULT' })}
              </Subtitle>
            </Row>
            <Row>
              <div style={{ width: '100%' }}>
                <Audio
                  src={defaultMusicUrl}
                  handlePlay={handlePlay}
                  handlePause={handlePause}
                />
              </div>
            </Row>
          </AudioCol>
          {isMutateLoading ? (
            <LottieContainer>
              <LottieIcon width={50} height={50} />
            </LottieContainer>
          ) : (
            <>
              {currentMusicUrl ? (
                <AudioCol>
                  <Subtitle>
                    {intl.formatMessage({ id: 'SIDE_NAV_BGM_CONTENT_AFTER' })}
                  </Subtitle>
                  <BgmTitleCol>
                    {changedBgmTitle && <BgmTitle>{changedBgmTitle}</BgmTitle>}
                    <Audio
                      src={currentMusicUrl}
                      handlePlay={handlePlay}
                      handlePause={handlePause}
                    />
                  </BgmTitleCol>
                </AudioCol>
              ) : (
                <>
                  <Subtitle>
                    {intl.formatMessage({ id: 'SIDE_NAV_BGM_CONTENT_AFTER' })}
                  </Subtitle>
                  <ContentNone>
                    {intl.formatMessage({
                      id: 'SIDE_NAV_BGM_CONTENT_AFTER_NONE'
                    })}
                  </ContentNone>
                </>
              )}
            </>
          )}
          <div
            style={{ border: `1px solid ${theme.colors.border.secondary}` }}
          />
          <MusicList
            sizeName={sizeName}
            onChange={onChange}
            duration={duration}
            setCurrentMusicUrl={setCurrentMusicUrl}
            handlePlay={handlePlay}
            handlePause={handlePause}
          />
        </ContentWrapper>
      </PanelTitle>
    </Container>
  )
}

export default MusicPanel
