import React, { createContext, useContext } from 'react'
import { service } from '../utils/api'
import { useQuery } from 'react-query'

export interface UserLimit {
  isSuccess: boolean
  usedScenarioGen: number // 사용한 시나리오 개수
  monthlyScenarioGen: number // 총 시나리오 개수
  needRenderCheck: boolean // 렌더링 무제한인지 아니면 횟수 체크해야하는지
  isUploadAvailable: boolean // 업로드 가능한 상태인지
  renderCnt: number // 렌더링 횟수 (무료일때만 카운트 됩니다.)
}

interface UserLimitContextType {
  userLimit: UserLimit | null
  isUserLimitLoading: boolean
  userLimitRefetch: () => Promise<any> | any
}

const UserLimitContext = createContext<UserLimitContextType>({
  userLimit: null,
  isUserLimitLoading: true,
  userLimitRefetch: () => {}
})

const UserLimitProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: userLimit,
    refetch,
    isLoading
  } = useQuery(['userLimit'], () => service().users.getLimit())

  const value = {
    userLimit: userLimit,
    isUserLimitLoading: isLoading,
    userLimitRefetch: refetch
  }

  return (
    <UserLimitContext.Provider value={value}>
      {children}
    </UserLimitContext.Provider>
  )
}

export const useUserLimitContext = (): UserLimitContextType => {
  const context = useContext(UserLimitContext)
  return context
}

export default UserLimitProvider
