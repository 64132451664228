import { useEffect, useState } from 'react'
import { sizes } from '../styles/theme/breakpoints'

const sizeKeys = Object.keys(sizes)

const useBreakpoint = () => {
  const [currentSizeName, setCurrentSizeName] =
    useState<keyof typeof sizes>('large')

  const isBreakpoint = (size: keyof typeof sizes) => {
    return window.innerWidth <= sizes[size]
  }

  const isMobile = window.innerWidth <= sizes.medium

  useEffect(() => {
    setCurrentSizeName(
      sizeKeys.find((key) => {
        return window.innerWidth <= (sizes as any)[key]
      }) as keyof typeof sizes
    )
  }, [])

  useEffect(() => {
    const handleWindowResize = () => {
      setCurrentSizeName(
        (sizeKeys.find((key) => {
          return window.innerWidth <= (sizes as any)[key]
        }) || 'large') as keyof typeof sizes
      )
    }
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [window.innerWidth, currentSizeName])

  return {
    sizeName: currentSizeName,
    isMobile,
    isBreakpoint
  }
}

export default useBreakpoint
