import {
  BaseSyntheticEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import styled from 'styled-components'
import { IconDirectionMore } from '../../assets/icons'
import useFormatText from '../../hooks/useFormatText'

interface PhotoFilterProps {
  setOrientaion: Dispatch<SetStateAction<string>>
}

const PhotoFilter = ({ setOrientaion }: PhotoFilterProps) => {
  const [visible, setVisible] = useState(false)

  const handleClickMenu = (e: MouseEvent<HTMLLIElement | HTMLUListElement>) => {
    const targetLi = e.currentTarget as HTMLLIElement
    setVisible(false)
    if (targetLi.dataset.orientation) {
      setOrientaion(targetLi.dataset.orientation)
    } else {
      setOrientaion('')
    }
  }

  const handleOutsideClick = () => {
    setVisible(false)
  }

  // custom hook
  const useOutsideClick = (callback: () => void) => {
    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
      const handleClick = (
        event: BaseSyntheticEvent | globalThis.MouseEvent
      ) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick)

      return () => {
        document.removeEventListener('click', handleClick)
      }
    }, [callback, ref])

    return ref
  }

  const ref = useOutsideClick(handleOutsideClick)

  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_B = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_B'
  )
  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_C = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_C'
  )
  const IMAGE_INPUT_FROM_FREESTOCK_FILTER_A = useFormatText(
    'IMAGE_INPUT_FROM_FREESTOCK_FILTER_A'
  )
  const menu = (
    <Menu>
      <Li onClick={handleClickMenu} data-orientation={'landscape'} tabIndex={0}>
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_B}
      </Li>
      <Li onClick={handleClickMenu} data-orientation={'portrait'} tabIndex={1}>
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_C}
      </Li>
      <Li onClick={handleClickMenu} data-orientation={''} tabIndex={2}>
        {IMAGE_INPUT_FROM_FREESTOCK_FILTER_A}
      </Li>
    </Menu>
  )
  return (
    <Wrapper>
      <DropDown>
        <IconDirectionMore
          style={{
            transform: visible ? 'rotate(180deg)' : '',
            transition: 'all 0.2s ease'
          }}
        />
        <Button
          ref={ref}
          type='button'
          onClick={() => {
            setVisible((prev) => !prev)
          }}
        >
          {useFormatText('IMAGE_INPUT_FILTER')}
        </Button>
      </DropDown>
      {visible && menu}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  cursor: pointer;
  margin-left: 10px;
`

const DropDown = styled.div`
  width: max-content;
`

const Menu = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  height: auto;
  transform: translate(0, 20px);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 9px 0;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 ${({ theme }) => theme.colors.black}29 !important;
  color: ${({ theme }) => theme.colors.black};
  z-index: 999999;
`

const Li = styled.li`
  width: 120px;
  height: 24px;
  padding: 5px 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.border.primary};
  }
`

const Button = styled.button`
  width: auto;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.text.link};
  cursor: pointer;
`

export default PhotoFilter
