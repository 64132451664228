import styled, { css } from 'styled-components'
import useFormatText from '../../../hooks/useFormatText'
import theme from '../../../styles/theme'

const CheckInfo = ({ checkedList }: { checkedList: any[] }) => {
  return (
    <Wrapper>
      <span className='check-count'>{checkedList.length}</span>
      {useFormatText('STORAGE_CHECK_COUNT_UNIT')}
      <span style={{ marginLeft: 4 }}>{useFormatText('AI_CHECKED_FILES')}</span>
    </Wrapper>
  )
}

export default CheckInfo

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;
  font-size: 14px;
  margin-right: 8px;

  .check-count {
    color: ${theme.colors.primary};
    font-weight: 600;
  }

  .checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid ${theme.colors.b6b6};
  }

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 12px;
      margin-right: 0;
    `)};

  ${({ theme }) =>
    theme.breakpoints.large(css`
      display: block;
      margin-bottom: 12px;
      margin-right: 0;
      text-align: right;
    `)};
`
