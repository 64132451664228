import { useRef, useCallback } from 'react'

type Callback<Args extends any[], Return> = (...args: Args) => Return

export const useDebounce = <Args extends any[], Return>(
  callback: Callback<Args, Return>,
  delay: number
) => {
  const timeId = useRef<NodeJS.Timeout | null>(null)

  const debouncedCallback = useCallback(
    (...args: Args) => {
      timeId.current && clearTimeout(timeId.current)
      timeId.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, delay]
  )

  return debouncedCallback
}
